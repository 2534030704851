import React, {useState} from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {TextField, Typography} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import machinesPageStyle from 'component/MachinePage/machinesPageStyle'
import IconButton from 'component/material/IconButton'
import EditIcon from 'component/icon/EditIcon'
import RestoreIcon from 'component/icon/RestoreIcon'
import ArchiveIcon from 'component/icon/ArchiveIcon'
import SortIcon from 'component/icon/SortIcon'
import Box from '@material-ui/core/Box'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {fireErrorToast, fireSuccessToast} from 'helper/functions'
import CloseIcon from 'component/icon/CloseIcon'
import WarningDialog from 'component/material/WarningDialog'
import cx from 'classnames'
import {getUsers, updateUser} from 'redux/action/userAction'
import Loader from 'component/material/Loader'
import SelectInput from 'component/field/SelectInput'
import {USER_ROLES} from 'helper/constants'
import CheckIcon from 'component/icon/CheckIcon'
import useWindowSize from 'helper/useWindowSize'

const UserTable = (props) => {
  const {
    classes,
    userList,
    handleTableSort,
    tableSort,
    updateUser,
    getUsers,
    onlyArchivedFilter,
    handleOnlyArchived,
    usersLoading,
  } = props

  const [archiveDialog, setOpenArchiveDialog] = useState({visible: false, user: ''})
  const [editRow, setEditRow] = useState(null)

  const {isMediaMobile, isMediaScreen} = useWindowSize()

  const handleChange = (e) => {
    setEditRow((prevState) => ({
      ...prevState,
      user: {...prevState.user, [e.target.name]: e.target.value},
    }))
  }

  const handleEditRow = (user, index) => (e) => {
    setEditRow({user: user, index: index})
  }

  const handleEditUser = () => {
    updateUser(editRow.user?.id, editRow.user)
      .then(() => {
        fireSuccessToast(<Trans>User edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>User edit failed.</Trans>)
      })
      .finally(() => {
        getUsers(onlyArchivedFilter)
        setEditRow(null)
      })
  }

  const handleEditCancel = () => {
    setEditRow(null)
  }

  const handleOpenArchiveDialog = (user) => (e) => {
    setOpenArchiveDialog({visible: true, user: user})
  }
  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog({visible: false, user: ''})
  }
  const handleArchiveUser = () => {
    updateUser(archiveDialog.user?.id, {
      ...archiveDialog.user,
      is_archived: !archiveDialog.user.is_archived,
    })
      .then((res) => {
        fireSuccessToast(<Trans>User edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>User edit failed.</Trans>)
      })
      .finally(() => {
        getUsers(onlyArchivedFilter)
        handleCloseArchiveDialog()
      })
  }

  const getSortClass = (orderBy) => {
    if (tableSort.orderBy === orderBy) {
      if (tableSort.orderDirection === 'ASC') {
        return classes.sortIconAsc
      } else {
        return classes.sortIconDesc
      }
    }
  }

  return (
    <>
      {/* CONFIRM ARCHIVE USER */}
      <WarningDialog
        title={
          archiveDialog.user?.is_archived ? (
            <Trans>Restore user from archive</Trans>
          ) : (
            <Trans>Archive user</Trans>
          )
        }
        message={archiveDialog.user?.name}
        open={archiveDialog.visible}
        onClose={handleCloseArchiveDialog}
        onConfirm={handleArchiveUser}
      />

      <GridContainer alignItems={'center'} className={classes.tableHead}>
        <GridItem container xs={isMediaMobile? 2:3} alignItems={'center'}>
          <span onClick={handleTableSort('name')} className={classes.sortIconBox}>
            <Typography color={'primary'} variant={'body1'} className={classes.tableHeadFilter}>
              <Trans>Name and surname</Trans>
            </Typography>
            <Box pl={3} className={cx(classes.sortIconBox, getSortClass('name'))}>
              <SortIcon />
            </Box>
          </span>
        </GridItem>

        <GridItem xs={isMediaMobile? 4:3}>
          <Typography color={'primary'} variant={'body1'} className={classes.tableHeadFilter}>
            <Trans>E-mail</Trans>
          </Typography>
        </GridItem>

        <GridItem xs={2}>
          <span onClick={handleTableSort('role')} className={classes.sortIconBox}>
            <Typography color={'primary'} variant={'body1'} className={classes.tableHeadFilter}>
              <Trans>Role</Trans>
            </Typography>
            <Box pl={3} className={cx(classes.sortIconBox, getSortClass('role'))}>
              <SortIcon />
            </Box>
          </span>
        </GridItem>

        <GridItem xs={2}>
          <span onClick={handleTableSort('state')} className={classes.sortIconBox}>
            <Typography color={'primary'} variant={'body1'} className={classes.tableHeadFilter}>
              <Trans>State</Trans>
            </Typography>
            <Box pl={3} className={cx(classes.sortIconBox, getSortClass('state'))}>
              <SortIcon />
            </Box>
          </span>
        </GridItem>

        <GridItem container xs={2} justify={'flex-end'}>
          <FormControlLabel
            control={
              <Checkbox
                color={'primary'}
                checked={onlyArchivedFilter}
                onChange={handleOnlyArchived}
                name={'is_archived'}
              />
            }
            label={
              <Typography color={'primary'} variant={'body1'}>
                {<Trans>Only non-active</Trans>}
              </Typography>
            }
          />
        </GridItem>
      </GridContainer>

      {usersLoading ? (
        <GridContainer className={classes.tableRow} alignItems={'center'}>
          <Loader className={classes.tableRowLoading} />
        </GridContainer>
      ) : userList?.length === 0 ? (
        <GridContainer className={classes.tableRow} alignItems={'center'} justify={'center'}>
          <Typography color={'secondary'} variant={'h6'}>
            <Trans>No users</Trans>
          </Typography>
        </GridContainer>
      ) : (
        userList?.map((user, index) => (
          <GridContainer key={index} className={classes.tableRow} alignItems={'center'}>
            <GridItem xs={isMediaMobile? 2:3} className={classes.tableRowName}>
              {editRow?.index === index ? (
                <TextField
                  fullWidth
                  value={editRow.user?.name}
                  name={'name'}
                  onChange={handleChange}
                  className={classes.tableInput}
                />
              ) : user?.name ? (
                user.name
              ) : (
                <Trans>Not specified</Trans>
              )}
            </GridItem>
            <GridItem xs={isMediaMobile? 4:3} className={classes.tableRowText}>
              {editRow?.index === index ? (
                <TextField
                  fullWidth
                  value={editRow.user?.email}
                  name={'email'}
                  onChange={handleChange}
                  className={classes.tableInput}
                />
              ) : user?.email ? (
                user.email
              ) : (
                <Trans>Not specified</Trans>
              )}
            </GridItem>
            <GridItem xs={2} className={classes.tableRowText}>
              {editRow?.index === index ? (
                <SelectInput
                  variant={'standard'}
                  name={'role'}
                  value={editRow.user?.role}
                  options={USER_ROLES}
                  className={classes.tableInput}
                  onChange={handleChange}
                  showHelperText={false}
                />
              ) : user?.role ? (
                user.role
              ) : (
                <Trans>Not specified</Trans>
              )}
            </GridItem>
            <GridItem xs={2}>
              {user.is_archived ? (
                <span className={classes.tableNonActive}>
                  <Trans>Non-active</Trans>
                </span>
              ) : (
                <span className={classes.tableActive}>
                  <Trans>Active</Trans>
                </span>
              )}
            </GridItem>
            <GridItem container xs={2} justify={'flex-end'} className={classes.tableRowActions}>
              {editRow?.index === index ? (
                <>
                  <IconButton icon={<CheckIcon />} onClick={handleEditUser} />
                  <IconButton icon={<CloseIcon />} onClick={handleEditCancel} />
                </>
              ) : (
                <>
                  <IconButton icon={<EditIcon />} onClick={handleEditRow(user, index)} />
                  {user.is_archived ? (
                    <IconButton icon={<RestoreIcon />} onClick={handleOpenArchiveDialog(user)} />
                  ) : (
                    <IconButton icon={<ArchiveIcon />} onClick={handleOpenArchiveDialog(user)} />
                  )}
                </>
              )}
            </GridItem>
          </GridContainer>
        ))
      )}
    </>
  )
}

UserTable.propTypes = {
  classes: PropTypes.object,
  tableSort: PropTypes.object,
  userList: PropTypes.array,
  updateUser: PropTypes.func,
  getUsers: PropTypes.func,
  handleOnlyArchived: PropTypes.func,
  handleTableSort: PropTypes.func,
  onlyArchivedFilter: PropTypes.bool,
  usersLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateUser,
      getUsers,
    },
    dispatch
  )
}

export default compose(
  withStyles(machinesPageStyle),
  connect((store) => {
    return {
      userList: store.user.list,
      usersLoading: store.user.loading,
    }
  }, mapDispatchToProps)
)(UserTable)
