const shiftDetailStyle = (theme) => ({
  shiftTabs: {
    height: 60,
    borderBottom: '1px solid ' + theme.palette.secondary.light,
    padding: '0 20px 0 10px',
    fontWeight: 700,
    fontSize: 16,
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    width: '100%',
  },

  shiftTab: {
    color: theme.palette.secondary.main,
    transition: 'color 100ms',
    height: 60,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
    paddingTop: 20,
    letterSpacing: '-0.5px',
    boxSizing: 'border-box',
    borderBottom: '2px solid transparent',
  },
  activeTab: {
    color: theme.palette.primary.main,
    transition: 'color 100ms',
    borderBottom: '2px solid ' + theme.palette.primary.main,
  },

  // new transport dialog window
  newTransportTitle: {
    '& h2': {
      fontWeight: 700,
    },
    minHeight: 100,
    borderBottom: '1px solid ' + theme.palette.primary.light,
  },
  newTransportMaterial: {
    minHeight: 200,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',

    '& span': {
      color: theme.palette.primary.main,
      paddingTop: 20,
      textTransform: 'uppercase',
      fontSize: 18,
      fontWeight: 600,
    },
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  newTransportMaterialSelected: {
    backgroundColor: theme.palette.primary.main,
    '& span': {
      color: theme.palette.background.paper,
      paddingTop: 20,
      textTransform: 'uppercase',
      fontSize: 18,
      fontWeight: 600,
    },
    '& svg': {
      fill: theme.palette.background.paper,
    },
  },

  newTransportForm: {
    borderTop: '1px solid ' + theme.palette.primary.light,
  },
  fieldLabel: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 600,
    paddingBottom: 5,
  },
  formField: {
    '& .MuiSelect-select': {
      padding: '12px 36px 12px 14px',
      display: 'flex',
      alignItems: 'center',
    },
  },

  // styling add icon inside button
  addCircleIconPadding: {
    paddingRight: 10,
    alignSelf: 'center',
    display: 'flex',
  },

  // set height of shift column to for scrolling
  shiftColumnHeight: {
    height: 'calc(100vh - 64px)',
    maxHeight: 'calc(100vh - 64px)',
    alignItems: 'flex-start',
    placeContent: 'flex-start',
  },

  endAdornmentPadding: {
    paddingRight: 12,
  },
  fieldEndAdornmentPadding: {
    '& input': {
      padding: '12px 0 12px 14px !important',
    },
  },

  // shift statistics styles
  statisticsScrollContainer: {
    height: 'calc(100% - 120px)',
    maxHeight: 'calc(100% - 120px)',
    overflowY: 'auto',
    width: '100%',
  },
  statisticsHead: {
    height: 60,
    boxSizing: 'border-box',
    borderBottom: '1px solid ' + theme.palette.primary.light,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  statisticsRow: {
    height: 60,
    boxSizing: 'border-box',
    borderBottom: '1px solid ' + theme.palette.primary.light,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  statisticsCell: {
    boxSizing: 'border-box',
    padding: '0 10px',
    color: theme.palette.primary.main,
  },
})

export default shiftDetailStyle
