import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import materialStyle from 'component/material/materialStyle'
import cx from 'classnames'

const Loader = (props) => {
  const {classes, className} = props
  return <div className={cx(classes.loader, className)} />
}

export default withStyles(materialStyle)(Loader)
