import React from 'react'
import {ReactComponent as Sort} from 'style/asset/sort-arrows.svg'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import iconStyle from 'component/icon/iconStyle'
import cx from 'classnames'

const SortIcon = (props) => {
  const {className, classes, ...rest} = props

  return <Sort {...rest} className={cx(className)} />
}

SortIcon.propTypes = {
  className: PropTypes.object,
  classes: PropTypes.object,
  rest: PropTypes.object,
}

export default withStyles(iconStyle)(SortIcon)
