import React from 'react'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import materialStyle from 'component/material/materialStyle'
import cx from 'classnames'

const UploadButton = (props) => {
  const {
    className,
    classes,
    saveImage,
    inputId = 'file',
    title = <Trans>Select image</Trans>,
  } = props

  const handleImageChange = (e) => {
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      saveImage(reader.result)
      document.getElementById(inputId).value = null
    }
    file && reader.readAsDataURL(file)
  }

  return (
    <div className={cx(classes.uploadWrapper, className)}>
      <label>
        {title}
        <input
          id={inputId}
          className={classes.fileInput}
          onChange={handleImageChange}
          accept="image/png, image/jpeg, image/gif"
          type="file"
        />
      </label>
    </div>
  )
}

export default withStyles(materialStyle)(UploadButton)
