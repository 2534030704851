import React from 'react'
import {ReactComponent as NewAsset} from 'style/asset/new-asset.svg'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import iconStyle from 'component/icon/iconStyle'

const NewAssetIcon = (props) => {
  const {className, classes, single, ...rest} = props

  return <NewAsset {...rest} className={cx(className, classes.newAssetIcon, {[classes.iconSingle]: single})} />
}

NewAssetIcon.propTypes = {
  className: PropTypes.object,
  rest: PropTypes.object,
}

export default withStyles(iconStyle)(NewAssetIcon)
