import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/CloseRounded'

const CloseIconButton = (props) => {
  const {onClose, classes} = props

  return (
    <IconButton onClick={onClose} classes={{root: classes.saveCloseButtonIcon}}>
      <CloseIcon />
    </IconButton>
  )
}

CloseIconButton.propTypes = {
  onClose: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(locationDetailStyle)(CloseIconButton)
