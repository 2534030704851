import React, {useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import {createShift, deleteTransport, deleteTransportInStorage, deleteTransportInStoragePastShift, getShifts, getShiftsFromStorage, updateShift} from 'redux/action/shiftAction'
import {getUsers} from 'redux/action/userAction'
import {connect} from 'react-redux'
import Accordion from 'component/material/Accordion'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import withStyles from '@material-ui/core/styles/withStyles'
import DeleteIcon from 'component/icon/DeleteIcon'
import WarningDialog from 'component/material/WarningDialog'
import {Trans} from '@lingui/macro'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import moment from 'moment'
import {DATE_TIME_FORMAT, TIME_FORMAT} from 'helper/constants'
import SingleShiftRow from 'component/MapLayoutPage/shiftDetail/SingleShiftRow'
import PropTypes from 'prop-types'
import {fireErrorToast, fireSuccessToast, fireWarningToast} from 'helper/functions'
import {useEffect} from 'react'
import DialogWindow from 'component/material/DialogWindow'
import EditTransportDialog from './EditTransportDialog'

const PastShiftTab = (props) => {
  const {
    classes,
    locationDetail,
    contractDetail,
    pastShifts,
    userList,
    getShifts,
    getUsers,
    deleteTransport,
  } = props

  const [openDeleteTransport, setOpenDeleteTransport] = useState({
    shiftId: null,
    transportId: null,
    visible: false,
  })
  const [expanded, setExpanded] = useState(0)
  const [openEditTransport, setOpenEditTransport] = useState({
    shiftId: null,
    transportId: null,
    visible: false,
    transport: {},
  })

  const openEditTransportDialog = (shiftId, transportId, transportOfflineId) => (e) => {
    const index = pastShifts.findIndex((shift) => shift.id === shiftId)
    const transport = pastShifts[index].transports.find(
      (item) =>
        (transportId && item.id === transportId) ||
        (transportOfflineId && item.transportOfflineId === transportOfflineId)
    )
    console.log({transportId, transportOfflineId, transport})
    setOpenEditTransport({
      shiftId: shiftId,
      transportId: transportId,
      transportOfflineId,
      visible: true,
      transport,
    })
  }
  const closeEditTransportDialog = () => {
    setOpenEditTransport({shiftId: null, transportId: null, visible: false})
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const getAccordionTitle = (shift) => {
    return (
      <GridContainer direction={'row'}>
        <GridItem xs={6}>{`${moment(shift.start).format(DATE_TIME_FORMAT)}-${moment(
          shift.end
        ).format(TIME_FORMAT)}`}</GridItem>
        <GridItem xs={6}>
          <b>
            {userList.find((user) => user.id === shift.user_id)?.name || (
              <Trans>Unknown user</Trans>
            )}
          </b>
        </GridItem>
      </GridContainer>
    )
  }

  const openDeleteTransportDialog = (shiftId, transportId, transportOfflineId) => (e) => {
    setOpenDeleteTransport({shiftId: shiftId, transportId: transportId, transportOfflineId, visible: true})
  }
  const closeDeleteTransportDialog = () => {
    setOpenDeleteTransport({shiftId: null, transportId: null, visible: false})
  }
  const handleDeleteTransport = () => {
    deleteTransport(
      locationDetail.id,
      contractDetail.id,
      openDeleteTransport.shiftId,
      openDeleteTransport.transportId,
      openDeleteTransport.transportOfflineId,
    )
      .then((res) => {
        fireSuccessToast(<Trans>Transport deleted.</Trans>)
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          deleteTransportInStoragePastShift(
            locationDetail.id,
            contractDetail.id,
            openDeleteTransport.shiftId,
            openDeleteTransport.transportId,
            openDeleteTransport.transportOfflineId,
          )
          getShiftsFromStorage(locationDetail.id, contractDetail.id)
          fireWarningToast(<Trans>Offline mode, data will be synced later</Trans>)
        } else {
        fireErrorToast(<Trans>Transport deletion failed.</Trans>)
        }
      })
      .finally(() => {
        getShifts(locationDetail.id, contractDetail.id)
        closeDeleteTransportDialog()
      })
  }

  useEffect(() => {
    getUsers()
  }, [pastShifts])

  return (
    <>
      {/* CONFIRM DELETE TRANSPORT */}
      <WarningDialog
        title={<Trans>Delete transport</Trans>}
        open={openDeleteTransport.visible}
        onClose={closeDeleteTransportDialog}
        onConfirm={handleDeleteTransport}
      />
      
      {/* EDIT TRANSPORT DIALOG */}
      <DialogWindow
        open={openEditTransport.visible}
        onClose={closeEditTransportDialog}
        maxWidth={'sm'}
      >
        <EditTransportDialog
          onClose={closeEditTransportDialog}
          shiftId={openEditTransport.shiftId}
          transport={openEditTransport.transport}
        />
      </DialogWindow>

      <GridContainer className={classes.pastShifts}>
        {pastShifts?.length === 0 ? (
          <GridItem xs={12} className={classes.emptyPastShifts}>
            <Trans>No past shifts</Trans>
          </GridItem>
        ) : (
          pastShifts.map((shift, index) => (
            <GridItem xs={12} key={index}>
              <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
                defaultExpanded={false}
                title={getAccordionTitle(shift)}
              >
                <GridContainer direction={'column'}>
                  {shift.transports?.map((transport, index) => (
                    <SingleShiftRow
                      key={index}
                      shift={shift}
                      transport={transport}
                      openDeleteTransportDialog={openDeleteTransportDialog}
                      openEditTransportDialog={openEditTransportDialog}
                    />
                  ))}
                </GridContainer>
              </Accordion>
            </GridItem>
          ))
        )}
      </GridContainer>
    </>
  )
}

PastShiftTab.propTypes = {
  classes: PropTypes.object,
  locationDetail: PropTypes.object,
  contractDetail: PropTypes.object,
  pastShifts: PropTypes.array,
  userList: PropTypes.array,
  getShifts: PropTypes.func,
  deleteTransport: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getShifts,
      createShift,
      updateShift,
      getUsers,
      deleteTransport,
    },
    dispatch
  )
}

export default compose(
  withStyles(locationDetailStyle),
  connect((store) => {
    return {
      pastShifts: store.shifts.pastShifts,
      userList: store.user.list,
      locationDetail: store.locations.detail,
      contractDetail: store.contracts.detail,
    }
  }, mapDispatchToProps)
)(PastShiftTab)
