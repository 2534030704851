import { MEDIA_MOBILE, MEDIA_SCREEN } from "helper/constants"

const fieldStyle = (theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },

  // chips used inside multi select
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  chip: {
    margin: 2,
  },

  // menu item inside the select paper
  menuItem: {
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },

  // number inputs
  inputJustifyRight: {
    color: 'red',
    '& input': {
      textAlign: 'end !important',
    },
  },

  // date input full width
  datePickerInput: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
      },
      '& .MuiInputBase-root': {
        height: 42,
        '& .MuiInputBase-input': {
          padding: '12.5px 14px',
        },
      },
    },
    '& fieldset': {
      boxShadow: '0 2px 6px 0 rgba(103, 103, 139, 0.1)',
    },
  },
  calendarIcon: {
    height: 18,
    width: 18,
    fill: theme.palette.secondary.main,
  },

  // error || helper text color
  formHelperText: {
    color: theme.palette.error.main,
  },

  // loading indicator in select field
  loadingEndAdornment: {
    paddingRight: '20px',
    '& .MuiCircularProgress-root': {
      width: '20px !important',
      height: '20px !important',
    },
  },
  selectIcon: {
    cursor: 'pointer',
    pointerEvents: 'none',
    '& svg': {
      position: 'absolute',
      right: 10,
      top: 'calc(50% - 6px)',
      width: 19,
      height: 12,
    },
  },
  selectInput: {
    '& .MuiSelect-select': {
      height: 42,
      padding: '12px 14px',
      boxSizing: 'border-box',
    },
  },
  emptyOption: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },

  fieldLabel: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 600,
    paddingBottom: 5,
  },

  adornments: {
    '& .MuiInputBase-adornedStart': {
      paddingLeft: 0,
    },
    '& .MuiInputBase-adornedEnd': {
      paddingRight: 0,
    },
  },

  inputPadding: {
    '& .MuiInputBase-input': {
      padding: '12px 14px',
      height: 42,
      fontSize: 16,
      boxSizing: 'border-box',
    },
  },
  fieldsPadding: {
    '& .MuiGrid-grid-sm-3': {
      paddingLeft: 10,
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiGrid-grid-sm-3': {
        '&:nth-child(2)': {
          paddingLeft: 0,
        },
        '&:nth-child(3)': {
          paddingLeft: 20,
        },
      },
    },
  },
  fieldsPaddingRight: {
    paddingRight: 10,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },

  submitFormButton: {
    padding: '12px 14px',
    height: 42,
    fontSize: 16,
    boxSizing: 'border-box',
  },
  submitFormButtonPosition: {
    paddingBottom: 23,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },

  smallGridPadding: {
    [MEDIA_SCREEN]: {
      '&:nth-child(odd)': {
        paddingRight: 10,
      },
      '&:nth-child(even)': {
        paddingLeft: 10,
      },
    },
    [MEDIA_MOBILE]: {
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  fieldEndAdornmentPadding: {
    '& input': {
      padding: '12px 0 12px 14px !important',
    },
  },
  endAdornmentPadding: {
    paddingRight: 12,
  },

  // cursor pointer class
  cursorPointer: {
    cursor: 'pointer',
  },

  adornmentIcon: {
    width: 42,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      fill: theme.palette.primary.dark,
      opacity: '.5',
      width: 18,
    },
  },

  adornment: {
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
  },

  fieldShadow: {
    '& fieldset': {
      boxShadow: '0 2px 6px 0 rgba(103, 103, 139, 0.1)',
    },
  },

  // styles for login texts (stay logged in, forgot password)
  loginText: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  // create location styles
  newLocation: {
    minHeight: 110,
    background: theme.palette.background.paper,
    cursor: 'pointer',
    borderBottom: '1px solid ' + theme.palette.secondary.light,
    borderRight: '1px solid ' + theme.palette.secondary.light,
    transition: 'background 200ms',
    '&:last-child': {
      borderRight: 'none',
    },

    '& span': {
      color: theme.palette.primary.main,
      paddingTop: 15,
      textTransform: 'uppercase',
      fontSize: 16,
      fontWeight: 700,
      boxSizing: 'border-box',
      transition: 'color 200ms',
    },
    '& svg': {
      width: 30,
      height: 'auto',
      fill: theme.palette.primary.main,
      transition: 'fill 200ms',
    },
  },
  workLocation: {
    '& svg': {
      fill: theme.palette.work,
    },
  },
  destinationLocation: {
    '& svg': {
      fill: theme.palette.destination,
    },
  },
  gasStationLocation: {
    '& svg': {
      fill: theme.palette.gas,
    },
  },
  serviceStationLocation: {
    '& svg': {
      fill: theme.palette.service,
    },
  },
  workLocationType: {
    background: theme.palette.work,
    transition: 'background 200ms',
    '& span': {
      color: theme.palette.background.paper,
    },
    '& svg': {
      fill: theme.palette.background.paper,
    },
  },
  destinationLocationType: {
    background: theme.palette.destination,
    transition: 'background 200ms',
    '& span': {
      color: theme.palette.background.paper,
    },
    '& svg': {
      fill: theme.palette.background.paper,
    },
  },
  gasStationLocationType: {
    background: theme.palette.gas,
    transition: 'background 200ms',
    '& span': {
      color: theme.palette.background.paper,
    },
    '& svg': {
      fill: theme.palette.background.paper,
    },
  },
  serviceStationLocationType: {
    background: theme.palette.service,
    transition: 'background 200ms',
    '& span': {
      color: theme.palette.background.paper,
    },
    '& svg': {
      fill: theme.palette.background.paper,
    },
  },
  selectCoordsBox: {
    minHeight: 180,
    borderTop: '1px solid ' + theme.palette.secondary.light,
    cursor: 'pointer',
    '& span': {
      color: theme.palette.primary.main,
      padding: '25px 0 15px',
      textTransform: 'uppercase',
      fontSize: 16,
      fontWeight: 700,
      boxSizing: 'border-box',
      transition: 'color 200ms',
    },
  },
  borderRightBox: {
    borderRight: '1px solid ' + theme.palette.secondary.light,
  },
})

export default fieldStyle
