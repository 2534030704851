import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import React from 'react'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import {Trans} from '@lingui/macro'
import {
  MY_ACCOUNT_FAIL,
  MY_ACCOUNT_REQUEST,
  MY_ACCOUNT_SUCCESS,
  SET_ACTIVE_PAGE,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
} from 'redux/actionType'

export const login = (data) => {
  return (dispatch) => {
    dispatch({type: USER_LOGIN_REQUEST})
    return httpClient
      .post(endpoints.login, data)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem('sessionId', res.headers['session-id'])
          dispatch({type: USER_LOGIN_SUCCESS, data: res.data.user})
          fireSuccessToast(<Trans>Successfully logged in.</Trans>)
          return true
        }
      })
      .catch((err) => {
        dispatch({type: USER_LOGIN_FAIL})
        if (err.response?.status === 401) {
          return fireErrorToast(<Trans>Wrong login credentials.</Trans>)
        }
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({type: USER_LOGOUT_REQUEST})
    return httpClient
      .del(endpoints.logout)
      .then((res) => {
        if (res.status === 204) {
          localStorage.removeItem('sessionId')
          dispatch({type: USER_LOGOUT_SUCCESS, data: res.data.user})
          dispatch({type: SET_ACTIVE_PAGE, data: 'login'})
        }
      })
      .catch((err) => {
        dispatch({type: USER_LOGOUT_FAIL})
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}

export const forgottenPassword = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.forgottenPassword, data)
      .then((res) => {
        return true
      })
      .catch((err) => {
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}

export const resetPassword = (token, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.resetPassword}/${token}`, data)
      .then((res) => {
        return true
      })
      .catch((err) => {
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}

export const getMyAccount = () => {
  return (dispatch) => {
    dispatch({type: MY_ACCOUNT_REQUEST})
    return httpClient
      .get(endpoints.myAccount)
      .then((res) => {
        dispatch({type: MY_ACCOUNT_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: MY_ACCOUNT_FAIL})
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}

export const editMyAccount = (data) => {
  return (dispatch) => {
    return httpClient
      .put(endpoints.myAccount, data)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}