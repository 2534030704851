import {combineReducers} from 'redux'
import userReducer from 'redux/reducer/userReducer'
import globalSettingsReducer from 'redux/reducer/globalSettingsReducer'
import locationReducer from 'redux/reducer/locationReducer'
import contractReducer from 'redux/reducer/contractReducer'
import shiftReducer from 'redux/reducer/shiftReducer'
import machineReducer from 'redux/reducer/machineReducer'
import activePageReducer from 'redux/reducer/activePageReducer'
import offlineActionsReducer from 'redux/reducer/offlineActionsReducer'

export default combineReducers({
  user: userReducer,
  globalSettings: globalSettingsReducer,
  locations: locationReducer,
  contracts: contractReducer,
  shifts: shiftReducer,
  machines: machineReducer,
  activePage: activePageReducer,
  offlineActions: offlineActionsReducer,
})
