import React from 'react'
import shiftDetailStyle from 'component/MapLayoutPage/shiftDetail/shiftDetailStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import cx from 'classnames'

const ShiftDetailTabs = (props) => {
  const {classes, handleTabClick, selectedTab} = props

  return (
    <div className={classes.shiftTabs}>
      <div
        onClick={handleTabClick('current')}
        className={cx(classes.shiftTab, selectedTab === 'current' && classes.activeTab)}
      >
        <Trans>Current shift</Trans>
      </div>
      <div
        onClick={handleTabClick('past')}
        className={cx(classes.shiftTab, selectedTab === 'past' && classes.activeTab)}
      >
        <Trans>Past shifts</Trans>
      </div>
      <div
        onClick={handleTabClick('statistic')}
        className={cx(classes.shiftTab, selectedTab === 'statistic' && classes.activeTab)}
      >
        <Trans>Statistics</Trans>
      </div>
    </div>
  )
}

ShiftDetailTabs.propTypes = {
  classes: PropTypes.object,
  handleTabClick: PropTypes.func,
  selectedTab: PropTypes.string,
}

export default withStyles(shiftDetailStyle)(ShiftDetailTabs)
