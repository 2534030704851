import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {composeValidators, required} from 'helper/validations'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import cx from 'classnames'
import {ReactComponent as Work} from 'style/asset/work.svg'
import {ReactComponent as Destination} from 'style/asset/destination.svg'
import {ReactComponent as Gas} from 'style/asset/gas.svg'
import {ReactComponent as Service} from 'style/asset/service.svg'
import {fireErrorToast, fireSuccessToast, handleMapError} from 'helper/functions'
import {bindActionCreators, compose} from 'redux'
import {createLocation, getLocations} from 'redux/action/locationAction'
import {connect} from 'react-redux'

const CreateLocationForm = (props) => {
  const {
    classes,
    locationFromMap,
    onClose,
    getLocations,
    createLocation,
    handleLocationFromMap,
  } = props

  const [locationType, setLocationType] = useState('WORK')
  const [selectedCoordinates, setSelectedCoordinates] = useState(null)

  const setActiveTransportMaterial = (type) => (e) => {
    setLocationType(type)
  }

  const isValidCoordinates = (value) => {
    let valueToRegex = value
    valueToRegex = valueToRegex?.replaceAll(/n/gi, '')
    valueToRegex = valueToRegex?.replaceAll(/e/gi, '')

    const regex = new RegExp(
      /^([-+]?)([\d]{1,2})((\.)?(\d+)(,))(\s*)(([-+]?)([\d]{1,3})((\.)?(\d+))?)$/g
    )
    if (!regex.test(valueToRegex)) {
      return <Trans>Invalid coordinates format</Trans>
    }
    return undefined
  }

  const prepareCreateLocationData = (values) => {
    return {
      ...values,
      type: locationType,
    }
  }

  const handleCreateLocation = (values) => {
    const data = prepareCreateLocationData(values)
    createLocation(data)
      .then((res) => {
        fireSuccessToast(<Trans>New location created.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Location create failed.</Trans>)
      })
      .finally(() => {
        getLocations()
        onClose()
      })
  }

  // simple HTML5 geolocation https://www.w3schools.com/html/html5_geolocation.asp
  const handleLocationFromDevice = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSetSelectedCoordinates, handleMapError, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
    } else {
      fireErrorToast(<Trans>Geolocation is not supported by this browser.</Trans>)
    }
  }

  const handleSetSelectedCoordinates = (position) => {
    // .toFixed(9) is rounding coords on 9 decimal places, 9 is enough to be precise
    setSelectedCoordinates(
      `${position.coords.latitude.toFixed(9)}, ${position.coords.longitude.toFixed(9)}`
    )
  }

  useEffect(() => {
    if (locationFromMap?.x && locationFromMap?.y) {
      setSelectedCoordinates(`${locationFromMap.y.toFixed(9)}, ${locationFromMap.x.toFixed(9)}`)
    }
  }, [locationFromMap])

  return (
    <>
      <GridItem xs={12} container direction={'row'}>
        <GridItem
          onClick={setActiveTransportMaterial('WORK')}
          container
          xs={3}
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
          className={cx(
            classes.newLocation,
            classes.workLocation,
            locationType === 'WORK' && classes.workLocationType
          )}
        >
          <Work />
          <span>
            <Trans>Work station</Trans>
          </span>
        </GridItem>

        <GridItem
          onClick={setActiveTransportMaterial('DESTINATION')}
          container
          xs={3}
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
          className={cx(
            classes.newLocation,
            classes.destinationLocation,
            locationType === 'DESTINATION' && classes.destinationLocationType
          )}
        >
          <Destination />
          <span>
            <Trans>Destination</Trans>
          </span>
        </GridItem>

        <GridItem
          onClick={setActiveTransportMaterial('GAS_STATION')}
          container
          xs={3}
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
          className={cx(
            classes.newLocation,
            classes.gasStationLocation,
            locationType === 'GAS_STATION' && classes.gasStationLocationType
          )}
        >
          <Gas />
          <span>
            <Trans>Gas station</Trans>
          </span>
        </GridItem>

        <GridItem
          onClick={setActiveTransportMaterial('SERVICE_STATION')}
          container
          xs={3}
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
          className={cx(
            classes.newLocation,
            classes.serviceStationLocation,
            locationType === 'SERVICE_STATION' && classes.serviceStationLocationType
          )}
        >
          <Service />
          <span>
            <Trans>Service</Trans>
          </span>
        </GridItem>
      </GridItem>
      <Box p={5}>
        <Form
          onSubmit={handleCreateLocation}
          initialValues={(values) => ({
            ...values,
            coordinates: selectedCoordinates,
          })}
        >
          {(formProps) => {
            return (
              <form onSubmit={formProps.handleSubmit}>
                <GridContainer>
                  <GridItem
                    xs={locationType !== 'WORK' ? 6 : 12}
                    className={locationType !== 'WORK' ? classes.smallGridPadding : ''}
                  >
                    <Field
                      name="name"
                      label={<Trans>Location name</Trans>}
                      component={TextInput}
                      placeholder={'Bruntálský les'}
                      validate={required}
                    />
                  </GridItem>

                  {locationType !== 'WORK' && (
                    <GridItem xs={6} className={classes.smallGridPadding}>
                      <Field
                        name="address"
                        label={<Trans>Location address</Trans>}
                        component={TextInput}
                        placeholder={'Jateční 854, 280 02 Kolín'}
                      />
                    </GridItem>
                  )}

                  <GridItem
                    container
                    xs={12}
                    alignItems={'flex-end'}
                    className={classes.fieldsPadding}
                  >
                    <GridItem xs={12} sm={6} className={classes.fieldsPaddingRight}>
                      <Field
                        name="coordinates"
                        variant="outlined"
                        label={<Trans>Coordinates</Trans>}
                        component={TextInput}
                        placeholder="50.01788, 15.20233"
                        validate={composeValidators(required, isValidCoordinates)}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={3}>
                      <PrimaryButton
                        text={<Trans>Select from map</Trans>}
                        textPadding={0}
                        onClick={handleLocationFromMap}
                        size={'medium'}
                        fullWidth={true}
                        buttonBottomMargin={true}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={3}>
                      <PrimaryButton
                        text={<Trans>Device location</Trans>}
                        textPadding={0}
                        onClick={handleLocationFromDevice}
                        size={'medium'}
                        fullWidth={true}
                        buttonBottomMargin={true}
                      />
                    </GridItem>
                  </GridItem>

                  <GridItem xs={12}>
                    <Field
                      name="description"
                      label={<Trans>Location description</Trans>}
                      component={TextInput}
                      rows={3}
                      multiline={true}
                    />
                  </GridItem>
                  <GridItem container justify={'flex-end'}>
                    <GridItem>
                      <PrimaryButton
                        formSubmit={true}
                        size={'small'}
                        type="submit"
                        text={<Trans>Create</Trans>}
                      />
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </form>
            )
          }}
        </Form>
      </Box>
    </>
  )
}

CreateLocationForm.propTypes = {
  classes: PropTypes.object,
  locationFromMap: PropTypes.object,
  onClose: PropTypes.func,
  getLocations: PropTypes.func,
  createLocation: PropTypes.func,
  handleLocationFromMap: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLocations,
      createLocation,
    },
    dispatch
  )
}

export default compose(
  withStyles(fieldStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(CreateLocationForm)
