import React, {useEffect, useState} from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import {TextField, Typography} from '@material-ui/core'
import BoxFullWidth from 'component/material/BoxFullWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import machinesPageStyle from 'component/MachinePage/machinesPageStyle'
import {bindActionCreators, compose} from 'redux'
import {createMachine, getMachines} from 'redux/action/machineAction'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import MachineTable from 'component/MachinePage/MachineTable'
import MachineIcon from 'component/icon/MachineIcon'
import PrimaryButton from 'component/material/PrimaryButton'
import NewAssetIcon from 'component/icon/NewAssetIcon'
import DialogWindow from 'component/material/DialogWindow'
import {fireSuccessToast} from 'helper/functions'
import CreateMachineForm from 'component/MachinePage/CreateMachineForm'

const MachinePage = (props) => {
  const {classes, getMachines, createMachine} = props

  const [openCreateMachine, setOpenCreateMachine] = useState(false)
  const [tableSort, setTableSort] = useState({orderBy: null, orderDirection: 'ASC'})
  const [onlyArchivedFilter, setOnlyArchivedFilter] = useState(false)

  const handleOnlyArchived = (e) => {
    setOnlyArchivedFilter(e.target.checked)
  }

  const handleOpenCreateMachine = () => {
    setOpenCreateMachine(true)
  }
  const handleCloseCreateMachine = () => {
    setOpenCreateMachine(false)
  }
  const handleCreateMachine = (values) => {
    createMachine(values)
      .then((res) => {
        handleCloseCreateMachine()
        fireSuccessToast(<Trans>New machine created.</Trans>)
      })
      .catch((err) => {
        fireSuccessToast(<Trans>Machine create failed.</Trans>)
      })
  }

  const handleChangeMachinesSearch = (e) => {}

  useEffect(() => {
    getMachines(onlyArchivedFilter, tableSort)
  }, [onlyArchivedFilter, tableSort])

  const handleTableSort = (orderBy) => (e) => {
    if (tableSort.orderBy === orderBy) {
      if (tableSort.orderDirection === 'ASC') {
        return setTableSort({orderBy: orderBy, orderDirection: 'DESC'})
      } else {
        // reset the sort
        return setTableSort({orderBy: null, orderDirection: 'ASC'})
      }
    } else {
      return setTableSort({orderBy: orderBy, orderDirection: 'ASC'})
    }
  }

  return (
    <GridContainer alignItems={'flex-start'}>
      {/* CREATE MACHINE DIALOG */}
      <DialogWindow
        title={<Trans>Create machine</Trans>}
        maxWidth={'sm'}
        open={openCreateMachine}
        onClose={handleCloseCreateMachine}
      >
        <CreateMachineForm handleSubmit={handleCreateMachine} />
      </DialogWindow>

      <BoxFullWidth px={6} py={4} className={classes.topBar}>
        <GridItem container xs={12} alignItems={'center'}>
          <MachineIcon className={classes.topBarIcon} />
          <Typography component={'span'} variant={'h3'} color={'primary'}>
            <b>
              <Trans>Machines</Trans>
            </b>
          </Typography>
        </GridItem>
      </BoxFullWidth>
      <GridContainer
        alignItems={'flex-start'}
        direction={'column'}
        className={classes.tableContainer}
      >
        <BoxFullWidth px={6} pb={4} pt={2}>
          <MachineTable
            onlyArchivedFilter={onlyArchivedFilter}
            handleOnlyArchived={handleOnlyArchived}
            handleTableSort={handleTableSort}
            tableSort={tableSort}
          />
        </BoxFullWidth>
        <BoxFullWidth px={6} py={2}>
          <GridContainer justify={'flex-end'} alignItems={'center'}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              onClick={handleOpenCreateMachine}
              text={
                <GridContainer alignItems={'center'}>
                  <NewAssetIcon />
                  <Trans>New machine</Trans>
                </GridContainer>
              }
            />
          </GridContainer>
        </BoxFullWidth>
      </GridContainer>
    </GridContainer>
  )
}

MachinePage.propTypes = {
  classes: PropTypes.object,
  getMachines: PropTypes.func,
  createMachine: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMachines,
      createMachine,
    },
    dispatch
  )
}

export default compose(
  withStyles(machinesPageStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(MachinePage)
