const materialStyle = (theme) => ({
  flexGrow: {
    flexGrow: 1,
  },

  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },

  fullHeight: {
    height: '100%',
  },

  // remove bottom line under last row in table
  tableRow: {
    '&:last-child': {
      '& .MuiTableCell-body': {
        borderBottom: 'none',
      },
    },
    // first and last cell in row are without left/right padding
    '& .MuiTableCell-root:first-child': {
      paddingLeft: '0',
    },
    '& .MuiTableCell-root:last-child': {
      paddingRight: '0',
    },
  },

  // solve same height and margin of expanded and not expanded accordion
  accordionSumRoot: {
    minHeight: 60,
    height: 60,
    padding: '0 10px',
    borderBottom: '1px solid ' + theme.palette.secondary.light,
    boxSizing: 'border-box',
    color: theme.palette.primary.main,

    '&.Mui-expanded': {
      minHeight: '60px',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  accordionMargin: {
    '&.Mui-expanded': {
      margin: '12px 0 !important',
    },
  },
  accordionDetailPadding: {
    padding: '0 10px',
  },
  accordionRoot: {
    '&.Mui-expanded': {
      marginTop: 0,
      backgroundColor: theme.palette.accordionBackground,
    },
  },

  // dialog window styling
  dialogTitle: {
    paddingRight: 62,
    paddingLeft: 62,

    '& h2': {
      fontWeight: 700,
    },
    minHeight: 100,
    borderBottom: '1px solid ' + theme.palette.primary.light,
  },
  dialogContent: {
    padding: '0 !important',
  },
  dialogActions: {
    padding: theme.spacing(4),
  },
  dialogCloseButton: {
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
    padding: 20,
  },

  iconButton: {
    zIndex: 1,
    width: 38,
    height: 38,
    border: '1px solid ' + theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    background: theme.palette.background.paper,
    webkitBoxShadow: '0px 0px 10px -5px rgba(0,0,0,0.65)',
    mozBoxShadow: '0px 0px 10px -5px rgba(0,0,0,0.65)',
    boxShadow: '0px 0px 10px -5px rgba(0,0,0,0.65)',
    '& svg': {
      transition: 'opacity 100ms',
      opacity: 0.5,
    },

    '&:hover': {
      transition: 'border 100ms, background 100ms',
      border: '1px solid ' + theme.palette.secondary.dark,
      background: theme.palette.background.default,

      '& svg': {
        transition: 'opacity 100ms',
        opacity: 1,
      },
    },
  },

  // hide file input
  fileInput: {
    display: 'none',
  },

  uploadWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& label': {
      minHeight: 'auto',
      minWidth: 'auto',
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
  },

  loader: {
    border: '5px solid transparent',
    borderRadius: '50%',
    borderTop: '5px solid ' + theme.palette.primary.main,
    width: 40,
    height: 40,
    animation: '$spin 1.2s linear infinite',
    margin: '0 auto',
    padding: 10,
  },

  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'},
  },

  // icon in warning / confirmation dialog
  warningDialogTitleIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  warningDialogTitle: {
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  warningDialogIcon: {
    fill: theme.palette.warning.main,
    height: 50,
    width: 50,
    paddingRight: theme.spacing(3),
  },

  // primary button padding adjusted to design
  primaryButtonPadding: {
    minHeight: 42,
    padding: '8px 14px',
    transition: 'opacity 200ms',
    '&:hover': {
      transition: 'opacity 200ms',
      opacity: 0.9,
      backgroundColor: theme.palette.primary.main,
    },
    '&.MuiButton-outlined': {
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
  },

  submitFormButton: {
    padding: '12px 14px',
    height: 42,
    fontSize: 16,
    boxSizing: 'border-box',
    width: 'max-content',
  },

  buttonBottomMargin: {
    marginBottom: '23px',
  },
})

export default materialStyle
