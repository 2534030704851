import {storageGet, storageSaveState, storageSet} from 'helper/functions'
import {sendOfflineAction} from 'redux/action/offlineActions'
import {
  OFFLINE_ACTION_POP,
  OFFLINE_FIX_SHIFT_ID,
  SHIFT_CREATE_FAIL,
  SHIFT_UPDATE_FAIL,
  TRANSPORT_CREATE_FAIL,
  TRANSPORT_DELETE_FAIL,
  TRANSPORT_DELETE_OFFLINE_ACTION,
  TRANSPORT_EDIT_FAIL,
} from 'redux/actionType'

const initState = storageGet('offlineActions') || {
  actions: [],
  lastActionWasPop: false,
}

const offlineActionsReducer = (state = initState, action) => {
  switch (action.type) {
    case SHIFT_CREATE_FAIL:
    case SHIFT_UPDATE_FAIL:
    case TRANSPORT_CREATE_FAIL:
    case TRANSPORT_DELETE_FAIL:
    case TRANSPORT_EDIT_FAIL: {
      const newState = {
        ...state,
        actions: action.isRepeated
          ? [action].concat(state.actions)
          : state.actions.concat([action]),
        lastActionWasPop: false,
      }
      storageSet('offlineActions', newState)
      return newState
    }

    case OFFLINE_ACTION_POP: {
      const [, ...actionsShifted] = state.actions
      const newState = {
        ...state,
        actions: actionsShifted,
        // to je proto, aby se po každém pokusu o odeslání akcí změnil store
        // a zavolala se funkce useEffect v OfflineActions.jsx
        lastActionWasPop: true,
      }
      storageSet('offlineActions', newState)
      return newState
    }

    case OFFLINE_FIX_SHIFT_ID: {
      console.log('OFFLINE_FIX_SHIFT_ID')
      const actions = state.actions.map((item) => {
        if (
          [
            TRANSPORT_CREATE_FAIL,
            SHIFT_UPDATE_FAIL,
            TRANSPORT_DELETE_FAIL,
            TRANSPORT_EDIT_FAIL,
          ].includes(item.type) &&
          item.data.isOffline &&
          item.data.offlineId === action.offlineId
        ) {
          item.data.isOffline = false
          item.shiftId = action.shiftId
          console.log('---->' + item.type + item.data.offlineId + '/' + item.shiftId)
        } else {
          console.log('---->skipping action ' + item.type + item.data.offlineId)
        }
        return item
      })
      console.log('actions=')
      console.log(actions)
      const newState = {
        ...state,
        actions,
      }
      storageSet('offlineActions', newState)
      return newState
    }

    case TRANSPORT_DELETE_OFFLINE_ACTION: {
      console.log(action)
      const newActions = state.actions.slice(0)
      const transportIndex = newActions.findIndex(
        (a) =>
          action.transportOfflineId === a.data?.transportOfflineId &&
          a.type === TRANSPORT_CREATE_FAIL
      )
      console.log({transportIndex})
      if (transportIndex >= 0) {
        newActions.splice(transportIndex, 1)
      }
      console.log(newActions)
      return {
        ...state,
        actions: newActions,
      }
    }

    default: {
      return state
    }
  }
}

export default offlineActionsReducer
