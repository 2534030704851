import {storageGet, storageSaveState} from 'helper/functions'
import {
  MACHINE_LIST_REQUEST,
  MACHINE_LIST_SUCCESS,
  MACHINE_LIST_FAIL,
  MACHINE_CREATE_REQUEST,
  MACHINE_CREATE_SUCCESS,
  MACHINE_CREATE_FAIL,
  MACHINE_UPDATE_REQUEST,
  MACHINE_UPDATE_SUCCESS,
  MACHINE_UPDATE_FAIL,
} from 'redux/actionType'

const initState = storageGet('machines') || {
  loading: false,
  list: [],
}

const machineReducer = (state = initState, action) => {
  switch (action.type) {
    case MACHINE_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case MACHINE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.data.objects,
      }
    }
    case MACHINE_LIST_FAIL: {
      return {
        ...state,
        ...storageGet('machines'),
        loading: false,
      }
    }

    case MACHINE_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case MACHINE_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: [...state.list, {...action.data}],
      }
    }
    case MACHINE_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case MACHINE_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case MACHINE_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case MACHINE_UPDATE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default storageSaveState(machineReducer, 'machines')
