import React from 'react'
import {ReactComponent as Location} from 'style/asset/locations.svg'
import PropTypes from 'prop-types'

const LocationIcon = (props) => {
  const {className, ...rest} = props

  return <Location {...rest} className={className} />
}

LocationIcon.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  rest: PropTypes.object,
}

export default LocationIcon
