import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import editFieldStyle from 'component/editField/editFieldStyle'

const EditFieldDefaultValue = (props) => {
  const {value, classes} = props

  return <div className={classes.defaultValue}>{value}</div>
}

export default withStyles(editFieldStyle)(EditFieldDefaultValue)
