import React, {useEffect, useState} from 'react'
import GridContainer from 'component/material/GridContainer'
import MapPanel from 'component/MapLayoutPage/MapPanel'
import Map from 'component/MapLayoutPage/Map'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {parseCoordinates} from 'helper/functions'
import {Trans} from '@lingui/macro'
import DialogWindow from 'component/material/DialogWindow'
import CreateLocationForm from 'component/MapLayoutPage/CreateLocationForm'
import {createLocation} from 'redux/action/locationAction'

const MapPage = (props) => {
  const {classes, locations, activePage} = props

  const [mapCenter, setMapCenter] = useState({x: 14.438158, y: 50.0775195})
  const [currentLocation, setCurrentLocation] = useState(null)
  const [locationFromMap, setLocationFromMap] = useState(null)
  const [gettingLocationFromMap, setGettingLocationFromMap] = useState(false)
  const [openLocationFromMapId, setOpenLocationFromMapId] = useState(null)
  const [openCreateLocation, setOpenCreateLocation] = useState(false)

  const onOpenLocation = (location) => {
    if (location?.coordinates) {
      setMapCenter(parseCoordinates(location.coordinates))
    }
  }

  const showCurrentLocation = (location) => {
    setCurrentLocation(location)
    setMapCenter(location)
  }

  const locationsParsed = locations?.map((loc) => {
    const coordinates = parseCoordinates(loc.coordinates)
    return {
      x: coordinates.x,
      y: coordinates.y,
      name: loc.name,
      type: loc.type,
      id: loc.id,
    }
  })

  const getLocationFromMap = (coords) => {
    setLocationFromMap(coords)
  }

  const handleGettingLocationFromMap = () => {
    setGettingLocationFromMap((prevState) => !prevState)
  }

  const openLocationDetailFromMap = (id) => {
    setOpenLocationFromMapId(id)
  }

  const closeLocationDetailFromMap = () => {
    setOpenLocationFromMapId(null)
  }

  const handleOpenCreateLocation = () => {
    setOpenCreateLocation(true)
  }
  const handleCloseCreateLocation = () => {
    setOpenCreateLocation(false)
  }

  const handleLocationFromMap = () => {
    handleGettingLocationFromMap()
  }

  useEffect(() => {
    setLocationFromMap(null)
  }, [locations])

  return (
    <>
      {/* CREATE LOCATION DIALOG */}
      <DialogWindow
        className={gettingLocationFromMap ? classes.hideModal : ''}
        title={<Trans>New location</Trans>}
        maxWidth={'sm'}
        open={openCreateLocation}
        onClose={handleCloseCreateLocation}
      >
        <CreateLocationForm
          locationFromMap={locationFromMap}
          onClose={handleCloseCreateLocation}
          handleLocationFromMap={handleLocationFromMap}
        />
      </DialogWindow>
      <GridContainer direction={'row'} className={classes.mapLayoutPage}>
        <Map
          gettingLocationFromMap={gettingLocationFromMap}
          getLocationFromMap={getLocationFromMap}
          openLocationDetailFromMap={openLocationDetailFromMap}
          mapCenter={mapCenter}
          locations={locationsParsed}
          currentLocation={currentLocation}
          activePage={activePage}
        />

        <MapPanel
          locationFromMapCoords={locationFromMap}
          handleLocationFromMap={handleLocationFromMap}
          gettingLocationFromMap={gettingLocationFromMap}
          openLocationFromMapId={openLocationFromMapId}
          closeLocationDetailFromMap={closeLocationDetailFromMap}
          handleOpenCreateLocation={handleOpenCreateLocation}
          onOpenLocation={onOpenLocation}
          setMyLocation={showCurrentLocation}
        />
      </GridContainer>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createLocation,
    },
    dispatch
  )
}

export default compose(
  withStyles(mapLayoutPageStyle),
  connect((store) => {
    return {
      locations: store.locations.list,
      activePage: store.activePage.page,
    }
  }, mapDispatchToProps)
)(MapPage)
