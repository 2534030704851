import React from 'react'
import GridItem from 'component/material/GridItem'
import {TextField} from '@material-ui/core'
import GridContainer from 'component/material/GridContainer'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/CloseRounded'
import PropTypes from 'prop-types'
import EditIconButton from 'component/editField/EditIconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import EditFieldLabel from 'component/editField/EditFieldLabel'
import cx from 'classnames'
import CheckIconButton from 'component/editField/CheckIconButton'
import CloseIconButton from 'component/editField/CloseIconButton'

const EditTextAreaField = (props) => {
  const {
    defaultValue,
    value,
    label,
    editing,
    enabled,
    name,
    onChange,
    onSave,
    onEdit,
    textFieldProps,
    classes,
  } = props

  return (
    <GridItem
      container
      xs={12}
      className={cx(
        classes.borderBottom,
        classes.borderRight,
        classes.textArea,
        classes.leftEdge,
        classes.rightEdge
      )}
    >
      <GridContainer direction={'row'} alignItems={'center'}>
        <GridItem xs={8}>
          <EditFieldLabel value={label} />
        </GridItem>
        <GridItem container justify={'flex-end'} xs={4}>
          {enabled && (
            <>
              {editing ? (
                <GridContainer justify={'flex-end'}>
                  <CheckIconButton onSave={onSave} />
                  <CloseIconButton onClose={onEdit('')} />
                </GridContainer>
              ) : (
                <EditIconButton onEdit={onEdit(name)} />
              )}
            </>
          )}
        </GridItem>
      </GridContainer>
      <GridItem xs={12}>
        {editing ? (
          <TextField
            {...textFieldProps}
            variant={'outlined'}
            fullWidth
            name={name}
            value={value}
            onChange={onChange}
          />
        ) : (
          <GridItem xs={12}>
            <div className={classes.textAreaDefaultValue}>{defaultValue}</div>
          </GridItem>
        )}
      </GridItem>
    </GridItem>
  )
}

EditTextAreaField.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  editing: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
}

EditTextAreaField.defaultProps = {
  enabled: true,
}

export default withStyles(locationDetailStyle)(EditTextAreaField)
