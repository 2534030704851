const iconStyle = (theme) => ({
  editIcon: {
    width: 20,
    height: 20,
  },

  newAssetIcon: {
    paddingRight: 14,
    width: 20,
    height: 20,
    fill: theme.palette.primary.contrastText,
  },

  iconSingle: {
    paddingRight: 0,
  },

  restoreIcon: {
    fill: theme.palette.success.dark + ' !important',
  },
  checkIcon: {
    width: 30,
    height: 30,
    fill: theme.palette.success.dark + ' !important',
  },
  archiveIcon: {
    fill: theme.palette.error.dark + ' !important',
  },

  closeIcon: {
    width: 16,
    height: 16,
  },

  locationTypeIcon: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
  },
  locationTypeWork: {
    backgroundColor: theme.palette.work,
    '& svg': {
      fill: theme.palette.background.paper,
    },
  },
  locationTypeDestination: {
    backgroundColor: theme.palette.destination,
    '& svg': {
      paddingLeft: 1,
      fill: theme.palette.background.paper,
    },
  },
  locationTypeGas: {
    backgroundColor: theme.palette.gas,
    '& svg': {
      fill: theme.palette.background.paper,
    },
  },
  locationTypeService: {
    backgroundColor: theme.palette.service,
    '& svg': {
      paddingLeft: 1,
      fill: theme.palette.background.paper,
    },
  },
})

export default iconStyle
