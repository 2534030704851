import React, {useState} from 'react'
import GridItem from 'component/material/GridItem'
import CloseIcon from '@material-ui/icons/CloseRounded'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import EditFieldLabel from 'component/editField/EditFieldLabel'
import {Trans} from '@lingui/macro'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import cx from 'classnames'
import moment from 'moment'
import Box from '@material-ui/core/Box'
import IconButton from 'component/material/IconButton'
import DotsIcon from 'component/icon/DotsIcon'
import Menu from '@material-ui/core/Menu'
import Fade from '@material-ui/core/Fade'
import GridContainer from 'component/material/GridContainer'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import ArchiveIcon from '@material-ui/icons/Archive'
import WarningDialog from 'component/material/WarningDialog'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {fireErrorToast, fireSuccessToast} from 'helper/functions'
import {
  createContract,
  deleteContract,
  updateContract,
  getContracts,
} from 'redux/action/contractAction'
import {getLocation} from 'redux/action/locationAction'
import {DATE_FORMAT, DB_DATE_FORMAT} from 'helper/constants'
import {ReactComponent as Delete} from 'style/asset/bin.svg'
import {ReactComponent as New} from 'style/asset/new-asset.svg'
import DialogWindow from 'component/material/DialogWindow'
import CreateContractForm from 'component/MapLayoutPage/locationDetail/CreateContractForm'

const SelectContractField = (props) => {
  const {
    value,
    options,
    onChange,
    responsiveness = {xs: 12},
    classes,
    onClose,
    loading,
    updateContract,
    locationDetail,
    contractDetail,
    deleteContract,
    createContract,
    getLocation,
    getContracts,
    isManager,
  } = props

  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openCreateContract, setOpenCreateContract] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = (e) => {
    setAnchorEl(e.currentTarget)
    setMenuOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const handleClickInMenu = (item) => (e) => {
    handleClose()
    item.onClick(name)
  }

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }
  const handleDeleteContract = () => {
    deleteContract(locationDetail.id, contractDetail.id)
      .then((res) => {
        fireSuccessToast(<Trans>Contract deleted.</Trans>)
        getContracts(locationDetail.id)
      })
      .catch((err) => {
        fireSuccessToast(<Trans>Contract delete failed.</Trans>)
      })
      .finally(() => {
        handleCloseDeleteDialog()
      })
  }

  const prepareContractEditData = (field) => {
    let submitData = {...contractDetail}

    submitData.machine_id = contractDetail?.machine?.id

    delete submitData.machine
    delete submitData.images

    return {...submitData, ...field}
  }

  // contract archive
  const handleOpenArchiveDialog = () => {
    setOpenArchiveDialog(true)
  }
  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog(false)
  }
  const handleArchiveContract = () => {
    const archiveIt = contractDetail && !contractDetail.is_archived
    updateContract(
      locationDetail.id,
      contractDetail.id,
      prepareContractEditData({is_archived: archiveIt})
    )
      .then((res) => {
        if (archiveIt) {
          fireSuccessToast(<Trans>Contract archived.</Trans>)
        } else {
          fireSuccessToast(<Trans>Contract restored.</Trans>)
        }
        getContracts(locationDetail.id)
      })
      .catch((err) => {
        if (archiveIt) {
          fireErrorToast(<Trans>Contract archive failed.</Trans>)
        } else {
          fireSuccessToast(<Trans>Contract restore failed.</Trans>)
        }
      })
      .finally(() => {
        handleCloseArchiveDialog()
      })
  }

  const openCreateContractDialog = () => {
    setOpenCreateContract(true)
  }
  const closeContractDialog = () => {
    setOpenCreateContract(false)
  }

  const prepareContractCreateData = (data) => {
    return {
      description: data.description,
      start: data.start.format(DB_DATE_FORMAT),
      end: data.end.format(DB_DATE_FORMAT),
      type: data.type,
      status: data.status,
      machine_id: data.machine_id,
      netto: data.netto / (data.material_type === 'WOOD' ? 1 : 2.6),
      material_type: data.material_type,
    }
  }

  const handleCreateContract = (data) => {
    createContract(locationDetail.id, prepareContractCreateData(data))
      .then((resCreateContract) => {
        fireSuccessToast(<Trans>New contract created.</Trans>)
        getLocation(locationDetail.id).then(() => {
          onChange({target: {value: resCreateContract?.id}})
        })
      })
      .catch((err) => {
        fireSuccessToast(<Trans>Contract create failed.</Trans>)
      })
      .finally(() => {
        closeContractDialog()
      })
  }

  const menuItems = isManager ? [
    {
      onClick: handleOpenArchiveDialog,
      text: contractDetail?.is_archived ? (
        <Trans>Restore contract from archive</Trans>
      ) : (
        <Trans>Archive contract</Trans>
      ),
      icon: contractDetail?.is_archived ? <UnarchiveIcon /> : <ArchiveIcon />,
    },
    {
      onClick: handleOpenDeleteDialog,
      text: <Trans>Delete contract</Trans>,
      icon: <Delete />,
    },
    {
      onClick: openCreateContractDialog,
      text: <Trans>Create contract</Trans>,
      icon: <New />,
    },
  ] : [{
    onClick: openCreateContractDialog,
    text: <Trans>Create contract</Trans>,
    icon: <New />,
  }]

  return (
    <>
      {/* CONFIRM ARCHIVE CONTRACT */}
      <WarningDialog
        title={
          contractDetail?.is_archived ? (
            <Trans>Restore contract from archive</Trans>
          ) : (
            <Trans>Archive contract</Trans>
          )
        }
        message={`${moment(contractDetail?.start).format(DATE_FORMAT)} - ${moment(
          contractDetail?.end
        ).format(DATE_FORMAT)}`}
        open={openArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onConfirm={handleArchiveContract}
      />

      {/* CONFIRM DELETE CONTRACT */}
      <WarningDialog
        title={<Trans>Delete contract</Trans>}
        message={`${moment(contractDetail?.start).format(DATE_FORMAT)} - ${moment(
          contractDetail?.end
        ).format(DATE_FORMAT)}`}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteContract}
      />

      {/* CREATE CONTRACT DIALOG */}
      <DialogWindow
        title={<Trans>New contract</Trans>}
        maxWidth={'sm'}
        open={openCreateContract}
        onClose={closeContractDialog}
      >
        <CreateContractForm handleSubmit={handleCreateContract} />
      </DialogWindow>

      <GridItem container {...responsiveness} alignItems={'center'} justify={'space-between'}>
        <div className={cx(classes.borderBottom, classes.selectContractField)}>
          <EditFieldLabel
            className={classes.labelPaddingRight}
            value={<Trans>Contract selection:</Trans>}
          />

          <Select
            variant="outlined"
            onChange={onChange}
            value={value ? value : ''}
            className={classes.flexGrowSelect}
            classes={{root: classes.contractSelectRoot}}
          >
            {loading ? (
              <MenuItem key={'loading'} value={''}>
                Loading...
              </MenuItem>
            ) : (
              options.map((option) => (
                <MenuItem
                  key={option.id}
                  classes={{root: classes.contractSelectItem}}
                  value={option.id}
                >
                  {option.is_archived ? <ArchiveIcon /> : ''}&nbsp;
                  {`${moment(option.start).format(DATE_FORMAT)} - ${moment(option.end).format(
                    DATE_FORMAT
                  )}`}
                </MenuItem>
              ))
            )}
          </Select>
          {(
            <>
              <Box pl={2}>
                <IconButton icon={<DotsIcon />} onClick={openMenu} />
              </Box>

              <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                {menuItems.map((item, index) => (
                  <MenuItem key={index} onClick={handleClickInMenu(item)}>
                    <GridContainer alignItems={'center'}>
                      {item.icon && (
                        <Box pr={3} className={classes.menuItemIcon}>
                          {item.icon}
                        </Box>
                      )}
                      {item.text}
                    </GridContainer>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </div>
        <div
          className={cx(classes.closeButtonBorders, classes.locationDetailCloseButton)}
          onClick={onClose}
        >
          <CloseIcon className={classes.locationCloseButton} />
        </div>
      </GridItem>
    </>
  )
}

SelectContractField.propTypes = {
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  options: PropTypes.array,
  classes: PropTypes.object,
  locationDetail: PropTypes.object,
  contractDetail: PropTypes.object,
  deleteContract: PropTypes.func,
  updateContract: PropTypes.func,
  createContract: PropTypes.func,
  getLocation: PropTypes.func,
  isManager: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLocation,
      updateContract,
      deleteContract,
      createContract,
      getContracts,
    },
    dispatch
  )
}

export default compose(
  withStyles(locationDetailStyle),
  connect((store) => {
    return {
      locationDetail: store.locations.detail,
      contractDetail: store.contracts.detail,
      isManager: store.user.myAccount.isManager,
    }
  }, mapDispatchToProps)
)(SelectContractField)
