import React from 'react'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import cx from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import iconStyle from 'component/icon/iconStyle'
import PropTypes from 'prop-types'

const EditIcon = (props) => {
  const {className, classes, ...rest} = props

  return <Edit {...rest} className={cx(className, classes.editIcon)} />
}

EditIcon.propTypes = {
  className: PropTypes.object,
  classes: PropTypes.object,
  rest: PropTypes.object,
}

export default withStyles(iconStyle)(EditIcon)
