import React from 'react'
import GridItem from 'component/material/GridItem'
import {CUBIC_METERS_TO_PRM} from 'helper/constants'
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded'
import IconButton from 'component/material/IconButton'
import DeleteIcon from 'component/icon/DeleteIcon'
import GridContainer from 'component/material/GridContainer'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import EditIcon from 'component/icon/EditIcon'

const SingleShiftRow = (props) => {
  const {classes, shift, transport, openDeleteTransportDialog, openEditTransportDialog} = props
  return (
    <GridContainer item className={classes.tableRowContainer}>
      <GridItem xs={3}>{transport?.delivery_note}</GridItem>

      <GridItem xs={true} container alignItems={'center'}>
        {transport?.material === 'RAW' ? (
          <b>{`${Number(transport?.netto)} m³`}</b>
        ) : (
          <b>{`${Math.round(Number(transport?.netto) * 10 * CUBIC_METERS_TO_PRM) / 10} prm`}</b>
        )}
        <span className={classes.transportArrowIcon}>
          <ArrowRightAltRoundedIcon />
        </span>
        {transport?.destination?.location_name ? (
          transport.destination.location_name
        ) : (
          <Trans>Unknown location</Trans>
        )}
      </GridItem>

      <GridItem
        xs={1}
        container
        direction={'row'}
        justify={'flex-end'}
        className={classes.transportTableButtons}
      >
        <IconButton
          onClick={openEditTransportDialog(shift.id, transport.id, transport.transportOfflineId)}
          icon={<EditIcon />}
        />
      </GridItem>
      <GridItem
        xs={1}
        container
        direction={'row'}
        justify={'flex-end'}
        className={classes.transportTableButtons}
      >
        <IconButton
          onClick={openDeleteTransportDialog(shift.id, transport.id, transport.transportOfflineId)}
          icon={<DeleteIcon />}
        />
      </GridItem>
    </GridContainer>
  )
}

SingleShiftRow.propTypes = {
  classes: PropTypes.object,
  shift: PropTypes.object,
  transport: PropTypes.object,
  openDeleteTransportDialog: PropTypes.func,
  openEditTransportDialog: PropTypes.func,
}

export default withStyles(locationDetailStyle)(SingleShiftRow)
