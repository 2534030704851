import React from 'react'
import GridItem from 'component/material/GridItem'

import PropTypes from 'prop-types'
import cx from 'classnames'
import EditFieldDefaultValue from 'component/editField/EditFieldDefaultValue'
import EditFieldLabel from 'component/editField/EditFieldLabel'
import GridContainer from 'component/material/GridContainer'
import CheckIconButton from 'component/editField/CheckIconButton'
import CloseIconButton from 'component/editField/CloseIconButton'
import EditIconButton from 'component/editField/EditIconButton'
import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import MenuItem from '@material-ui/core/MenuItem'

const EditSelectField = (props) => {
  const {
    defaultValue,
    value,
    label,
    onChange,
    onSave,
    onEdit,
    editing,
    enabled,
    responsiveness = {xs: 12, sm: 6},
    classes,
    borderSide,
    edgeSide,
    name,
    variant = 'standard',
    loading,
    options,
    emptyOption,
  } = props

  const classesObject = cx(
    classes.borderBottom,
    borderSide === 'right' && classes.borderRight,
    borderSide === 'left' && classes.borderLeft,
    edgeSide === 'left' && classes.leftEdge
  )

  return (
    <GridItem container {...responsiveness} className={classesObject} alignItems={'center'}>
      <GridItem container xs={true} direction={'column'}>
        {editing ? (
          <Select
            variant={variant}
            onChange={onChange}
            value={value ? value : ''}
            fullWidth={true}
            name={name}
            className={classes.flexGrowSelect}
          >
            {emptyOption && (
              <MenuItem key={'empty'} value={null} classes={{root: classes.emptyOption}}>
                {'----'}
              </MenuItem>
            )}
            {loading ? (
              <MenuItem key={'loading'} value={''}>
                Loading...
              </MenuItem>
            ) : (
              options.map((option, index) => (
                <MenuItem
                  key={index}
                  classes={{root: classes.contractSelectItem}}
                  value={option.id ? option.id : option.value}
                >
                  {option.name ? option.name : option.label}
                </MenuItem>
              ))
            )}
          </Select>
        ) : (
          <EditFieldDefaultValue value={defaultValue ? defaultValue : '- - - -'} />
        )}
        <EditFieldLabel value={label} />
      </GridItem>
      <GridItem>
        {enabled && (
          <>
            {editing ? (
              <GridContainer justify={'flex-end'} alignItems={'center'} direction={'column'}>
                <CheckIconButton onSave={onSave} />
                <CloseIconButton onClose={onEdit(null)} />
              </GridContainer>
            ) : (
              <EditIconButton onEdit={onEdit(name)} />
            )}
          </>
        )}
      </GridItem>
    </GridItem>
  )
}

EditSelectField.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editing: PropTypes.bool,
  emptyOption: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  responsiveness: PropTypes.object,
  classes: PropTypes.object,
  borderSide: PropTypes.string,
}

EditSelectField.defaultProps = {
  enabled: true,
}

export default withStyles(locationDetailStyle)(EditSelectField)
