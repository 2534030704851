import React, {useEffect} from 'react'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoginPage from 'page/LoginPage'
import PublicLayout from 'layout/PublicLayout/PublicLayout'
import {I18nProvider} from '@lingui/react'
import {i18n} from '@lingui/core'
import catalogEn from 'App/locale/en/messages'
import catalogCs from 'App/locale/cs/messages'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import toastStyle from 'component/toast/toastStyle'
import PrivateLayout from 'layout/PrivateLayout/PrivateLayout'
import MapLayoutPage from 'page/MapPage'
import moment from 'moment'
import 'moment/locale/cs'
import MachinesPage from 'page/MachinePage'
import UserPage from 'page/UserPage'
import {setActivePage} from 'redux/action/activePageAction'

function App(props) {
  const {globalSettings, classes, activePage, setActivePage} = props

  const activeLang = globalSettings.currentLanguage ? globalSettings.currentLanguage : 'cs'

  moment.locale('activeLang')

  i18n.load({
    en: catalogEn.messages,
    cs: catalogCs.messages,
  })

  i18n.activate(activeLang)

  useEffect(() => {
    if (window.location.pathname.includes('/auth/reset-password/')) {
      setActivePage('login')
    }
  }, [activePage])

  return (
    <I18nProvider i18n={i18n}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={2500}
        className={classes.containerStyle}
      />
      {activePage === 'login' ? (
        <PublicLayout>
          <LoginPage />
        </PublicLayout>
      ) : (
        <PrivateLayout>
          {activePage === 'map' ? (
            <MapLayoutPage />
          ) : activePage === 'machines' ? (
            <MachinesPage />
          ) : (
            activePage === 'users' && <UserPage />
          )}
        </PrivateLayout>
      )}
    </I18nProvider>
  )
}

App.propTypes = {
  globalSettings: PropTypes.object,
  classes: PropTypes.object,
  setActivePage: PropTypes.func,
  activePage: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setActivePage,
    },
    dispatch
  )
}

export default compose(
  withStyles(toastStyle),
  connect((store) => {
    return {
      globalSettings: store.globalSettings,
      activePage: store.activePage.page,
    }
  }, mapDispatchToProps)
)(App)
