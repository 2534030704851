import React from 'react'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  MACHINE_CREATE_FAIL,
  MACHINE_CREATE_REQUEST,
  MACHINE_CREATE_SUCCESS,
  MACHINE_LIST_FAIL,
  MACHINE_LIST_REQUEST,
  MACHINE_LIST_SUCCESS,
  MACHINE_UPDATE_FAIL,
  MACHINE_UPDATE_REQUEST,
  MACHINE_UPDATE_SUCCESS,
} from 'redux/actionType'
import {fireWarningOffline, fireWarningToast, globalApiErrorHandler} from 'helper/functions'
import {Trans} from '@lingui/macro'

export const getMachines = (isArchived, sortParams) => {
  return (dispatch) => {
    dispatch({type: MACHINE_LIST_REQUEST})
    return httpClient
      .get(endpoints.machines, {
        is_archived: isArchived || null,
        order_by: sortParams?.orderBy || null,
        order_direction: sortParams?.orderBy ? sortParams.orderDirection : null,
      })
      .then((res) => {
        dispatch({type: MACHINE_LIST_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: MACHINE_LIST_FAIL})
          fireWarningOffline()
        } else {
          globalApiErrorHandler(err, dispatch)
          return Promise.reject()
        }
      })
  }
}

export const createMachine = (data) => {
  return (dispatch) => {
    dispatch({type: MACHINE_CREATE_REQUEST})
    return httpClient
      .post(endpoints.machines, data)
      .then((res) => {
        dispatch({type: MACHINE_CREATE_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: MACHINE_CREATE_FAIL})
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}

export const updateMachine = (machineId, data) => {
  return (dispatch) => {
    dispatch({type: MACHINE_UPDATE_REQUEST})
    return httpClient
      .put(`${endpoints.machines}/${machineId}`, data)
      .then((res) => {
        dispatch({type: MACHINE_UPDATE_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: MACHINE_UPDATE_FAIL})
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}
