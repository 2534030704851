import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import materialStyle from 'component/material/materialStyle'
import Grid from '@material-ui/core/Grid'

const GridContainer = (props) => {
  const {children, className, classes, ...rest} = props
  return (
    <Grid container className={className} {...rest}>
      {children}
    </Grid>
  )
}

GridContainer.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
}

export default withStyles(materialStyle)(GridContainer)
