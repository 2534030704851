import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'

const PrimaryButton = (props) => {
  const {
    text,
    textPadding = 2,
    fullWidth = true,
    variant = 'contained',
    size = 'large',
    color = 'primary',
    classes,
    className,
    formSubmit = false,
    buttonBottomMargin = false,
    ...rest
  } = props

  return (
    <Button
      color={color}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      {...rest}
      className={cx(
        className,
        classes.primaryButtonPadding,
        formSubmit && classes.submitFormButton,
        buttonBottomMargin && classes.buttonBottomMargin
      )}
    >
      <Box p={textPadding}>{text}</Box>
    </Button>
  )
}

PrimaryButton.propTypes = {
  text: PropTypes.node,
  textPadding: PropTypes.number,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.object,
  rest: PropTypes.object,
}

export default withStyles(materialStyle)(PrimaryButton)
