import React from 'react'
import {RESPONSIVE_WIDTH} from './constants'

export default function useWindowSize() {
  const isSSR = typeof window === 'undefined'
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
    isMediaMobile: isSSR ? false : window.innerWidth < RESPONSIVE_WIDTH,
    isMediaScreen: isSSR ? true : window.innerWidth >= RESPONSIVE_WIDTH,
  })

  function changeWindowSize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isMediaMobile: window.innerWidth < RESPONSIVE_WIDTH,
      isMediaScreen: window.innerWidth >= RESPONSIVE_WIDTH,
    })
  }

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}
