import {storageGet, storageSaveState} from 'helper/functions'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_CREATE_FAIL,
  USER_CREATE_SUCCESS,
  USER_CREATE_REQUEST,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  MY_ACCOUNT_REQUEST,
  MY_ACCOUNT_SUCCESS,
  MY_ACCOUNT_FAIL,
} from 'redux/actionType'

const initState = storageGet('user') || {
  loading: false,
  data: {},
  list: [],
  detail: {},
  myAccount: {},
  myAccountLoading: false,
  isManager: false,
}

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    }
    case USER_LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case USER_LOGOUT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: {},
      }
    }
    case USER_LOGOUT_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case USER_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case USER_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.data.objects,
      }
    }
    case USER_LIST_FAIL: {
      return {
        ...state,
        ...storageGet('user'),
        loading: false,
      }
    }

    case USER_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case USER_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case USER_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case MY_ACCOUNT_REQUEST: {
      return {
        ...state,
        myAccountLoading: true,
      }
    }
    case MY_ACCOUNT_SUCCESS: {
      const isManager = action.data.roles?.findIndex((role) => role.name === 'MANAGER') >= 0 
      return {
        ...state,
        myAccountLoading: false,
        myAccount: {...action.data, isManager},
        isManager,
      }
    }
    case MY_ACCOUNT_FAIL: {
      return {
        ...state,
        myAccountLoading: false,
      }
    }

    default: {
      return state
    }
  }
}

export default storageSaveState(userReducer, 'user')
