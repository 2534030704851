import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {startCheckingOfflineActions} from 'redux/action/offlineActions'
import {bindActionCreators} from 'redux'

const OfflineActions = (props) => {
  const {startCheckingOfflineActions} = props

  useEffect(() => {
    startCheckingOfflineActions()
  }, [])
  return null
}

OfflineActions.propTypes = {
  offlineActions: PropTypes.object,
  popOfflineAction: PropTypes.func,
  sendOfflineAction: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      startCheckingOfflineActions,
    },
    dispatch
  )
}

export default connect((store) => {
  return {
    offlineActions: store.offlineActions,
  }
}, mapDispatchToProps)(OfflineActions)
