import React from 'react'
import {ENVIRONMENT, GATEWAY_URL} from 'helper/constants'
import browserHistory from 'helper/history'
import {MOCK_API, MOCK_PORT_MAPPING} from 'helper/configConstants'
import {toast} from 'react-toastify'
import Toast from 'component/toast/Toast'
import {Trans} from '@lingui/macro'
import {SET_ACTIVE_PAGE} from 'redux/actionType'
import moment from 'moment'

/**
 * Get environment parameter from .env file
 * @param {string} envName - name of parameter
 */
export const resolveEnv = (envName) => {
  if (ENVIRONMENT === 'production') {
    return window._env_[envName]
  }
  return process.env[envName]
}
export const isUserLogged = () => {
  return !!localStorage.getItem('sessionId')
}
/**
 * Redirect user to specific url, can be used outside of component
 * @param {string} route - url to be redirected to
 */
export const redirectTo = (route) => {
  browserHistory.push(route)
}

/* resize event sent to notify map about resized container */
const resizeEvent = document.createEvent('Event')
resizeEvent.initEvent('resize', true, true)
/**
 * Call window resize event to let map react to resized container
 */
export const windowResizeEvent = () => {
  window.dispatchEvent(resizeEvent)
}

/**
 * Add service name to gateway url or use mock if set in configConstants
 * @param {string} name - name of service
 */
export const getServiceUrl = (name) => {
  if (ENVIRONMENT !== 'production' && MOCK_API) {
    return `http://localhost:${MOCK_PORT_MAPPING[name]}`
  }
  return `${GATEWAY_URL}/api/${name}`
}

export const fireSuccessToast = (message, options) => {
  return toast.success(<Toast message={message} type="success" />, options)
}

export const fireErrorToast = (message, options = {autoClose: 5000}) => {
  return toast.error(<Toast message={message} type="error" />, options)
}

export const fireWarningToast = (message, options) => {
  return toast.warning(<Toast message={message} type="warning" />, options)
}

const includesMissingSessionId = (message) => {
  const words = message?.toLowerCase(message.split(' '))
  return words?.includes('missing') && words?.includes('header') && words?.includes("'session-id")
}

const includesGetPermissions = (message) => {
  return message?.includes('AuthClient#getPermissions')
}

const loopThroughErrors = (message, errors, dispatch) => {
  if (errors?.length !== 0) {
    errors.forEach((err) => {
      fireErrorToast(
        <>
          {message}: {err?.message}
        </>
      )
      if (err?.message === 'Session ID not authenticated.') {
        localStorage.removeItem('sessionId')
        dispatch && dispatch({type: SET_ACTIVE_PAGE, data: 'login'})
      }
    })
  } else {
    fireErrorToast(message)
  }
}

/**
 * Function used for basic error handling in catch(err=>globalApiErrorHandler(err))
 * @param error
 * @param dispatch from redux
 */
export const globalApiErrorHandler = (error, dispatch) => {
  if (error.response) {
    const {status, data, message} = error.response
    switch (status) {
      case 400: // bad request
        if (includesMissingSessionId(message)) {
          localStorage.removeItem('sessionId')
          dispatch && dispatch({type: SET_ACTIVE_PAGE, data: 'login'})
          break
        }
        data?.errors && loopThroughErrors(<Trans>Bad request</Trans>, data.errors, dispatch)
        return Promise.reject(error)
      case 401: // unauthorized
        fireErrorToast(<Trans>Unauthorized</Trans>)
        localStorage.removeItem('sessionId')
        dispatch && dispatch({type: SET_ACTIVE_PAGE, data: 'login'})
        break
      case 403: // forbidden
        data?.errors && loopThroughErrors(<Trans>Forbidden</Trans>, data.errors, dispatch)
        return Promise.reject(error)
      case 404: // not found
        fireErrorToast(<Trans>Resource not found</Trans>)
        break
      case 500: // internal server error
        if (includesGetPermissions(data.message)) {
          localStorage.removeItem('sessionId')
          fireErrorToast(<Trans>Unauthorized</Trans>)
          dispatch && dispatch({type: SET_ACTIVE_PAGE, data: 'login'})
        } else {
          fireErrorToast(<Trans>Internal server error</Trans>)
        }
        break
      default:
        fireErrorToast(<Trans>Unknown server error</Trans>)
        break
    }
  } else {
    if (error.message !== 'Network Error') {
      fireErrorToast(<Trans>Unknown error</Trans>)
      console.log(error)
      console.log(error.message)
    }
  }
  throw error
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const getDateTimeDBFormat = (date) => {
  let firstPart = moment(date).format().slice(0, 22)
  let secondPart = moment(date).format().slice(23)

  return firstPart + secondPart
}

export const parseCoordinates = (coordinates) => {
  return {
    x: coordinates.split(', ')[1],
    y: coordinates.split(', ')[0],
  }
}

export const handleMapError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return fireErrorToast(<Trans>User denied the request for Geolocation.</Trans>)
    case error.POSITION_UNAVAILABLE:
      return fireErrorToast(<Trans>Location information is unavailable.</Trans>)
    case error.TIMEOUT:
      return fireErrorToast(<Trans>The request to get user location timed out.</Trans>)
    case error.UNKNOWN_ERROR:
      return fireErrorToast(<Trans>An unknown error occurred.</Trans>)
  }
}

export const storageSet = (keyName, objectValue) => {
  localStorage.setItem(keyName, JSON.stringify(objectValue))
}

export const storageGet = (keyName) => {
  try {
    const itemFound = localStorage.getItem(keyName);
    if (itemFound === null) {
      // console.info(`>>storageGet: could not find item: ${keyName}`);
      return null;
    }
    return JSON.parse(localStorage.getItem(keyName))
  } catch (err) {
    console.log(err);
    return {}
  }
}

export const storageSaveState = (reducerFunction, keyName) => {
  return (state, action) => {
    const newState = reducerFunction(state, action)
    // ignore redux system actions (e.g. @@redux/INIT)
    if (!action.type.startsWith('@@')) {
      if (keyName instanceof Function) {
        storageSet(keyName(newState), newState)
      } else {
        storageSet(keyName, newState)
      }
    }
    return newState
  }
}

export function fireWarningOffline() {
  const timeDiff = (new Date() - fireWarningOffline.timestamp) / 1000
  if (fireWarningOffline.timestamp === undefined || timeDiff > 1) {
    fireWarningToast(<Trans>Offline data loaded</Trans>)
    fireWarningOffline.timestamp = new Date()
  }
}
