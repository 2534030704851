import React from 'react'
import Typography from '@material-ui/core/Typography'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import LoginForm from 'component/LoginPage/LoginForm'
import BoxFullWidth from 'component/material/BoxFullWidth'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import {withStyles} from '@material-ui/core'
const logo = 'logo.png'

const LoginPage = (props) => {
  const {classes} = props
  return (
    <GridContainer direction="row" justify="center" alignItems="center">
      <GridItem xs={10} sm={8} md={6} lg={4}>
        <GridContainer direction="column" justify="center" alignItems="center">
          <BoxFullWidth px={20} pb={20}>
            <BoxFullWidth pb={10}>
              {/* <Typography variant="h2" color="primary" align="center" gutterBottom>
                <b>
                  <Trans>LogBook</Trans>
                </b>
              </Typography> */}
              <img src={logo} className={classes.loginPageLogoImage} />
            </BoxFullWidth>

            <LoginForm />
          </BoxFullWidth>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

export default withStyles(privateLayoutStyle)(LoginPage)
