import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'
import WorkPin from 'style/asset/pin-work.svg'
import DestinationPin from 'style/asset/pin-destination.svg'
import GaskPin from 'style/asset/pin-gas.svg'
import ServicePin from 'style/asset/pin-servis.svg'
import TargetPin from 'style/asset/pin-target.svg'
import LocationPin from 'style/asset/pin-my-location.svg'

let allLocationsVisible = false
let gettingLocationFromMapGlobal = false
let compassMarker = null

const createCurrentLocation = (currentLocation) => {
  return {x: currentLocation.x, y: currentLocation.y, name: 'Vaše poloha', type: 'LOCATION'}
}

const createMarker = (loc) => {
  const coordinates = SMap.Coords.fromWGS84(loc.x, loc.y)

  let LocPin = WorkPin
  if (loc.type === 'DESTINATION') LocPin = DestinationPin
  if (loc.type === 'GAS_STATION') LocPin = GaskPin
  if (loc.type === 'SERVICE_STATION') LocPin = ServicePin
  if (loc.type === 'LOCATION') LocPin = LocationPin

  let znacka = JAK.mel('div')
  let obrazek
  if (loc.type === 'LOCATION') {
    znacka = JAK.mel('div', {}, {pointerEvents: 'none'})
    obrazek = JAK.mel('img', {src: LocPin}, {pointerEvents: 'none'})
  } else {
    znacka = JAK.mel('div')
    obrazek = JAK.mel('img', {src: LocPin}, {cursor: 'pointer'})
  }
  znacka.appendChild(obrazek)

  let marker = new SMap.Marker(coordinates, loc.id, {
    id: loc.id,
    title: loc.name,
    url: znacka,
  })
  return marker
} 

const addLocationsToLayer = (map, layer, locations, openLocationDetailFromMap) => {
  if (locations) {
    
    locations.forEach((loc) => {
      layer.addMarker(createMarker(loc))
    })

    map.getSignals().addListener(this, 'marker-click', function (e) {
      // vybrany marker
      let marker = e.target
      let id = marker.getId()
      let markers = layer.getMarkers()

      // zobrazime jeho jmeno - parovani vybraneho markeru pomoci jeho id a nasich vstupnich dat
      for (let i = 0; i < markers.length; i++) {
        if (markers[i]._id === id) {
          openLocationDetailFromMap(markers[i]._id)
        }
      }
    })

    if (allLocationsVisible === false) {
      // set center and zoom for all locations to be visible
      // but only at first load
      const locationsCoordinates = locations.map((loc) => SMap.Coords.fromWGS84(loc.x, loc.y))
      const centerZoom = map.computeCenterZoom(locationsCoordinates)
      map.setCenterZoom(centerZoom[0], centerZoom[1])
      // make sure to do this just once
      allLocationsVisible = true
    }
  }
}

const addTargetMarkerToLayer = (map, layer, getLocationFromMap) => {
  let znacka = JAK.mel('div')
  let obrazek = JAK.mel('img', {src: TargetPin}, {cursor: 'pointer'})
  znacka.appendChild(obrazek)

  const center = map.getCenter()

  compassMarker = new SMap.Marker(center, 0, {
    title: 'target-pin',
    url: znacka,
  })
  compassMarker.decorate(SMap.Marker.Feature.Draggable)
  layer.addMarker(compassMarker)

  function start(e) {
    /* Začátek tažení */
    let node = e.target.getContainer()
    node[SMap.LAYER_MARKER].style.cursor = 'help'
  }

  function stop(e) {
    let node = e.target.getContainer()
    node[SMap.LAYER_MARKER].style.cursor = ''
    let coords = e.target.getCoords()

    getLocationFromMap(coords)
  }

  let signals = map.getSignals()
  signals.addListener(window, 'marker-drag-stop', stop)
  signals.addListener(window, 'marker-drag-start', start)
}

const addControlsToMap = (map) => {
  // reakce mapy na změnu velikosti okna
  map.addControl(new SMap.Control.Sync())

  // přepínač mapových podkladů
  const layerSwitch = new SMap.Control.Layer({
    width: 65,
    items: 3,
    page: 3,
  })
  layerSwitch.addDefaultLayer(SMap.DEF_BASE)
  layerSwitch.addDefaultLayer(SMap.DEF_OPHOTO)
  layerSwitch.addDefaultLayer(SMap.DEF_TURIST)

  // pozice okna ve kterem se vybira typ mapy
  layerSwitch.getContainer().querySelector('.layer-switch .window').style.top = 'unset'
  layerSwitch.getContainer().querySelector('.layer-switch .window').style.bottom = '30px'

  map.addControl(layerSwitch, {bottom: '20px', left: '20px'})

  // kontrola pomocí myši
  const mouse = new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_WHEEL | SMap.MOUSE_ZOOM)
  map.addControl(mouse)

  // tlačítka zoom in/zoom out
  const control = new SMap.Control.Zoom({}, {showZoomMenu: false})
  map.addControl(control, {bottom: '48px', left: '150px'})

  // měřítko se 4 dílky
  const scale = new SMap.Control.Scale(4)
  map.addControl(scale, {bottom: '20px', left: '230px'})
}

const Map = (props) => {
  const {
    classes,
    mapCenter,
    currentLocation,
    locations,
    gettingLocationFromMap,
    getLocationFromMap,
    openLocationDetailFromMap,
    activePage,
  } = props

  const [mapLoaderLoaded, setMapLoaderLoaded] = useState(false)
  const [map, setMap] = useState(null)
  const [markerLayer, setMarkerLayer] = useState(null)
  const [targetMarkerCreated, setTargetMarkerCreated] = useState(false)

  const defaultZoom = 12
  const specificZoom = 16

  const isMapLoaderLoaded = () => mapLoaderLoaded && typeof SMap !== 'undefined'

  useEffect(() => {
    // Pokud ještě nebyl script element vložen
    if (!document.getElementById('map-loader-script')) {
      // Vytvoříme script element s odkazem na loader
      const script = document.createElement('script')
      script.src = 'https://api.mapy.cz/loader.js'
      script.id = 'map-loader-script'

      // Po načtení scriptu spustíme loader
      script.onload = () => {
        Loader.async = true
        Loader.load(null, null, () => {
          setMapLoaderLoaded(true)
        })
      }
      // Script element přidáme do hlavičky
      document.head.append(script)
    } else {
      // Loader již byl vložen dříve
      setMapLoaderLoaded(true)
    }
  }, [activePage])

  useEffect(() => {
    if (activePage === 'map') {
      const center = mapCenter
      // Pokud je Loader načtený, vytvoříme mapu
      if (isMapLoaderLoaded()) {
        const mapCreated = new SMap(
          JAK.gel('map'), // ID elementu, kam se mapa připojí
          SMap.Coords.fromWGS84(center.x, center.y),
          defaultZoom
        )
        // vrstvy - mapové podklady
        mapCreated.addDefaultLayer(SMap.DEF_TURIST)
        mapCreated.addDefaultLayer(SMap.DEF_OPHOTO)
        mapCreated.addDefaultLayer(SMap.DEF_BASE).enable()

        // vrsta se značkami
        let markerLayerCreated = new SMap.Layer.Marker()
        mapCreated.addLayer(markerLayerCreated)
        markerLayerCreated.enable()
        addLocationsToLayer(mapCreated, markerLayerCreated, locations, openLocationDetailFromMap)
        setMarkerLayer(markerLayerCreated)

        // ovládací prvky
        addControlsToMap(mapCreated)

        // signály
        mapCreated.getSignals().addListener(window, "map-click", (e) => {
          if (e.type === 'map-click') {
            const coords = SMap.Coords.fromEvent(e.data.event, mapCreated)
            console.log({coords, gettingLocationFromMap, gettingLocationFromMapGlobal})
            if (gettingLocationFromMapGlobal) {
              getLocationFromMap(coords)
              if (compassMarker) {
                compassMarker.setCoords(SMap.Coords.fromWGS84(coords.x, coords.y))
              }
            }
          }
        })

        setMap(mapCreated)
      }
    }
  }, [mapLoaderLoaded, activePage])

  // nastavit mapCenter podle props
  useEffect(() => {
    if (isMapLoaderLoaded() && map && mapCenter) {
      map.setCenter(SMap.Coords.fromWGS84(mapCenter.x, mapCenter.y))
      map.setZoom(specificZoom)
    }
  }, [mapCenter, activePage])

  useEffect(() => {
    gettingLocationFromMapGlobal = gettingLocationFromMap
    // vrstva se značkami
    if (map && markerLayer) {
      if (!gettingLocationFromMap) {
        markerLayer.removeAll()
        setTargetMarkerCreated(false)
        addLocationsToLayer(map, markerLayer, locations, openLocationDetailFromMap)
      } else if (!targetMarkerCreated) {
        setTargetMarkerCreated(true)
        markerLayer.removeAll()
        addTargetMarkerToLayer(map, markerLayer, getLocationFromMap)
      }
      if (currentLocation) {
        markerLayer.addMarker(createMarker(createCurrentLocation(currentLocation)))
      }
    }
  }, [locations, currentLocation, gettingLocationFromMap, activePage])

  return (
    <>
      {isMapLoaderLoaded() ? (
        <div className={classes.mapContainer} id="map" />
      ) : (
        <div className={classes.mapContainer}>loading map...</div>
      )}
    </>
  )
}

Map.propTypes = {
  classes: PropTypes.object,
  mapCenter: PropTypes.object,
  currentLocation: PropTypes.object,
  locations: PropTypes.array,
  gettingLocationFromMap: PropTypes.bool,
  getLocationFromMap: PropTypes.func,
  openLocationDetailFromMap: PropTypes.func,
}

export default withStyles(mapLayoutPageStyle)(Map)
