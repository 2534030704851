import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import GridItem from 'component/material/GridItem'
import cx from 'classnames'

const EditFieldRow = (props) => {
  const {
    classes,
    className,
    borderSide,
    responsiveness = {xs: 12},
    children,
    borderBottom = true,
    leftEdge = true,
  } = props

  const classesObject = cx(
    borderBottom && classes.borderBottom,
    leftEdge && classes.leftEdge,
    classes.rightEdge,
    borderSide === 'right' && classes.borderRight,
    borderSide === 'left' && classes.borderLeft,
    classes.editFieldRow,
    className
  )

  return (
    <GridItem
      container
      {...responsiveness}
      className={classesObject}
      justify={'space-between'}
      alignItems={'center'}
    >
      {children}
    </GridItem>
  )
}

export default withStyles(locationDetailStyle)(EditFieldRow)
