import React, {useEffect, useState} from 'react'
import GridItem from 'component/material/GridItem'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import SelectContractField from 'component/editField/SelectContractField'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getContract} from 'redux/action/contractAction'
import shiftDetailStyle from 'component/MapLayoutPage/shiftDetail/shiftDetailStyle'
import ShiftDetailTabs from 'component/MapLayoutPage/shiftDetail/ShiftDetailTabs'
import CurrentShiftTab from 'component/MapLayoutPage/shiftDetail/CurrentShiftTab'
import PastShiftTab from 'component/MapLayoutPage/shiftDetail/PastShiftTab'
import StatisticTab from 'component/MapLayoutPage/shiftDetail/StatistickTab'

const ShiftDetail = (props) => {
  const {classes, onClose, contract, listLoading, locationDetail, getContract} = props
  const [selectedContract, setSelectedContract] = useState(undefined)

  const [selectedTab, setSelectedTab] = useState('current')

  const contracts = locationDetail.contracts;

  const handleTabClick = (tab) => (e) => {
    setSelectedTab(tab)
  }

  const handleContractChange = (e) => {
    setSelectedContract(e.target.value)
    getContract(locationDetail.id, e.target.value)
  }

  useEffect(() => {
    if (contract?.id) {
      setSelectedContract(contract)
    }
  }, [contract])

  return (
    <GridItem container xs={12} md={6} className={classes.shiftColumnHeight}>
      {/* FIRST ROW WITH CONTRACT SELECT AND CLOSE DIALOG ICON */}
      <SelectContractField
        loading={listLoading}
        onClose={onClose}
        options={contracts}
        value={selectedContract?.id}
        onChange={handleContractChange}
      />

      <ShiftDetailTabs selectedTab={selectedTab} handleTabClick={handleTabClick} />

      {selectedTab === 'current' ? (
        <CurrentShiftTab />
      ) : selectedTab === 'past' ? (
        <PastShiftTab />
      ) : (
        <StatisticTab />
      )}
    </GridItem>
  )
}

ShiftDetail.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
  getContract: PropTypes.func,
  contracts: PropTypes.array,
  contract: PropTypes.object,
  listLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getContract,
    },
    dispatch
  )
}

export default compose(
  withStyles(shiftDetailStyle),
  connect((store) => {
    return {
      // contracts: store.contracts.list,
      listLoading: store.contracts.listLoading,
      contract: store.contracts.detail,
    }
  }, mapDispatchToProps)
)(ShiftDetail)
