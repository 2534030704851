import React from 'react'
import BoxFullWidth from 'component/material/BoxFullWidth'
import moment from 'moment'
import {DAY_MONTH_FORMAT, DB_DATE_FORMAT} from 'helper/constants'
import Box from '@material-ui/core/Box'
import GridContainer from 'component/material/GridContainer'
import withStyles from '@material-ui/core/styles/withStyles'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'
import PropTypes from 'prop-types'
import {ReactComponent as Work} from 'style/asset/work.svg'
import {ReactComponent as Gas} from 'style/asset/gas.svg'
import {ReactComponent as Destination} from 'style/asset/destination.svg'
import {ReactComponent as Service} from 'style/asset/service.svg'
import GridItem from 'component/material/GridItem'

const SingleLocation = (props) => {
  const {location, classes, openLocationDetail} = props

  const getWorkTypeIcon = () => {
    if (location.type === 'WORK')
      return (
        <span className={classes.workTypeIcon}>
          <Work />
        </span>
      )
    if (location.type === 'GAS_STATION')
      return (
        <span className={classes.gasTypeIcon}>
          <Gas />
        </span>
      )
    if (location.type === 'DESTINATION')
      return (
        <span className={classes.destinationTypeIcon}>
          <Destination />
        </span>
      )
    if (location.type === 'SERVICE_STATION')
      return (
        <span className={classes.serviceTypeIcon}>
          <Service />
        </span>
      )
  }

  const renderDateOfActiveContract = () => {
    if (location.contracts?.length >= 1) {
      return (
        <span className={classes.singleLocationDate}>
          <span>
            {moment(location.contracts[0].start).format(DAY_MONTH_FORMAT)}
            &thinsp;&ndash;&thinsp;
            {moment(location.contracts[0].end).format(DAY_MONTH_FORMAT)}
          </span>
        </span>
      )
    }
  }

  return (
    <BoxFullWidth
      mb={3}
      className={classes.singleLocationContainer}
      onClick={openLocationDetail(location)}
    >
      <GridContainer alignItems={'center'}>
        <GridItem container xs={12} alignItems={'center'}>
          {getWorkTypeIcon()}
          <Box component="span" px={3}>
            <span className={classes.locationName}>{location.name}</span>
          </Box>
        </GridItem>
        <GridItem xs={12}>{location.contracts && renderDateOfActiveContract()}</GridItem>
      </GridContainer>
      <div className={classes.dashedBorder}></div>
    </BoxFullWidth>
  )
}

SingleLocation.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
  openLocationDetail: PropTypes.func,
}

export default withStyles(mapLayoutPageStyle)(SingleLocation)
