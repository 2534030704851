import {storageGet, storageSet} from 'helper/functions'
import {
  SHIFT_LIST_REQUEST,
  SHIFT_LIST_SUCCESS,
  SHIFT_DETAIL_REQUEST,
  SHIFT_DETAIL_SUCCESS,
  SHIFT_DETAIL_FAIL,
  SHIFT_LIST_FAIL,
  TRANSPORT_REQUEST,
  TRANSPORT_SUCCESS,
  TRANSPORT_FAIL,
  SHIFT_CREATE_REQUEST,
  SHIFT_CREATE_SUCCESS,
  SHIFT_CREATE_FAIL,
  SHIFT_UPDATE_REQUEST,
  SHIFT_UPDATE_SUCCESS,
  SHIFT_UPDATE_FAIL,
  TRANSPORT_CREATE_FAIL,
  TRANSPORT_DELETE_FAIL,
  OFFLINE_SHIFT_LIST_SUCCESS,
  TRANSPORT_EDIT_FAIL,
} from 'redux/actionType'

const initState = {
  shiftCreateLoading: false,
  shiftUpdateLoading: false,
  loading: false,
  listLoading: false,
  meta: {},
  list: [],
  detail: {},
  currentShift: {},
  pastShifts: [],
  offlineShiftsToSend: [],
}

const saveToStorage = (locationId, contractId, state) => {
  storageSet(`shifts-${locationId}-${contractId}`, state)
}

const shiftReducer = (state = initState, action) => {
  switch (action.type) {
    case SHIFT_LIST_REQUEST: {
      return {
        ...state,
        listLoading: true,
      }
    }
    case SHIFT_LIST_SUCCESS: {
      const newState = {
        ...state,
        listLoading: false,
        list: action.data.objects,
        currentShift: action.data.objects.find((shift) => shift.end === null) || {},
        // filter only shifts with end time and sort them by id, newest/biggest id is first
        pastShifts: action.data.objects
          .filter((shift) => shift.end !== null)
          .sort((a, b) => b.id - a.id),
        locationId: action.locationId,
        contractId: action.contractId,
      }
      saveToStorage(action.locationId, action.contractId, newState)
      return newState
    }
    case SHIFT_LIST_FAIL: {
      return {
        ...state,
        ...storageGet(`shifts-${action.locationId}-${action.contractId}`),
        offlineData: true,
        listLoading: false,
      }
    }
    case OFFLINE_SHIFT_LIST_SUCCESS: {
      const newState = {
        ...state,
        listLoading: false,
        list: action.data.objects,
        currentShift: action.data.objects.find((shift) => shift.end === null) || {},
        // filter only shifts with end time and sort them by id, newest/biggest id is first
        pastShifts: action.data.objects
          .filter((shift) => shift.end !== null)
          .sort((a, b) => b.id - a.id),
        locationId: action.locationId,
        contractId: action.contractId,
      }
      saveToStorage(action.locationId, action.contractId, newState)
      return newState
    }

    case SHIFT_DETAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case SHIFT_DETAIL_SUCCESS: {
      storageSet(
        `shifts-detail-${action.locationId}-${action.contractId}-${action.shiftId}`,
        action.data
      )
      return {
        ...state,
        loading: false,
        detail: action.data,
      }
    }
    case SHIFT_DETAIL_FAIL: {
      return {
        ...state,
        detail: storageGet(
          `shifts-detail-${action.locationId}-${action.contractId}-${action.shiftId}`
        ),
        loading: false,
      }
    }

    case TRANSPORT_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case TRANSPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case TRANSPORT_FAIL:
      return {
        ...state,
        loading: false,
      }
    case SHIFT_CREATE_REQUEST: {
      return {
        ...state,
        shiftCreateLoading: true,
      }
    }
    case SHIFT_CREATE_SUCCESS:
    case SHIFT_CREATE_FAIL: {
      return {
        ...state,
        shiftCreateLoading: false,
      }
    }
    case SHIFT_UPDATE_REQUEST: {
      return {
        ...state,
        shiftUpdateLoading: true,
      }
    }
    case SHIFT_UPDATE_SUCCESS:
    case SHIFT_UPDATE_FAIL: {
      return {
        ...state,
        shiftUpdateLoading: false,
      }
    }
    case TRANSPORT_FAIL:
      return {
        ...state,
        loading: false,
      }
    case TRANSPORT_CREATE_FAIL:
    case TRANSPORT_DELETE_FAIL:
    case TRANSPORT_EDIT_FAIL:
      return {
        ...state,
        ...storageGet(`shifts-${action.locationId}-${action.contractId}`),
        loading: false,
      }

    default: {
      return state
    }
  }
}

export default shiftReducer
