import React, {useEffect, useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {composeValidators, required} from 'helper/validations'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import cx from 'classnames'
import {ReactComponent as SmartPhoneFinger} from 'style/asset/smartphone-finger.svg'
import {ReactComponent as PhoneAction} from 'style/asset/phone-action.svg'
import {fireErrorToast, fireSuccessToast, handleMapError} from 'helper/functions'
import {bindActionCreators, compose} from 'redux'
import {updateLocation, getLocation} from 'redux/action/locationAction'
import {connect} from 'react-redux'
import BoxFullWidth from 'component/material/BoxFullWidth'

const EditCoordsForm = (props) => {
  const {
    classes,
    locationFromMapCoords,
    updateLocation,
    handleLocationFromMap,
    locationDetail,
    onClose,
    getLocation,
    coordinates,
  } = props

  const [selectedCoordinates, setSelectedCoordinates] = useState(null)

  const isValidCoordinates = (value) => {
    let valueToRegex = value
    valueToRegex = valueToRegex?.replaceAll(/n/gi, '')
    valueToRegex = valueToRegex?.replaceAll(/e/gi, '')

    const regex = new RegExp(
      /^([-+]?)([\d]{1,2})((\.)?(\d+)(,))(\s*)(([-+]?)([\d]{1,3})((\.)?(\d+))?)$/g
    )
    if (!regex.test(valueToRegex)) {
      return <Trans>Invalid coordinates format</Trans>
    }
    return undefined
  }

  const prepareCreateLocationData = (values) => {
    return {
      ...locationDetail,
      ...values,
    }
  }

  const handleEditCoords = (values) => {
    const data = prepareCreateLocationData(values)
    updateLocation(locationDetail?.id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Location edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Location edit failed.</Trans>)
      })
      .finally(() => {
        getLocation(locationDetail?.id)
        onClose()
      })
  }

  // simple HTML5 geolocation https://www.w3schools.com/html/html5_geolocation.asp
  const handleLocationFromDevice = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSetSelectedCoordinates, handleMapError, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
    } else {
      fireErrorToast(<Trans>Geolocation is not supported by this browser.</Trans>)
    }
  }

  const handleSetSelectedCoordinates = (position) => {
    setSelectedCoordinates(
      `${position.coords.latitude.toFixed(9)}, ${position.coords.longitude.toFixed(9)}`
    )
  }

  useEffect(() => {
    if (locationFromMapCoords?.x && locationFromMapCoords?.y) {
      // .toFixed(9) is rounding coords on 9 decimal places, 9 is enough to be precise
      setSelectedCoordinates(
        `${locationFromMapCoords.y.toFixed(9)}, ${locationFromMapCoords.x.toFixed(9)}`
      )
    }
  }, [locationFromMapCoords])

  return (
    <>
      <Box p={5}>
        <Form
          onSubmit={handleEditCoords}
          initialValues={{
            coordinates: !!selectedCoordinates ? selectedCoordinates : coordinates,
          }}
        >
          {(formProps) => {
            return (
              <form onSubmit={formProps.handleSubmit}>
                <GridContainer>
                  <GridItem
                    container
                    xs={12}
                    alignItems={'center'}
                    justify={'center'}
                    className={classes.fieldsPadding}
                  >
                    <GridItem xs={12} sm={6} className={classes.fieldsPaddingRight}>
                      <Field
                        name="coordinates"
                        variant="outlined"
                        label={<Trans>Coordinates</Trans>}
                        component={TextInput}
                        placeholder="50.01788, 15.20233"
                        validate={composeValidators(required, isValidCoordinates)}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                      <BoxFullWidth mt={1}>
                        <PrimaryButton
                          formSubmit={true}
                          size={'small'}
                          type="submit"
                          text={<Trans>Save</Trans>}
                        />
                      </BoxFullWidth>
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </form>
            )
          }}
        </Form>
      </Box>
      <GridItem xs={12} container direction={'row'}>
        <GridItem
          onClick={handleLocationFromMap}
          container
          xs={6}
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
          className={cx(classes.selectCoordsBox, classes.borderRightBox)}
        >
          <SmartPhoneFinger />
          <span>
            <Trans>Select from map</Trans>
          </span>
        </GridItem>

        <GridItem
          onClick={handleLocationFromDevice}
          container
          xs={6}
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
          className={classes.selectCoordsBox}
        >
          <PhoneAction />
          <span>
            <Trans>Device location</Trans>
          </span>
        </GridItem>
      </GridItem>
    </>
  )
}

EditCoordsForm.propTypes = {
  classes: PropTypes.object,
  locationFromMap: PropTypes.object,
  updateLocation: PropTypes.func,
  handleLocationFromMap: PropTypes.func,
  onClose: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocation,
      getLocation,
    },
    dispatch
  )
}

export default compose(
  withStyles(fieldStyle),
  connect((store) => {
    return {locationDetail: store.locations.detail}
  }, mapDispatchToProps)
)(EditCoordsForm)
