import {SET_ACTIVE_PAGE} from 'redux/actionType'

const initState = localStorage.getItem('sessionId') ? {page: 'map'} : {page: 'login'}

const activePageReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE: {
      return {
        ...state,
        page: action.data,
      }
    }
    default: {
      return state
    }
  }
}
export default activePageReducer
