import React from 'react'
import {ReactComponent as Archive} from 'style/asset/archive.svg'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import iconStyle from 'component/icon/iconStyle'
import cx from 'classnames'

const ArchiveIcon = (props) => {
  const {className, classes, ...rest} = props

  return <Archive {...rest} className={cx(className, classes.archiveIcon)} />
}

ArchiveIcon.propTypes = {
  className: PropTypes.object,
  classes: PropTypes.object,
  rest: PropTypes.object,
}

export default withStyles(iconStyle)(ArchiveIcon)
