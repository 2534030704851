import React from 'react'
import GridItem from 'component/material/GridItem'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import EditFieldDefaultValue from 'component/editField/EditFieldDefaultValue'
import EditFieldLabel from 'component/editField/EditFieldLabel'
import EditIconButton from 'component/editField/EditIconButton'
import cx from 'classnames'

const EditCoordinateField = (props) => {
  const {
    defaultValue,
    label,
    name,
    onEdit,
    enabled,
    responsiveness = {xs: 12, sm: 6},
    classes,
  } = props

  return (
    <GridItem
      container
      {...responsiveness}
      className={cx(classes.borderBottom, classes.borderRight, classes.leftEdge)}
      alignItems={'center'}
    >
      <GridItem xs={true}>
        <EditFieldDefaultValue value={defaultValue} />
        <EditFieldLabel value={label} />
      </GridItem>
      <GridItem>{enabled && <EditIconButton onEdit={onEdit(name)} />}</GridItem>
    </GridItem>
  )
}

EditCoordinateField.propTypes = {
  classes: PropTypes.object,
  responsiveness: PropTypes.object,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
}

EditCoordinateField.defaultProps = {
  enabled: true,
}

export default withStyles(locationDetailStyle)(EditCoordinateField)
