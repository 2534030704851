import React from 'react'
import {KeyboardDatePicker} from '@material-ui/pickers'
import fieldStyle from 'component/field/fieldStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {DATE_FORMAT} from 'helper/constants'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import moment from 'moment'
import {ReactComponent as CalendarIcon} from 'style/asset/calendar.svg'

// KeyboardDatePicker API https://material-ui-pickers.dev/api/KeyboardDatePicker
function DatePickerInput(props) {
  const {
    classes,
    helperText,
    input,
    meta,
    label,
    variant = 'outlined',
    showHelperText = true,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && meta.error
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <div className={classes.datePickerInput}>
      <KeyboardDatePicker
        inputVariant={variant}
        autoOk={true}
        format={DATE_FORMAT}
        label={label}
        {...input}
        {...rest}
        value={input.value ? input.value : null}
        error={!!errorMessage}
        helperText={errorHelperText}
        invalidDateMessage={<Trans>Invalid Date Format</Trans>}
        maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
        minDateMessage={<Trans>Date should not be before minimal date</Trans>}
        cancelLabel={<Trans>Close</Trans>}
        okLabel={<Trans>Ok</Trans>}
        placeholder={moment().format(DATE_FORMAT)}
        keyboardIcon={<CalendarIcon className={classes.calendarIcon} />}
      />
    </div>
  )
}

DatePickerInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  variant: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  invalidDateMessage: PropTypes.node,
  maxDateMessage: PropTypes.node,
  minDateMessage: PropTypes.node,
  cancelLabel: PropTypes.node,
  okLabel: PropTypes.node,
}

export default withStyles(fieldStyle)(DatePickerInput)
