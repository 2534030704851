import React from 'react'
import {ReactComponent as Menu} from 'style/asset/menu-vertical.svg'
import PropTypes from 'prop-types'
import cx from 'classnames'
import iconStyle from 'component/icon/iconStyle'
import withStyles from '@material-ui/core/styles/withStyles'

const DotsIcon = (props) => {
  const {classes, className, ...rest} = props

  return <Menu {...rest} className={cx(className)} />
}

DotsIcon.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  rest: PropTypes.object,
}

export default withStyles(iconStyle)(DotsIcon)
