import React from 'react'
import {
  OFFLINE_ACTION_POP,
  SHIFT_CREATE_FAIL,
  SHIFT_UPDATE_FAIL,
  TRANSPORT_CREATE_FAIL,
  TRANSPORT_DELETE_FAIL,
  TRANSPORT_EDIT_FAIL,
} from 'redux/actionType'
import {getOfflineLocations} from './locationAction'
import {
  createShift,
  createTransport,
  deleteTransport,
  editTransport,
  updateShift,
} from './shiftAction'
import {getUsers} from './userAction'

export const popOfflineAction = () => {
  return (dispatch) => {
    return dispatch({type: OFFLINE_ACTION_POP})
  }
}

function checkingForOfflineActionsStarted() {
  if (!checkingForOfflineActionsStarted.checkingStarted) {
    checkingForOfflineActionsStarted.checkingStarted = true
    return false
  }
  return true
}

export const startCheckingOfflineActions = () => {
  return (dispatch, getState) => {
    if (!checkingForOfflineActionsStarted()) {
      console.log('startCheckingOfflineActions NOW')
      checkActionsToSend()(dispatch, getState)
      getOfflineLocations()(dispatch)
      getUsers()(dispatch)
      setInterval(() => getOfflineLocations()(dispatch), 5 * 60 * 1000)
    }
  }
}

const checkActionsToSend = () => {
  return (dispatch, getState) => {
    console.log('...checkActionsToSend in progress')
    const actions = getState().offlineActions.actions
    console.log('Pocet akci k odeslani: ' + actions.length)
    if (actions.length >= 1) {
      popOfflineAction()(dispatch)
      sendOfflineAction(actions[0])(dispatch)
        .then(() => checkActionsToSend()(dispatch, getState))
        .catch((err) => {
          if (err.message === 'Network Error') {
            setTimeout(() => checkActionsToSend()(dispatch, getState), 15000)
          } else {
            console.log(err)
            checkActionsToSend()(dispatch, getState)
          }
        })
    } else {
      setTimeout(() => checkActionsToSend()(dispatch, getState), 15000)
    }
  }
}

const sendOfflineAction = (action) => {
  return (dispatch) => {
    console.log('sendOfflineAction: ' + action.type + '/' + action.shiftId)
    switch (action.type) {
      case SHIFT_CREATE_FAIL:
        return createShift(action.locationId, action.contractId, action.data, true)(dispatch)
      case SHIFT_UPDATE_FAIL:
        return updateShift(
          action.locationId,
          action.contractId,
          action.shiftId,
          action.data,
          true
        )(dispatch)
      case TRANSPORT_CREATE_FAIL:
        return createTransport(
          action.locationId,
          action.contractId,
          action.shiftId,
          action.data,
          true
        )(dispatch)
      case TRANSPORT_DELETE_FAIL:
        if (action.transportId !== undefined) {
          console.log({action})
          return deleteTransport(
            action.locationId,
            action.contractId,
            action.shiftId,
            action.transportId,
            action.data?.transportOfflineId || null,
            !!action.data?.transportOfflineId
          )(dispatch)
        }
      case TRANSPORT_EDIT_FAIL:
        console.log({action})
        return editTransport(
          action.locationId,
          action.contractId,
          action.shiftId,
          action.data,
          true
        )(dispatch)
    }
  }
}
