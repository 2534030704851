import React, {useEffect, useState} from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {TextField, Typography} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import machinesPageStyle from 'component/MachinePage/machinesPageStyle'
import IconButton from 'component/material/IconButton'
import EditIcon from 'component/icon/EditIcon'
import RestoreIcon from 'component/icon/RestoreIcon'
import ArchiveIcon from 'component/icon/ArchiveIcon'
import SortIcon from 'component/icon/SortIcon'
import Box from '@material-ui/core/Box'
import {bindActionCreators, compose} from 'redux'
import {getMachines, updateMachine} from 'redux/action/machineAction'
import {connect} from 'react-redux'
import {fireErrorToast, fireSuccessToast} from 'helper/functions'
import CloseIcon from 'component/icon/CloseIcon'
import WarningDialog from 'component/material/WarningDialog'
import cx from 'classnames'
import Loader from 'component/material/Loader'
import useWindowSize from 'helper/useWindowSize'

const MachineTable = (props) => {
  const {
    classes,
    machineList,
    machinesLoading,
    handleTableSort,
    tableSort,
    updateMachine,
    getMachines,
    onlyArchivedFilter,
    handleOnlyArchived,
  } = props

  const [archiveDialog, setOpenArchiveDialog] = useState({visible: false, machine: ''})
  const [editRow, setEditRow] = useState(null)
  const {isMediaMobile, isMediaScreen} = useWindowSize()

  const handleChange = (e) => {
    setEditRow((prevState) => ({
      ...prevState,
      machine: {...prevState.machine, [e.target.name]: e.target.value},
    }))
  }

  const handleEditRow = (machine, index) => (e) => {
    setEditRow({machine: machine, index: index})
  }
  const handleEditCancel = () => {
    setEditRow(null)
  }
  const handleEditMachine = () => {
    updateMachine(editRow.machine?.id, editRow.machine)
      .then((res) => {
        fireSuccessToast(<Trans>Machine edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Machine edit failed.</Trans>)
      })
      .finally(() => {
        getMachines(onlyArchivedFilter, tableSort)
        handleEditCancel()
      })
  }

  const handleOpenArchiveDialog = (machine) => (e) => {
    setOpenArchiveDialog({visible: true, machine: machine})
  }
  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog({visible: false, machine: ''})
  }
  const handleArchiveMachine = () => {
    updateMachine(archiveDialog.machine?.id, {
      ...archiveDialog.machine,
      is_archived: !archiveDialog.machine.is_archived,
    })
      .then(() => {
        fireSuccessToast(<Trans>Machine edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Machine edit failed.</Trans>)
      })
      .finally(() => {
        getMachines(onlyArchivedFilter, tableSort)
        handleCloseArchiveDialog()
      })
  }

  const getSortClass = (orderBy) => {
    if (tableSort.orderBy === orderBy) {
      if (tableSort.orderDirection === 'ASC') {
        return classes.sortIconAsc
      } else {
        return classes.sortIconDesc
      }
    }
  }

  return (
    <>
      {/* CONFIRM ARCHIVE MACHINE */}
      <WarningDialog
        title={
          archiveDialog.machine?.is_archived ? (
            <Trans>Restore machine from archive</Trans>
          ) : (
            <Trans>Archive machine</Trans>
          )
        }
        message={archiveDialog.machine?.name}
        open={archiveDialog.visible}
        onClose={handleCloseArchiveDialog}
        onConfirm={handleArchiveMachine}
      />

      <GridContainer alignItems={'center'} className={classes.tableHead}>
        <GridItem container xs={4} alignItems={'center'}>
          <span onClick={handleTableSort('name')} className={classes.sortIconBox}>
            <Typography color={'primary'} variant={'body1'} className={classes.tableHeadFilter}>
              {isMediaScreen ? <Trans>Machine name</Trans> : <Trans>Name</Trans>}
            </Typography>
            <Box pl={3} className={cx(classes.sortIconBox, getSortClass('name'))}>
              <SortIcon />
            </Box>
          </span>
        </GridItem>

        <GridItem xs={3}>
          <Typography color={'primary'} variant={'body1'} className={classes.tableHeadFilter}>
            <Trans>SPZ</Trans>
          </Typography>
        </GridItem>

        <GridItem xs={2}>
          <Typography color={'primary'} variant={'body1'} className={classes.tableHeadFilter}>
            <Trans>State</Trans>
          </Typography>
        </GridItem>

        {isMediaScreen && (
          <GridItem container xs={3} justify={'flex-end'}>
            <FormControlLabel
              control={
                <Checkbox
                  color={'primary'}
                  checked={onlyArchivedFilter}
                  onChange={handleOnlyArchived}
                  name={'is_archived'}
                />
              }
              label={
                <Typography color={'primary'} variant={'body1'}>
                  {<Trans>Only non-active</Trans>}
                </Typography>
              }
            />
          </GridItem>
        )}
      </GridContainer>

      {machinesLoading ? (
        <GridContainer className={classes.tableRow} alignItems={'center'}>
          <Loader className={classes.tableRowLoading} />
        </GridContainer>
      ) : machineList?.length === 0 ? (
        <GridContainer className={classes.tableRow} alignItems={'center'} justify={'center'}>
          <Typography color={'secondary'} variant={'h6'}>
            <Trans>No machines</Trans>
          </Typography>
        </GridContainer>
      ) : (
        machineList?.map((machine, index) => (
          <GridContainer key={index} className={classes.tableRow} alignItems={'center'}>
            <GridItem xs={4} className={classes.tableRowName}>
              {editRow?.index === index ? (
                <TextField
                  fullWidth
                  value={editRow.machine?.name}
                  name={'name'}
                  onChange={handleChange}
                  className={classes.tableInput}
                />
              ) : (
                machine.name
              )}
            </GridItem>
            <GridItem xs={3} className={classes.tableRowText}>
              {editRow?.index === index ? (
                <TextField
                  fullWidth
                  value={editRow.machine?.spz}
                  name={'spz'}
                  onChange={handleChange}
                  className={classes.tableInput}
                />
              ) : (
                machine.spz
              )}
            </GridItem>
            <GridItem xs={2}>
              {machine.is_archived ? (
                <span className={classes.tableNonActive}>
                  <Trans>Non-active</Trans>
                </span>
              ) : (
                <span className={classes.tableActive}>
                  <Trans>Active</Trans>
                </span>
              )}
            </GridItem>
            <GridItem container xs={3} justify={'flex-end'} className={classes.tableRowActions}>
              {editRow?.index === index ? (
                <>
                  <IconButton className={classes.machineListIcon} icon={<RestoreIcon />} onClick={handleEditMachine} />
                  <IconButton className={classes.machineListIcon} icon={<CloseIcon />} onClick={handleEditCancel} />
                </>
              ) : (
                <>
                  <IconButton className={classes.machineListIcon} icon={<EditIcon />} onClick={handleEditRow(machine, index)} />
                  {machine.is_archived ? (
                    <IconButton className={classes.machineListIcon} con={<RestoreIcon />} onClick={handleOpenArchiveDialog(machine)} />
                  ) : (
                    <IconButton className={classes.machineListIcon} icon={<ArchiveIcon />} onClick={handleOpenArchiveDialog(machine)} />
                  )}
                </>
              )}
            </GridItem>
          </GridContainer>
        ))
      )}
    </>
  )
}

MachineTable.propTypes = {
  classes: PropTypes.object,
  tableSort: PropTypes.object,
  machineList: PropTypes.array,
  updateMachine: PropTypes.func,
  handleOnlyArchived: PropTypes.func,
  handleTableSort: PropTypes.func,
  onlyArchivedFilter: PropTypes.bool,
  machinesLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateMachine,
      getMachines,
    },
    dispatch
  )
}

export default compose(
  withStyles(machinesPageStyle),
  connect((store) => {
    return {
      machineList: store.machines.list,
      machinesLoading: store.machines.loading,
    }
  }, mapDispatchToProps)
)(MachineTable)
