import React from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {required} from 'helper/validations'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import SelectInput from 'component/field/SelectInput'
import {USER_ROLES} from 'helper/constants'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'

const CreateUserForm = (props) => {
  const {classes, handleSubmit} = props

  return (
    <Box p={5}>
      <Form onSubmit={handleSubmit}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={6} className={classes.smallGridPadding}>
                  <Field
                    name="name"
                    label={<Trans>Name and surname</Trans>}
                    component={TextInput}
                    validate={required}
                    placeholder={'Josef Novák'}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} className={classes.smallGridPadding}>
                  <div className={classes.fieldLabel}>{<Trans>Role</Trans>}</div>
                  <Field
                    name="role"
                    component={SelectInput}
                    options={USER_ROLES}
                    validate={required}
                    defaultValue={'OPERATOR'}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="email"
                    label={<Trans>E-mail</Trans>}
                    component={TextInput}
                    validate={required}
                    placeholder={'josef.novak@seznam.cz'}
                  />
                </GridItem>

                <GridItem container justify={'flex-end'}>
                  <PrimaryButton
                    formSubmit={true}
                    textPadding={1}
                    size={'large'}
                    type="submit"
                    fullWidth={false}
                    text={<Trans>Create</Trans>}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </Box>
  )
}

CreateUserForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(fieldStyle)(CreateUserForm)
