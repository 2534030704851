import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormHelperText from '@material-ui/core/FormHelperText'
import {ReactComponent as SelectIcon} from 'style/asset/arrow-down.svg'
import cx from 'classnames'

const SelectInput = (props) => {
  const {
    classes,
    disabled,
    label,
    name,
    input,
    value,
    onChange,
    onBlur,
    meta,
    options,
    variant = 'outlined',
    helperText,
    showHelperText = true,
    customError,
    loading,
    className,
    placeholder,
    emptyOption = false,
    ...rest
  } = props

  const errorMessage = customError || (meta && meta.touched && meta.error)
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <FormControl
      error={!!errorMessage}
      variant={variant}
      className={cx(classes.formControl, classes.fieldShadow)}
    >
      {label && <InputLabel id="simple-select-outlined-label">{label}</InputLabel>}
      <Select
        {...input}
        {...rest}
        value={input ? input.value : value}
        onChange={input ? input.onChange : onChange}
        onBlur={() => {
          onBlur && onBlur(input?.value, input?.name)
          input?.onBlur()
        }}
        className={cx(classes.selectInput, className)}
        name={name}
        label={label}
        disabled={disabled}
        IconComponent={() =>
          !loading && (
            <span className={classes.selectIcon}>
              <SelectIcon />
            </span>
          )
        }
        endAdornment={
          loading && (
            <InputAdornment position="end" className={classes.loadingEndAdornment}>
              <CircularProgress color="secondary" />
            </InputAdornment>
          )
        }
      >
        {emptyOption && (
          <MenuItem key={'empty'} value={null} classes={{root: classes.emptyOption}}>
            {'----'}
          </MenuItem>
        )}
        {!loading && options.length !== 0 ? (
          options.map((option, index) => (
            // if option is object with value and label, use that
            // otherwise use option as string
            <MenuItem key={index} value={option.value ? option.value : option.id}>
              {option.label ? option.label : option.name ? option.name : option}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={'empty'} value={null} classes={{root: classes.emptyOption}}>
            <Trans>No options</Trans>
          </MenuItem>
        )}
      </Select>
      {showHelperText && (
        <FormHelperText className={classes.formHelperText}>{errorHelperText}</FormHelperText>
      )}
    </FormControl>
  )
}

SelectInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  name: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export default withStyles(fieldStyle)(SelectInput)
