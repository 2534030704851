import React from 'react'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  SHIFT_LIST_REQUEST,
  SHIFT_LIST_SUCCESS,
  SHIFT_DETAIL_REQUEST,
  SHIFT_DETAIL_SUCCESS,
  SHIFT_CREATE_REQUEST,
  SHIFT_CREATE_SUCCESS,
  SHIFT_UPDATE_REQUEST,
  SHIFT_UPDATE_SUCCESS,
  SHIFT_UPDATE_FAIL,
  SHIFT_DETAIL_FAIL,
  SHIFT_LIST_FAIL,
  SHIFT_CREATE_FAIL,
  TRANSPORT_REQUEST,
  TRANSPORT_SUCCESS,
  TRANSPORT_FAIL,
  TRANSPORT_CREATE_FAIL,
  TRANSPORT_DELETE_FAIL,
  OFFLINE_FIX_SHIFT_ID,
  OFFLINE_SHIFT_LIST_SUCCESS,
  TRANSPORT_DELETE_OFFLINE_ACTION,
  TRANSPORT_EDIT_FAIL,
} from 'redux/actionType'
import {
  fireWarningOffline,
  fireWarningToast,
  globalApiErrorHandler,
  storageGet,
  storageSet,
} from 'helper/functions'
import {Trans} from '@lingui/macro'

export const getShifts = (locationId, contractId) => {
  return (dispatch) => {
    dispatch({type: SHIFT_LIST_REQUEST})
    return httpClient
      .get(`${endpoints.locations}/${locationId}/contracts/${contractId}/shifts`)
      .then((res) => {
        dispatch({type: SHIFT_LIST_SUCCESS, data: res.data, locationId, contractId})
        return res.data.objects
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: SHIFT_LIST_FAIL, locationId, contractId})
          fireWarningOffline()
        } else {
          globalApiErrorHandler(err, dispatch)
          return Promise.reject()
        }
      })
  }
}

export const getOfflineShifts = (locationId, contractId) => {
  return (dispatch) => {
    return httpClient
      .get(`${endpoints.locations}/${locationId}/contracts/${contractId}/shifts`)
      .then((res) => {
        dispatch({type: OFFLINE_SHIFT_LIST_SUCCESS, data: res.data, locationId, contractId})
      })
      .catch(() => {})
  }
}

export const getShiftsFromStorage = (locationId, contractId) => {
  return (dispatch) => {
    dispatch({type: SHIFT_LIST_FAIL, locationId, contractId})
  }
}

export const getShift = (locationId, contractId, shiftId) => {
  return (dispatch) => {
    dispatch({type: SHIFT_DETAIL_REQUEST})
    return httpClient
      .get(`${endpoints.locations}/${locationId}/contracts/${contractId}/shifts/${shiftId}`)
      .then((res) => {
        dispatch({type: SHIFT_DETAIL_SUCCESS, data: res.data, locationId, contractId, shiftId})
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: SHIFT_DETAIL_FAIL, locationId, contractId, shiftId})
        } else {
          globalApiErrorHandler(err, dispatch)
          return Promise.reject()
        }
      })
  }
}

export const createShift = (locationId, contractId, data, isRepeated = false) => {
  return (dispatch) => {
    dispatch({type: SHIFT_CREATE_REQUEST})
    return httpClient
      .post(`${endpoints.locations}/${locationId}/contracts/${contractId}/shifts`, data)
      .then((res) => {
        if (data.isOffline) {
          console.log(
            ':: Dispatching offline fix, shiftId = ' +
              res.data.id +
              ', offlineId = ' +
              data.offlineId
          )
          dispatch({type: OFFLINE_FIX_SHIFT_ID, offlineId: data.offlineId, shiftId: res.data.id})
        }
        dispatch({type: SHIFT_CREATE_SUCCESS, data: res.data})
        getShifts(locationId, contractId)(dispatch)
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          if (!isRepeated) {
            data.isOffline = true
            data.offlineId = Math.random().toString(36).substring(2, 15)
            // return generated offlineId for the shift, which will be saved to consecutive transports
            err.offlineId = data.offlineId
            console.log('Generated offline id for shift: ' + data.offlineId)
          }
          dispatch({type: SHIFT_CREATE_FAIL, locationId, contractId, data, isRepeated})
        } else {
          globalApiErrorHandler(err, dispatch)
        }
        return Promise.reject(err)
      })
  }
}

export const createShiftInStorage = (locationId, contractId, data) => {
  const shiftData = storageGet(`shifts-${locationId}-${contractId}`)
  if (shiftData) {
    shiftData.currentShift = data
    console.log('updateShiftInStorage')
    console.log(data)
    storageSet(`shifts-${locationId}-${contractId}`, shiftData)
  }
}

export const updateShift = (locationId, contractId, shiftId, data, isRepeated = false) => {
  return (dispatch) => {
    if (data.isOffline) {
      dispatch({type: SHIFT_UPDATE_FAIL, locationId, contractId, shiftId: null, data})
      return Promise.reject({message: 'Network Error'})
    }
    dispatch({type: SHIFT_UPDATE_REQUEST})
    return httpClient
      .put(`${endpoints.locations}/${locationId}/contracts/${contractId}/shifts/${shiftId}`, data)
      .then((res) => {
        dispatch({type: SHIFT_UPDATE_SUCCESS})
        getShifts(locationId, contractId)(dispatch)
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: SHIFT_UPDATE_FAIL, locationId, contractId, shiftId, data, isRepeated})
        } else {
          globalApiErrorHandler(err, dispatch)
        }
        return Promise.reject(err)
      })
  }
}

export const updateShiftInStorage = (locationId, contractId, shiftId, data) => {
  const shiftData = storageGet(`shifts-${locationId}-${contractId}`)
  if (shiftData) {
    shiftData.currentShift = {}
    // shiftData.pastShifts.unshift(data)
    console.log('updateShiftInStorage')
    console.log(data)
    storageSet(`shifts-${locationId}-${contractId}`, shiftData)
  }
}

export const createTransport = (locationId, contractId, shiftId, data, isRepeated = false) => {
  return (dispatch) => {
    if (data.isOffline) {
      if (!isRepeated) {
        const transportOfflineId = Math.random().toString(36).substring(2, 15)
        data.transportOfflineId = transportOfflineId
      }
      dispatch({type: TRANSPORT_CREATE_FAIL, locationId, contractId, shiftId: null, data})
      return Promise.reject({message: 'Network Error', transportOfflineId: data.transportOfflineId})
    }
    dispatch({type: TRANSPORT_REQUEST})
    return httpClient
      .post(
        `${endpoints.locations}/${locationId}/contracts/${contractId}/shifts/${shiftId}/transports`,
        data
      )
      .then((res) => {
        dispatch({type: TRANSPORT_SUCCESS, data: res.data})
        getShifts(locationId, contractId)(dispatch)
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          if (!isRepeated) {
            const transportOfflineId = Math.random().toString(36).substring(2, 15)
            data.transportOfflineId = transportOfflineId
          }
          dispatch({
            type: TRANSPORT_CREATE_FAIL,
            locationId,
            contractId,
            shiftId,
            data,
            isRepeated,
          })
          err.transportOfflineId = data.transportOfflineId
        } else {
          dispatch({type: TRANSPORT_FAIL})
          globalApiErrorHandler(err, dispatch)
        }
        return Promise.reject(err)
      })
  }
}

export const createTransportInStorage = (locationId, contractId, data) => {
  const shiftData = storageGet(`shifts-${locationId}-${contractId}`)
  console.log('create transport in storage' + locationId + ', ' + contractId)
  if (shiftData?.currentShift) {
    if (!shiftData.currentShift.transports) {
      console.log('create empty transports array')
      shiftData.currentShift.transports = []
    }
    shiftData.currentShift.transports.push(data)
    storageSet(`shifts-${locationId}-${contractId}`, shiftData)
  }
}

export const deleteTransport = (
  locationId,
  contractId,
  shiftId,
  transportId,
  transportOfflineId = null,
  isRepeated = false
) => {
  return (dispatch) => {
    console.log({locationId, contractId, shiftId, transportId, transportOfflineId})
    if (transportId === undefined) {
      dispatch({type: TRANSPORT_DELETE_OFFLINE_ACTION, locationId, contractId, transportOfflineId})
      return Promise.reject({message: 'Network Error'})
    }
    dispatch({type: TRANSPORT_REQUEST})
    return httpClient
      .del(
        `${endpoints.locations}/${locationId}/contracts/${contractId}/shifts/${shiftId}/transports/${transportId}`
      )
      .then((res) => {
        dispatch({type: TRANSPORT_SUCCESS})
        getShifts(locationId, contractId)(dispatch)
      })
      .catch((err) => {
        if (!isRepeated) {
          if (err.message === 'Network Error') {
            dispatch({
              type: TRANSPORT_DELETE_FAIL,
              locationId,
              contractId,
              shiftId,
              transportId,
              transportOfflineId,
              isRepeated,
            })
          } else {
            dispatch({type: TRANSPORT_FAIL})
            globalApiErrorHandler(err, dispatch)
          }
        }
        return Promise.reject(err)
      })
  }
}

export const deleteTransportInStorage = (
  locationId,
  contractId,
  shiftId,
  transportId,
  transportOfflineId
) => {
  const shiftData = storageGet(`shifts-${locationId}-${contractId}`)
  if (shiftData?.currentShift?.transports) {
    const transportIndex = shiftData.currentShift.transports.findIndex(
      (transport) =>
        (transportId && transport.id === transportId) ||
        (transportOfflineId && transport.transportOfflineId === transportOfflineId)
    )
    if (transportIndex >= 0) {
      shiftData.currentShift.transports.splice(transportIndex, 1)
      storageSet(`shifts-${locationId}-${contractId}`, shiftData)
    }
  }
}

export const deleteTransportInStoragePastShift = (
  locationId,
  contractId,
  shiftId,
  transportId,
  transportOfflineId
) => {
  const shiftData = storageGet(`shifts-${locationId}-${contractId}`)
  const shiftIndex = shiftData?.list?.findIndex((shift) => shift.id === shiftId)
  console.log({
    name: 'deleteTransportInStoragePastShift',
    shiftId,
    transportId,
    transportOfflineId,
    shiftIndex,
  })
  if (shiftData?.list?.[shiftIndex]) {
    const transportIndex = shiftData.list[shiftIndex].transports.findIndex(
      (transport) =>
        (transportId && transport.id === transportId) ||
        (transportOfflineId && transport.transportOfflineId === transportOfflineId)
    )
    console.log({transportIndex})
    if (transportIndex >= 0) {
      shiftData.list[shiftIndex].transports.splice(transportIndex, 1)
    }
  }
  const pastShiftIndex = shiftData?.pastShifts?.findIndex((shift) => shift.id === shiftId)
  console.log({
    name: 'deleteTransportInStoragePastShift',
    shiftId,
    transportId,
    transportOfflineId,
    pastShiftIndex,
  })
  if (shiftData?.pastShifts?.[pastShiftIndex]) {
    const transportIndex = shiftData.pastShifts[pastShiftIndex].transports.findIndex(
      (transport) =>
        (transportId && transport.id === transportId) ||
        (transportOfflineId && transport.transportOfflineId === transportOfflineId)
    )
    console.log({transportIndex})
    if (transportIndex >= 0) {
      shiftData.pastShifts[pastShiftIndex].transports.splice(transportIndex, 1)
    }
  }
  storageSet(`shifts-${locationId}-${contractId}`, shiftData)
}

export const editTransport = (locationId, contractId, shiftId, data, isRepeated = false) => {
  return (dispatch) => {
    if (data.isOffline) {
      dispatch({type: TRANSPORT_EDIT_FAIL, locationId, contractId, shiftId: null, data})
      return Promise.reject({message: 'Network Error'})
    }
    dispatch({type: TRANSPORT_REQUEST})
    return httpClient
      .put(
        `${endpoints.locations}/${locationId}/contracts/${contractId}/shifts/${shiftId}/transports/${data.id}`,
        data
      )
      .then((res) => {
        dispatch({type: TRANSPORT_SUCCESS, data: res.data})
        getShifts(locationId, contractId)(dispatch)
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({
            type: TRANSPORT_EDIT_FAIL,
            locationId,
            contractId,
            shiftId,
            data,
            isRepeated,
          })
        } else {
          dispatch({type: TRANSPORT_FAIL})
          globalApiErrorHandler(err, dispatch)
        }
        return Promise.reject(err)
      })
  }
}

export const editTransportInStorage = (locationId, contractId, data) => {
  console.log('editTransportInStorage', {locationId, contractId, data})
  const shiftData = storageGet(`shifts-${locationId}-${contractId}`)
  console.log('edit transport ' + locationId + ', ' + contractId)
  if (shiftData?.currentShift?.transports) {
    const index = shiftData.currentShift.transports.findIndex(
      (item) =>
        (data.id && data.id === item.id) ||
        (data.transportOfflineId && data.transportOfflineId === item.transportOfflineId)
    )
    shiftData.currentShift.transports[index] = data
    storageSet(`shifts-${locationId}-${contractId}`, shiftData)
  }
}

export const editTransportInStoragePastShift = (
  locationId,
  contractId,
  shiftId,
  transportId,
  data
) => {
  const shiftData = storageGet(`shifts-${locationId}-${contractId}`)
  const shiftIndex = shiftData?.list?.findIndex((shift) => shift.id === shiftId)
  console.log({name: 'editTransportInStoragePastShift', shiftId, transportId, data, shiftIndex})
  if (shiftData?.list?.[shiftIndex]) {
    const transportIndex = shiftData.list[shiftIndex].transports.findIndex(
      (transport) => transportId && transport.id === transportId
    )
    console.log({transportIndex})
    if (transportIndex >= 0) {
      shiftData.list[shiftIndex].transports[transportIndex] = data
    }
  }
  const pastShiftIndex = shiftData?.pastShifts?.findIndex((shift) => shift.id === shiftId)

  if (shiftData?.pastShifts?.[pastShiftIndex]) {
    const transportIndex = shiftData.pastShifts[pastShiftIndex].transports.findIndex(
      (transport) => transportId && transport.id === transportId
    )
    console.log({transportIndex})
    if (transportIndex >= 0) {
      shiftData.pastShifts[pastShiftIndex].transports[transportIndex] = data
    }
  }
  storageSet(`shifts-${locationId}-${contractId}`, shiftData)
}
