import {getServiceUrl} from 'helper/functions'

// map of endpoints for cleaner use in api calls
const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  forgottenPassword: `${getServiceUrl('auth')}/forgotten-password`,
  myAccount: `${getServiceUrl('auth')}/my-account`,
  resetPassword: `${getServiceUrl('auth')}/reset-password`,
  users: `${getServiceUrl('logistics')}/users`,
  machines: `${getServiceUrl('logistics')}/machines`,
  locations: `${getServiceUrl('logistics')}/locations`,
}

export default endpoints
