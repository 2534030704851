import React from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {required} from 'helper/validations'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import machinesPageStyle from 'component/MachinePage/machinesPageStyle'

const CreateMachineForm = (props) => {
  const {handleSubmit, classes} = props

  return (
    <Box p={5}>
      <Form onSubmit={handleSubmit}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={6} className={classes.smallGridPadding}>
                  <Field
                    name="name"
                    label={<Trans>Machine name</Trans>}
                    component={TextInput}
                    validate={required}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} className={classes.smallGridPadding}>
                  <Field
                    name="spz"
                    label={<Trans>SPZ</Trans>}
                    component={TextInput}
                    validate={required}
                  />
                </GridItem>
                <GridItem container justify={'flex-end'}>
                  <GridItem>
                    <PrimaryButton
                      formSubmit={true}
                      textPadding={0}
                      size={'medium'}
                      type="submit"
                      text={<Trans>Create</Trans>}
                    />
                  </GridItem>
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </Box>
  )
}

CreateMachineForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(machinesPageStyle)(CreateMachineForm)
