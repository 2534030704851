import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import editFieldStyle from 'component/editField/editFieldStyle'
import cx from 'classnames'

const EditFieldLabel = (props) => {
  const {value, classes, className} = props

  return <div className={cx(className, classes.editFieldLabel)}>{value}</div>
}

export default withStyles(editFieldStyle)(EditFieldLabel)
