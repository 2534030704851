import React from 'react'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  CONTRACT_LIST_REQUEST,
  CONTRACT_LIST_SUCCESS,
  CONTRACT_LIST_FAIL,
  CONTRACT_DETAIL_REQUEST,
  CONTRACT_DETAIL_SUCCESS,
  CONTRACT_DETAIL_FAIL,
  CONTRACT_UPDATE_REQUEST,
  CONTRACT_UPDATE_SUCCESS,
  CONTRACT_DELETE_REQUEST,
  CONTRACT_DELETE_SUCCESS,
  CONTRACT_DELETE_FAIL,
  OFFLINE_CONTRACT_DETAIL_SUCCESS,
  OFFLINE_CONTRACT_LIST_SUCCESS,
} from 'redux/actionType'
import {fireWarningOffline, globalApiErrorHandler} from 'helper/functions'
import {getOfflineShifts} from './shiftAction'

export const getContracts = (locationId) => {
  return (dispatch) => {
    dispatch({type: CONTRACT_LIST_REQUEST})
    return httpClient
      .get(`${endpoints.locations}/${locationId}/contracts`)
      .then((res) => {
        const objectsSorted = res.data.objects.sort((a, b) => {
          const sortArchived = Number(a.is_archived) - Number(b.is_archived)
          const sortDate = a.start > b.start ? 1 : -1
          return sortArchived || sortDate
        })
        dispatch({
          type: CONTRACT_LIST_SUCCESS,
          data: {...res.data, objects: objectsSorted},
          locationId,
        })
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: CONTRACT_LIST_FAIL, locationId})
          fireWarningOffline()
        } else {
          globalApiErrorHandler(err, dispatch)
        }
      })
  }
}

export const getOfflineContracts = (locationId) => {
  return (dispatch) => {
    return httpClient
      .get(`${endpoints.locations}/${locationId}/contracts`)
      .then((res) => {
        const objectsSorted = res.data.objects.sort((a, b) => {
          const sortArchived = Number(a.is_archived) - Number(b.is_archived)
          const sortDate = a.start > b.start ? 1 : -1
          return sortArchived || sortDate
        })
        dispatch({
          type: OFFLINE_CONTRACT_LIST_SUCCESS,
          data: {...res.data, objects: objectsSorted},
          locationId,
        })
        res.data.objects.forEach((contract) => {
          httpClient
            .get(`${endpoints.locations}/${locationId}/contracts/${contract.id}`)
            .then((res) => {
              dispatch({
                type: OFFLINE_CONTRACT_DETAIL_SUCCESS,
                data: res.data,
                locationId,
                contractId: contract.id,
              })
              getOfflineShifts(locationId, contract.id)(dispatch)
            })
            .catch(() => {})
        })
      })
      .catch(() => {})
  }
}

export const getContract = (locationId, contractId) => {
  return (dispatch) => {
    dispatch({type: CONTRACT_DETAIL_REQUEST})
    return httpClient
      .get(`${endpoints.locations}/${locationId}/contracts/${contractId}`)
      .then((res) => {
        dispatch({type: CONTRACT_DETAIL_SUCCESS, data: res.data, locationId, contractId})
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: CONTRACT_DETAIL_FAIL, locationId, contractId})
          fireWarningOffline()
        } else {
          globalApiErrorHandler(err, dispatch)
        }
      })
  }
}

export const updateContract = (locationId, contractId, data) => {
  return (dispatch) => {
    dispatch({type: CONTRACT_UPDATE_REQUEST})
    return httpClient
      .put(`${endpoints.locations}/${locationId}/contracts/${contractId}`, data)
      .then((res) => {
        dispatch({type: CONTRACT_UPDATE_SUCCESS})
        return Promise.resolve()
      })
      .catch((err) => globalApiErrorHandler(err, dispatch))
  }
}

export const uploadContractImage = (locationId, contractId, data) => {
  return httpClient
    .post(`${endpoints.locations}/${locationId}/contracts/${contractId}/images`, data)
    .then((res) => {
      return Promise.resolve()
    })
    .catch(globalApiErrorHandler)
}

export const deleteContractImage = (locationId, contractId, imageId) => {
  return httpClient
    .del(`${endpoints.locations}/${locationId}/contracts/${contractId}/images/${imageId}`)
    .then((res) => {
      return Promise.resolve()
    })
    .catch(globalApiErrorHandler)
}

export const createContract = (locationId, data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.locations}/${locationId}/contracts`, data)
      .then((res) => {
        return res.data
      })
      .catch((err) => globalApiErrorHandler(err, dispatch))
  }
}

export const deleteContract = (locationId, contractId) => {
  return (dispatch) => {
    dispatch({type: CONTRACT_DELETE_REQUEST})
    return httpClient
      .del(`${endpoints.locations}/${locationId}/contracts/${contractId}`)
      .then((res) => {
        dispatch({type: CONTRACT_DELETE_SUCCESS})
        return Promise.resolve()
      })
      .catch((err) => {
        dispatch({type: CONTRACT_DELETE_FAIL})
        globalApiErrorHandler(err, dispatch)
      })
  }
}
