const editFieldStyle = (theme) => ({
  defaultValue: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: 14,
  },

  editFieldLabel: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.dark,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '-0.5px',
  },
})

export default editFieldStyle
