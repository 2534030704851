import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import materialStyle from 'component/material/materialStyle'
import cx from 'classnames'

const CustomIconButton = (props) => {
  const {icon, onClick, className, classes} = props

  return (
    <div onClick={onClick} className={cx(className, classes.iconButton)}>
      {icon}
    </div>
  )
}

CustomIconButton.propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func,
}

export default withStyles(materialStyle)(CustomIconButton)
