import React, {useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import PropTypes from 'prop-types'
import LeftPanel from 'layout/PrivateLayout/LeftPanel'
import {fireErrorToast, fireSuccessToast, isUserLogged} from 'helper/functions'
import {FORCE_NO_AUTH} from 'helper/configConstants'
import {bindActionCreators, compose} from 'redux'
import {editMyAccount, getMyAccount, logout} from 'redux/action/authAction'
import {setActivePage} from 'redux/action/activePageAction'
import {connect} from 'react-redux'
import DialogWindow from 'component/material/DialogWindow'
import AccountSettingsForm from 'component/general/AccountSettingsForm'
import { Trans } from '@lingui/macro'
import OfflineActions from 'component/general/OfflineActions'

const PrivateLayout = (props) => {
  const {classes, children, setActivePage, getMyAccount, editMyAccount} = props

  const [openAccountSettings, setOpenAccountSettings] = useState(false)

  if (!FORCE_NO_AUTH && !isUserLogged()) {
    return setActivePage('login')
  }

  const handleSaveAccountSettings = (values) => {
    editMyAccount(values)
    .then((res) => {
      fireSuccessToast(<Trans>Account settings edited.</Trans>)
    })
    .catch((err) => {
      fireErrorToast(<Trans>Account Settings edit failed.</Trans>)
    })
    .finally(() => {
      getMyAccount()
      setOpenAccountSettings(false)
    })
  }

  return (
    <div className={classes.container}>
      <OfflineActions />
      <LeftPanel handleOpenAccountSettings={setOpenAccountSettings.bind(null, true)} />
      <DialogWindow
        title={<Trans>Account Settings</Trans>}
        maxWidth={'sm'}
        open={openAccountSettings}
        onClose={setOpenAccountSettings.bind(null, false)}
      >
        <AccountSettingsForm handleSubmit={handleSaveAccountSettings} />
      </DialogWindow>
      {children}
    </div>
  )
}

PrivateLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  setActivePage: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      setActivePage,
      getMyAccount,
      editMyAccount
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect(null, mapDispatchToProps)
)(PrivateLayout)
