import { MEDIA_MOBILE, MEDIA_SCREEN, RESPONSIVE_WIDTH } from "helper/constants"

// accessing theme which is active in ThemeProvider
const privateLayoutStyle = (theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
  },
  leftPanel: {
    minWidth: '100px',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.15)',
    borderRight: 'solid 1px ' + theme.palette.secondary.main,
    zIndex: 1,
  },
  [MEDIA_SCREEN]: {
    leftPanel: {
      minWidth: '100px'
    },
  },
  [MEDIA_MOBILE]: {
    leftPanel: {
      height: '80px',
      width: '100%',
      minWidth: '100%',
      position: 'fixed',
      bottom: '0px',
      zIndex: 1000
    },
  },

  leftPanelLogo: {
    height: 200,
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 40,
    boxSizing: 'content-box',
  },

  leftPanelLogoText: {
    color: theme.palette.primary.main,
    fontSize: 42,
    fontWeight: 800,
    transform: 'rotate(-90deg)',
  },

  leftPanelLogoImage: {
    transform: 'rotate(-90deg)',
    width: 150,
    marginTop: 25,
    marginLeft: 7,
  },

  leftPanelTopIcons: {
    width: '100px',
    boxSizing: 'border-box',
    '& svg': {
      width: '32px',
      height: '32px',
      cursor: 'pointer',
    },
  },

  leftPanelMenuIcon: {
    boxSizing: 'border-box',
    padding: '10px 0',
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 700,
    cursor: 'pointer',

    '&:hover': {
      '& span': {
        transition: 'opacity 200ms',
      },
      '& svg': {
        opacity: 1,
        transition: 'opacity 200ms',
      },
    },

    '& span': {
      opacity: 0.5,
      transition: 'opacity 200ms',
    },
    '& svg': {
      paddingBottom: 4,
      fill: theme.palette.primary.main,
      opacity: 0.5,
      transition: 'opacity 200ms',
    },
  },
  leftPanelMenuIconActive: {
    '& span': {
      opacity: 1,
    },
    '& svg': {
      opacity: 1,
      transition: 'opacity 200ms',
    },
  },

  leftPanelUserSettingIcon: {
    fontSize: 14,
    color: theme.palette.primary.main,
    '& svg': {
      fill: theme.palette.primary.main,
    },
    [MEDIA_MOBILE]: {
      padding: 0,
    }
  },

  myAccountRole: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  loginPageLogoImage: {
    width: '100%',
  },
})

export default privateLayoutStyle
