import React from 'react'
import Carousel from 'react-material-ui-carousel'
import Paper from '@material-ui/core/Paper'
import DialogWindow from 'component/material/DialogWindow'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'

const BigImageCarouselDialog = (props) => {
  const {classes, open, openBigCarousel, closeBigImageCarousel, images} = props

  return (
    <DialogWindow
      className={classes.transparentDialog}
      fullScreen
      open={open !== null}
      onClose={closeBigImageCarousel}
    >
      <Carousel
        autoPlay={false}
        index={openBigCarousel}
        animation={'slide'}
        fullHeightHover={false}
        className={classes.bigCarousel}
      >
        {images &&
          images.map((item, i) => (
            <Paper elevation={0} key={i} className={classes.bigCarouselPaper}>
              <img src={item.url} alt={item.id} className={classes.carouselImage} />
            </Paper>
          ))}
      </Carousel>
    </DialogWindow>
  )
}

BigImageCarouselDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.number,
  openBigCarousel: PropTypes.func,
  closeBigImageCarousel: PropTypes.func,
  images: PropTypes.array,
}

export default withStyles(locationDetailStyle)(BigImageCarouselDialog)
