import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {ReactComponent as ArrowDown} from 'style/asset/arrow-down.svg'

const CustomAccordion = (props) => {
  const {
    children,
    title,
    classes,
    square = true,
    elevation = 0,
    defaultExpanded = true,
    ...rest
  } = props

  return (
    <Accordion
      elevation={elevation}
      defaultExpanded={defaultExpanded}
      classes={{root: classes.accordionRoot}}
      square={square}
      {...rest}
    >
      <AccordionSummary
        expandIcon={<ArrowDown />}
        classes={{root: classes.accordionSumRoot, content: classes.accordionMargin}}
      >
        <BoxFullWidth>{title}</BoxFullWidth>
      </AccordionSummary>

      <AccordionDetails classes={{root: classes.accordionDetailPadding}}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default withStyles(materialStyle)(CustomAccordion)
