import React, {useState} from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Typography} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import {ReactComponent as Stepka} from 'style/asset/stepka.svg'
import {ReactComponent as Klada} from 'style/asset/klada.svg'
import withStyles from '@material-ui/core/styles/withStyles'
import shiftDetailStyle from 'component/MapLayoutPage/shiftDetail/shiftDetailStyle'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {Field, Form} from 'react-final-form'
import SelectInput from 'component/field/SelectInput'
import {required} from 'helper/validations'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import BoxFullWidth from 'component/material/BoxFullWidth'
import TextInput from 'component/field/TextInput'
import PrimaryButton from 'component/material/PrimaryButton'
import {
  createTransport,
  createTransportInStorage,
  editTransport,
  editTransportInStorage,
  editTransportInStoragePastShift,
  getShifts,
  getShiftsFromStorage,
} from 'redux/action/shiftAction'
import {fireErrorToast, fireSuccessToast, fireWarningToast} from 'helper/functions'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import {CUBIC_METERS_TO_PRM} from 'helper/constants'

const EditTransportDialog = (props) => {
  const {
    classes,
    currentShift,
    onClose,
    locationsList,
    createTransport,
    editTransport,
    getShifts,
    locationId,
    contractId,
    getShiftsFromStorage,
    isManager,
    transport,
    shiftId,
  } = props

  console.log({transport})

  const materialType = transport?.material

  const getMaterialUnits = () => (materialType === 'RAW' ? 'm³' : 'prm')

  const prepareTransportData = (values) => {
    const data = {
      delivery_note: values.delivery_note,
      netto: Number(values.netto) / (materialType === 'RAW' ? 1 : CUBIC_METERS_TO_PRM),
      material: materialType,
      destination: {
        location_id: values.destination,
        location_name: locationsList.find((loc) => loc.id === values.destination)?.name,
      },
      id: transport.id,
    }
    if (!shiftId && currentShift?.isOffline) {
      data.isOffline = true
      data.offlineId = currentShift.offlineId
      console.log('Adding transport to shift with offlineId ' + data.offlineId)
    }
    return data
  }

  const handleSubmit = (values) => {
    const transportData = prepareTransportData(values)

    editTransport(locationId, contractId, shiftId || currentShift.id, transportData)
      .then((res) => {
        fireSuccessToast(<Trans>Transport edited.</Trans>)
      })
      .catch((err) => {
        console.log('Edit transport submit .catch:')
        console.log(err)
        console.log(err.message)
        if (err.message === 'Network Error') {
          if (shiftId) {
            editTransportInStoragePastShift(
              locationId,
              contractId,
              shiftId,
              transportData.id,
              transportData
            )
          }
          editTransportInStorage(locationId, contractId, {
            ...transportData,
            transportOfflineId: err.transportOfflineId,
          })
          getShiftsFromStorage(locationId, contractId)
          fireWarningToast(<Trans>Offline mode, data will be synced later</Trans>)
        } else {
          fireErrorToast(<Trans>Editting transport failed.</Trans>)
        }
      })
      .finally(() => {
        onClose()
      })
  }

  return (
    <GridContainer direction={'column'}>
      <GridItem
        container
        justify={'center'}
        alignItems={'center'}
        xs={12}
        className={classes.newTransportTitle}
      >
        <Typography variant={'h2'} color={'primary'}>
          <Trans>Edit transport</Trans>
        </Typography>
      </GridItem>
      <GridItem xs={12} container direction={'row'}>
        {materialType === 'RAW' && (
          <GridItem
            container
            xs={6}
            direction={'column'}
            justify={'center'}
            alignItems={'center'}
            className={cx(classes.newTransportMaterial, classes.newTransportMaterialSelected)}
          >
            <Klada />
            <span>
              <Trans>Material</Trans>
            </span>
          </GridItem>
        )}
        {materialType === 'PROCESSED' && (
          <GridItem
            container
            xs={6}
            direction={'column'}
            justify={'center'}
            alignItems={'center'}
            className={cx(classes.newTransportMaterial, classes.newTransportMaterialSelected)}
          >
            <Stepka />
            <span>
              <Trans>Wood chips</Trans>
            </span>
          </GridItem>
        )}
      </GridItem>
      <GridItem xs={12} className={classes.newTransportForm}>
        <Form onSubmit={handleSubmit}>
          {(formProps) => {
            return (
              <form onSubmit={formProps.handleSubmit}>
                <BoxFullWidth p={5}>
                  <GridContainer>
                    <GridItem container xs={12} direction={'column'}>
                      <div className={classes.fieldLabel}>
                        <Trans>Target location</Trans>:
                      </div>
                      <Field
                        name="destination"
                        component={SelectInput}
                        options={locationsList}
                        validate={required}
                        initialValue={transport?.destination?.location_id}
                        className={classes.formField}
                      />
                    </GridItem>
                    <GridItem container xs={7} direction={'column'}>
                      <Box pr={2.5}>
                        <Field
                          label={<Trans>Delivery note:</Trans>}
                          name="delivery_note"
                          component={TextInput}
                          validate={required}
                          initialValue={transport?.delivery_note}
                        />
                      </Box>
                    </GridItem>
                    <GridItem container xs={5} direction={'column'}>
                      <Box pl={2.5}>
                        <Field
                          label={
                            <>
                              <Trans>Netto</Trans>
                              &nbsp;({getMaterialUnits()}):
                            </>
                          }
                          name="netto"
                          type={'number'}
                          component={TextInput}
                          validate={required}
                          className={classes.fieldEndAdornmentPadding}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                className={classes.endAdornmentPadding}
                                position="end"
                              >
                                {getMaterialUnits()}
                              </InputAdornment>
                            ),
                          }}
                          initialValue={
                            transport?.netto * (materialType === 'RAW' ? 1 : CUBIC_METERS_TO_PRM)
                          }
                        />
                      </Box>
                    </GridItem>
                    <GridItem container justify={'flex-end'} xs={12}>
                      <PrimaryButton
                        type={'submit'}
                        fullWidth={false}
                        formSubmit={true}
                        textPadding={1}
                        size={'small'}
                        text={
                          <GridContainer justify={'center'} alignItems={'center'}>
                            <Trans>Edit</Trans>
                          </GridContainer>
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </BoxFullWidth>
              </form>
            )
          }}
        </Form>
      </GridItem>
    </GridContainer>
  )
}

EditTransportDialog.propTypes = {
  classes: PropTypes.object,
  shiftId: PropTypes.number,
  locationsList: PropTypes.array,
  createTransport: PropTypes.func,
  editTransport: PropTypes.func,
  getShifts: PropTypes.func,
  onClose: PropTypes.func,
  locationId: PropTypes.number,
  contractId: PropTypes.number,
  transport: PropTypes.object,
  shiftId: PropTypes.number,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createTransport,
      editTransport,
      getShifts,
      getShiftsFromStorage,
    },
    dispatch
  )
}

export default compose(
  withStyles(shiftDetailStyle),
  connect((store) => {
    return {
      locationsList: store.locations.list,
      locationId: store.locations?.detail?.id,
      contractId: store.contracts?.detail?.id,
      currentShift: store.shifts?.currentShift,
      isManager: store.user.isManager,
    }
  }, mapDispatchToProps)
)(EditTransportDialog)
