import React, {useState} from 'react'
import {Trans} from '@lingui/macro'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import ArchiveIcon from '@material-ui/icons/Archive'
import {ReactComponent as Delete} from 'style/asset/bin.svg'
import EditTitleField from 'component/editField/EditTitleField'
import PropTypes from 'prop-types'
import WarningDialog from 'component/material/WarningDialog'
import {fireSuccessToast} from 'helper/functions'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {deleteLocation, getLocations, updateLocation} from 'redux/action/locationAction'
import EditIcon from 'component/icon/EditIcon'

const LocationDetailTitleRow = (props) => {
  const {
    locationDetail,
    editingField,
    onChange,
    onSave,
    onEdit,
    onClose,
    updateLocation,
    deleteLocation,
    getLocations,
    borderBottom = false,
    hasContract,
    filters,
    archivedFilter,
    activeWorkFilter,
    isManager,
  } = props

  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const prepareLocationEditData = (field) => {
    let submitData = {...locationDetail}

    delete submitData.contracts
    delete submitData.depot
    delete submitData.images

    return {...submitData, ...field}
  }

  // location delete
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }
  const handleDeleteLocation = () => {
    deleteLocation(locationDetail.id)
      .then((res) => {
        handleCloseDeleteDialog()
        fireSuccessToast(<Trans>Location deleted.</Trans>)
      })
      .catch((err) => {
        fireSuccessToast(<Trans>Location delete failed.</Trans>)
      })
      .finally(() => {
        handleCloseDeleteDialog()
        getLocations()
        onClose()
      })
  }

  // location archive
  const handleOpenArchiveDialog = () => {
    setOpenArchiveDialog(true)
  }
  const handleCloseArchiveDialog = () => {
    setOpenArchiveDialog(false)
  }
  const handleArchiveLocation = () => {
    updateLocation(
      locationDetail.id,
      prepareLocationEditData({is_archived: !locationDetail.is_archived})
    )
      .then((res) => {
        fireSuccessToast(<Trans>Location edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Location edit failed.</Trans>)
      })
      .finally(() => {
        getLocations(filters, archivedFilter, activeWorkFilter)
        handleCloseArchiveDialog()
      })
  }

  return (
    <>
      {/* CONFIRM ARCHIVE LOCATION */}
      <WarningDialog
        title={
          locationDetail?.is_archived ? (
            <Trans>Restore location from archive</Trans>
          ) : (
            <Trans>Archive location</Trans>
          )
        }
        message={locationDetail?.name}
        open={openArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onConfirm={handleArchiveLocation}
      />

      {/* CONFIRM DELETE LOCATION */}
      <WarningDialog
        title={<Trans>Delete location</Trans>}
        message={locationDetail?.name}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDeleteLocation}
      />

      <EditTitleField
        locationType={locationDetail?.type}
        defaultValue={locationDetail?.name}
        value={editingField?.name}
        name={'name'}
        editing={editingField?.hasOwnProperty('name')}
        onChange={onChange}
        onSave={onSave}
        onEdit={onEdit}
        onClose={onClose}
        hasContract={hasContract}
        borderBottom={borderBottom}
        showMenuButton={!!locationDetail?.id && isManager}
        menuItems={
          !locationDetail?.id
            ? []
            : [
                {
                  onClick: onEdit,
                  text: <Trans>Edit location name</Trans>,
                  icon: <EditIcon />,
                },
                {
                  onClick: handleOpenArchiveDialog,
                  text: locationDetail?.is_archived ? (
                    <Trans>Restore location from archive</Trans>
                  ) : (
                    <Trans>Archive location</Trans>
                  ),
                  icon: locationDetail?.is_archived ? <UnarchiveIcon /> : <ArchiveIcon />,
                },
                {
                  onClick: handleOpenDeleteDialog,
                  text: <Trans>Delete location</Trans>,
                  icon: <Delete />,
                },
              ]
        }
      />
    </>
  )
}

LocationDetailTitleRow.propTypes = {
  locationDetail: PropTypes.object,
  editingField: PropTypes.object,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
  updateLocation: PropTypes.func,
  deleteLocation: PropTypes.func,
  getLocations: PropTypes.func,
  hasContract: PropTypes.bool,
  isManager: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocation,
      deleteLocation,
      getLocations,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      isManager: store.user.myAccount.isManager,
    }
  }, mapDispatchToProps)
)(LocationDetailTitleRow)
