// user action types
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'
export const USER_LIST_REQUEST = 'USERS_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USERS_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USERS_LIST_FAIL'
export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL'
export const USER_UPDATE_REQUEST = 'MACHINE_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'MACHINE_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'MACHINE_UPDATE_FAIL'
export const MY_ACCOUNT_REQUEST = 'MY_ACCOUNT_REQUEST'
export const MY_ACCOUNT_SUCCESS = 'MY_ACCOUNT_SUCCESS'
export const MY_ACCOUNT_FAIL = 'MY_ACCOUNT_FAIL'

// global setting action type
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS'

// location action types
export const LOCATION_LIST_REQUEST = 'LOCATION_REQUEST'
export const LOCATION_LIST_SUCCESS = 'LOCATION_SUCCESS'
export const LOCATION_LIST_FAIL = 'LOCATION_LIST_FAIL'
export const LOCATION_DETAIL_REQUEST = 'LOCATION_DETAIL_REQUEST'
export const LOCATION_DETAIL_SUCCESS = 'LOCATION_DETAIL_SUCCESS'
export const LOCATION_DETAIL_FAIL = 'LOCATION_DETAIL_FAIL'
export const LOCATION_CREATE_REQUEST = 'LOCATION_CREATE_REQUEST'
export const LOCATION_CREATE_SUCCESS = 'LOCATION_CREATE_SUCCESS'
export const LOCATION_UPDATE_REQUEST = 'LOCATION_UPDATE_REQUEST'
export const LOCATION_UPDATE_SUCCESS = 'LOCATION_UPDATE_SUCCESS'
export const LOCATION_UPDATE_FAIL = 'LOCATION_UPDATE_FAIL'
export const LOCATION_DELETE_REQUEST = 'LOCATION_DELETE_REQUEST'
export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS'
export const LOCATION_DELETE_FAIL = 'LOCATION_DELETE_FAIL'
export const UPLOAD_LOCATION_IMAGE_REQUEST = 'UPLOAD_LOCATION_IMAGE_REQUEST'
export const UPLOAD_LOCATION_IMAGE_SUCCESS = 'UPLOAD_LOCATION_IMAGE_SUCCESS'

export const CLEAR_LOCATION_DETAIL = 'CLEAR_LOCATION_DETAIL'

// contract action types
export const CONTRACT_LIST_REQUEST = 'CONTRACT_LIST_REQUEST'
export const CONTRACT_LIST_SUCCESS = 'CONTRACT_LIST_SUCCESS'
export const CONTRACT_LIST_FAIL = 'CONTRACT_LIST_FAIL'
export const CONTRACT_DETAIL_REQUEST = 'CONTRACT_DETAIL_REQUEST'
export const CONTRACT_DETAIL_SUCCESS = 'CONTRACT_DETAIL_SUCCESS'
export const CONTRACT_DETAIL_FAIL = 'CONTRACT_DETAIL_FAIL'
export const CONTRACT_UPDATE_REQUEST = 'CONTRACT_UPDATE_REQUEST'
export const CONTRACT_UPDATE_SUCCESS = 'CONTRACT_UPDATE_SUCCESS'
export const CONTRACT_CREATE_REQUEST = 'CONTRACT_CREATE_REQUEST'
export const CONTRACT_CREATE_SUCCESS = 'CONTRACT_CREATE_SUCCESS'
export const CONTRACT_DELETE_REQUEST = 'CONTRACT_DELETE_REQUEST'
export const CONTRACT_DELETE_SUCCESS = 'CONTRACT_DELETE_SUCCESS'
export const CONTRACT_DELETE_FAIL = 'CONTRACT_DELETE_FAIL'

// shift action types
export const SHIFT_LIST_REQUEST = 'SHIFT_LIST_REQUEST'
export const SHIFT_LIST_SUCCESS = 'SHIFT_LIST_SUCCESS'
export const SHIFT_LIST_FAIL = 'SHIFT_LIST_FAIL'
export const SHIFT_DETAIL_REQUEST = 'SHIFT_DETAIL_REQUEST'
export const SHIFT_DETAIL_SUCCESS = 'SHIFT_DETAIL_SUCCESS'
export const SHIFT_DETAIL_FAIL = 'SHIFT_DETAIL_FAIL'
export const SHIFT_CREATE_REQUEST = 'SHIFT_CREATE_REQUEST'
export const SHIFT_CREATE_SUCCESS = 'SHIFT_CREATE_SUCCESS'
export const SHIFT_CREATE_FAIL = 'SHIFT_CREATE_FAIL'
export const SHIFT_UPDATE_REQUEST = 'SHIFT_UPDATE_REQUEST'
export const SHIFT_UPDATE_SUCCESS = 'SHIFT_UPDATE_SUCCESS'
export const SHIFT_UPDATE_FAIL = 'SHIFT_UPDATE_FAIL'

// transport action types
export const TRANSPORT_LIST_REQUEST = 'TRANSPORT_LIST_REQUEST'
export const TRANSPORT_LIST_SUCCESS = 'TRANSPORT_LIST_SUCCESS'
export const TRANSPORT_LIST_FAIL = 'TRANSPORT_LIST_FAIL'
export const TRANSPORT_REQUEST = 'TRANSPORT_REQUEST'
export const TRANSPORT_SUCCESS = 'TRANSPORT_SUCCESS'
export const TRANSPORT_FAIL = 'TRANSPORT_FAIL'
export const TRANSPORT_CREATE_FAIL = 'TRANSPORT_CREATE_FAIL'
export const TRANSPORT_DELETE_FAIL = 'TRANSPORT_DELETE_FAIL'
export const TRANSPORT_EDIT_FAIL = 'TRANSPORT_EDIT_FAIL'


// machine action types
export const MACHINE_LIST_REQUEST = 'MACHINE_LIST_REQUEST'
export const MACHINE_LIST_SUCCESS = 'MACHINE_LIST_SUCCESS'
export const MACHINE_LIST_FAIL = 'MACHINE_LIST_FAIL'
export const MACHINE_CREATE_REQUEST = 'MACHINE_CREATE_REQUEST'
export const MACHINE_CREATE_SUCCESS = 'MACHINE_CREATE_SUCCESS'
export const MACHINE_CREATE_FAIL = 'MACHINE_CREATE_FAIL'
export const MACHINE_UPDATE_REQUEST = 'MACHINE_UPDATE_REQUEST'
export const MACHINE_UPDATE_SUCCESS = 'MACHINE_UPDATE_SUCCESS'
export const MACHINE_UPDATE_FAIL = 'MACHINE_UPDATE_FAIL'

// active page action type
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE'

// offline actions
export const OFFLINE_ACTION_POP = 'OFFLINE_ACTION_POP'
export const OFFLINE_FIX_SHIFT_ID = 'OFFLINE_FIX_SHIFT_ID'
export const OFFLINE_LOCATION_LIST_SUCCESS = 'OFFLINE_LOCATION_LIST_SUCCESS'
export const OFFLINE_LOCATION_DETAIL_SUCCESS = 'OFFLINE_LOCATION_DETAIL_SUCCESS'
export const OFFLINE_CONTRACT_LIST_SUCCESS = 'OFFLINE_CONTRACT_LIST_SUCCESS'
export const OFFLINE_CONTRACT_DETAIL_SUCCESS = 'OFFLINE_CONTRACT_DETAIL_SUCCESS'
export const OFFLINE_SHIFT_LIST_SUCCESS = 'OFFLINE_SHIFT_LIST_SUCCESS'
export const TRANSPORT_DELETE_OFFLINE_ACTION = 'TRANSPORT_DELETE_OFFLINE_ACTION'