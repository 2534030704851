import React from 'react'
import GridItem from 'component/material/GridItem'
import {InputAdornment, TextField} from '@material-ui/core'
import GridContainer from 'component/material/GridContainer'
import PropTypes from 'prop-types'
import EditIconButton from 'component/editField/EditIconButton'
import CheckIconButton from 'component/editField/CheckIconButton'
import CloseIconButton from 'component/editField/CloseIconButton'
import EditFieldLabel from 'component/editField/EditFieldLabel'
import EditFieldDefaultValue from 'component/editField/EditFieldDefaultValue'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import cx from 'classnames'

/**
 * Component able of toggling edit/display single controlled fields
 *
 * @param defaultValue = initial value of field, displayed when not editing
 * @param value = actual value which change during onChange event
 * @param editing = true/false
 * @param name = name of the field on BE
 * @param onChange = function controlling field change
 * @param onSave = submit put request with new value
 * @param onEdit = setting field which is edited or cancelling edit with null
 * @param responsiveness = object with material breakpoints
 * @returns {JSX.Element}
 */
const EditTextField = (props) => {
  const {
    defaultValue,
    value,
    label,
    editing,
    enabled,
    name,
    onChange,
    onSave,
    onEdit,
    responsiveness = {xs: 12, sm: 6},
    classes,
    borderSide,
    units,
  } = props

  const classesObject = cx(
    classes.borderBottom,
    borderSide === 'right' && classes.borderRight,
    borderSide === 'left' && classes.borderLeft
  )

  return (
    <GridItem container {...responsiveness} className={classesObject} alignItems={'center'}>
      <GridItem container xs={true} direction={'column'}>
        {editing ? (
          <TextField
            fullWidth
            name={name}
            value={value}
            onChange={onChange}
            InputProps={{endAdornment: units && <InputAdornment>{units}</InputAdornment>}}
          />
        ) : (
          <EditFieldDefaultValue
            value={defaultValue || defaultValue === 0 ? defaultValue + ' ' + units : '- - - -'}
          />
        )}
        <EditFieldLabel value={label} />
      </GridItem>
      <GridItem>
        {enabled && (
          <>
            {editing ? (
              <GridContainer justify={'flex-end'} alignItems={'center'} direction={'column'}>
                <CheckIconButton onSave={onSave} />
                <CloseIconButton onClose={onEdit(null)} />
              </GridContainer>
            ) : (
              <EditIconButton onEdit={onEdit(name)} />
            )}
          </>
        )}
      </GridItem>
    </GridItem>
  )
}

EditTextField.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editing: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  responsiveness: PropTypes.object,
  classes: PropTypes.object,
  borderSide: PropTypes.string,
  units: PropTypes.string,
}

EditTextField.defaultProps = {
  enabled: true,
}

export default withStyles(locationDetailStyle)(EditTextField)
