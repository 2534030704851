import React, {useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import GridItem from 'component/material/GridItem'
import {fireSuccessToast} from 'helper/functions'
import {Trans} from '@lingui/macro'
import {getLocation, updateLocation} from 'redux/action/locationAction'
import EditCoordinateField from 'component/editField/EditCoordinateField'
import EditTextAreaField from 'component/editField/EditTextAreaField'
import PrimaryButton from 'component/material/PrimaryButton'
import AddCircleIcon from '@material-ui/icons/AddCircleRounded'
import GridContainer from 'component/material/GridContainer'
import ImageCarousel from 'component/MapLayoutPage/locationDetail/ImageCarousel'
import BigImageCarouselDialog from 'component/MapLayoutPage/locationDetail/BigImageCarouselDialog'
import DialogWindow from 'component/material/DialogWindow'
import {createContract} from 'redux/action/contractAction'
import CreateContractForm from 'component/MapLayoutPage/locationDetail/CreateContractForm'
import EditFieldRow from 'component/editField/EditFieldRow'
import {DB_DATE_FORMAT} from 'helper/constants'
import LocationDetailTitleRow from 'component/MapLayoutPage/locationDetail/LocationDetailTitleRow'
import EditTextField from 'component/editField/EditTextField'
import EditCoordsForm from 'component/MapLayoutPage/locationDetail/EditCoordsForm'

const LocationWithoutContractDialog = (props) => {
  const {
    locationDetail,
    onClose,
    classes,
    createContract,
    updateLocation,
    getLocation,
    filters,
    archivedFilter,
    activeWorkFilter,
    handleLocationFromMap,
    locationFromMapCoords,
    gettingLocationFromMap,
    isManager,
  } = props

  const [openBigCarousel, setOpenBigCarousel] = useState(null)
  const [editingField, setEditingField] = useState(null)
  const [openCreateContract, setOpenCreateContract] = useState(false)
  const [openEditCoords, setOpenEditCoords] = useState(false)

  const openCreateContractDialog = () => {
    setOpenCreateContract(true)
  }
  const closeContractDialog = () => {
    setOpenCreateContract(false)
  }

  const prepareContractCreateData = (data) => {
    return {
      description: data.description,
      start: data.start.format(DB_DATE_FORMAT),
      end: data.end.format(DB_DATE_FORMAT),
      type: data.type,
      status: data.status,
      machine_id: data.machine_id,
      netto: data.netto / (data.material_type === 'WOOD' ? 1 : 2.6),
      material_type: data.material_type,
    }
  }

  const handleCreateContract = (data) => {
    createContract(locationDetail.id, prepareContractCreateData(data))
      .then((res) => {
        fireSuccessToast(<Trans>New contract created.</Trans>)
      })
      .catch((err) => {
        fireSuccessToast(<Trans>Contract create failed.</Trans>)
      })
      .finally(() => {
        getLocation(locationDetail.id)
        closeContractDialog()
      })
  }

  const prepareLocationEditData = (field) => {
    let submitData = {...locationDetail}

    delete submitData.contracts
    delete submitData.depot
    delete submitData.images

    return {...submitData, ...field}
  }

  const handleOnChange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    setEditingField({[fieldName]: fieldValue})
  }

  const handleEditingField = (field) => (e) => {
    setEditingField({[field]: locationDetail[field] || ''})
  }

  const handleEditTitle = (fieldName) => {
    setEditingField({[fieldName]: locationDetail[fieldName] || ''})
  }

  const handleEditCoords = (field) => (e) => {
    setOpenEditCoords(true)
  }
  const handleCloseEditCoords = () => {
    setOpenEditCoords(false)
  }

  const handleUpdateLocation = (e) => {
    updateLocation(locationDetail.id, prepareLocationEditData(editingField))
      .then((res) => {
        fireSuccessToast(<Trans>Location edited.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Location edit failed.</Trans>)
      })
      .finally(() => {
        getLocation(locationDetail.id)
        setEditingField(null)
      })
  }

  const closeBigImageCarousel = () => {
    setOpenBigCarousel(null)
  }

  const openBigImageCarousel = (index) => () => {
    setOpenBigCarousel(index)
  }

  return (
    <>
      {/* FULL SCREEN CAROUSEL OPENED AFTER CLICK ON SMALL IMAGE */}
      <BigImageCarouselDialog
        open={openBigCarousel}
        closeBigImageCarousel={closeBigImageCarousel}
        images={locationDetail?.images}
      />
      {/* CREATE CONTRACT DIALOG */}
      <DialogWindow
        title={<Trans>New contract</Trans>}
        maxWidth={'sm'}
        open={openCreateContract}
        onClose={closeContractDialog}
      >
        <CreateContractForm handleSubmit={handleCreateContract} />
      </DialogWindow>

      {/* EDIT COORDS DIALOG */}
      <DialogWindow
        title={<Trans>Edit coords</Trans>}
        maxWidth={'sm'}
        open={openEditCoords}
        onClose={handleCloseEditCoords}
        className={gettingLocationFromMap ? classes.hideModal : ''}
      >
        <EditCoordsForm
          onClose={handleCloseEditCoords}
          locationFromMapCoords={locationFromMapCoords}
          handleLocationFromMap={handleLocationFromMap}
          coordinates={locationDetail?.coordinates}
        />
      </DialogWindow>

      <GridItem container xs={12}>
        {/* FIRST ROW WITH TITLE NAME OF LOCATION */}
        <LocationDetailTitleRow
          locationDetail={locationDetail}
          editingField={editingField}
          onChange={handleOnChange}
          onSave={handleUpdateLocation}
          onEdit={handleEditTitle}
          onClose={onClose}
          filters={filters}
          archivedFilter={archivedFilter}
          activeWorkFilter={activeWorkFilter}
        />

        <EditFieldRow>
          <div className={classes.locationEditRowTitle}>
            <Trans>Location info</Trans>
          </div>
          {locationDetail?.type === 'WORK' && (
            <PrimaryButton
              onClick={openCreateContractDialog}
              fullWidth={false}
              size={'small'}
              textPadding={0}
              text={
                <GridContainer justify={'center'} alignItems={'center'}>
                  <div className={classes.addCircleIconPadding}>
                    <AddCircleIcon />
                  </div>
                  <Trans>Create contract</Trans>
                </GridContainer>
              }
            />
          )}
        </EditFieldRow>

        {/* COORDINATES */}
        <EditCoordinateField
          defaultValue={locationDetail?.coordinates}
          name={'coordinates'}
          label={<Trans>Coordinates</Trans>}
          onEdit={handleEditCoords}
          responsiveness={locationDetail?.type === 'WORK' ? {xs: 12} : {xs: 12, sm: 6}}
          enabled
        />

        {/* ADDRESS */}
        {locationDetail?.type !== 'WORK' && (
          <EditTextField
            defaultValue={locationDetail?.address}
            value={editingField?.address}
            editing={editingField?.hasOwnProperty('address')}
            label={<Trans>Address</Trans>}
            name={'address'}
            onChange={handleOnChange}
            onSave={handleUpdateLocation}
            onEdit={handleEditingField}
            borderSide={'right'}
          />
        )}

        {/* DESCRIPTION FIELD FOR LOCATION */}
        <EditTextAreaField
          defaultValue={locationDetail?.description}
          value={editingField?.description}
          name={'description'}
          editing={editingField?.hasOwnProperty('description')}
          onChange={handleOnChange}
          onSave={handleUpdateLocation}
          onEdit={handleEditingField}
          label={<Trans>Location description</Trans>}
          textFieldProps={{rows: 3, multiline: true}}
        />
        {/* SMALL CAROUSEL IN BOTTOM OF PAGE */}
        <GridItem container xs={12}>
          <ImageCarousel
            openBigImageCarousel={openBigImageCarousel}
            images={locationDetail?.images}
            locationId={locationDetail?.id}
          />
        </GridItem>
      </GridItem>
    </>
  )
}

LocationWithoutContractDialog.propTypes = {
  onClose: PropTypes.func,
  locationDetail: PropTypes.object,
  classes: PropTypes.object,
  hasLocationContract: PropTypes.bool,
  createContract: PropTypes.func,
  updateLocation: PropTypes.func,
  getLocation: PropTypes.func,
  handleLocationFromMap: PropTypes.func,
  gettingLocationFromMap: PropTypes.bool,
  isManager: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocation,
      createContract,
      getLocation,
    },
    dispatch
  )
}

export default compose(
  withStyles(mapLayoutPageStyle),
  connect((store) => {
    return {
      locationsLoading: store.locations.loading,
      locationDetail: store.locations.detail,
      isManager: store.user.myAccount.isManager,
    }
  }, mapDispatchToProps)
)(LocationWithoutContractDialog)
