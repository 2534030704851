import {storageGet, storageSaveState, storageSet} from 'helper/functions'
import {
  LOCATION_DETAIL_REQUEST,
  LOCATION_DETAIL_SUCCESS,
  LOCATION_DETAIL_FAIL,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_FAIL,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_UPDATE_FAIL,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_FAIL,
  LOCATION_DELETE_SUCCESS,
  OFFLINE_LOCATION_LIST_SUCCESS,
  OFFLINE_LOCATION_DETAIL_SUCCESS,
  CLEAR_LOCATION_DETAIL,
} from 'redux/actionType'

const initState = storageGet('locations') || {
  loading: false,
  list: [],
  detail: {},
  meta: {},
  imageUpload: false,
}

const locationReducer = (state = initState, action) => {
  switch (action.type) {
    case LOCATION_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case LOCATION_LIST_SUCCESS: {
      return {
        ...state,
        offlineData: false,
        loading: false,
        list: action.data.objects,
        meta: action.data.meta,
      }
    }
    case LOCATION_LIST_FAIL: {
      return {
        ...state,
        ...storageGet('locations'),
        offlineData: true,
        loading: false,
      }
    }
    case OFFLINE_LOCATION_LIST_SUCCESS: {
      storageSet('locations', {list: action.data.objects})
      return state
    }

    case OFFLINE_LOCATION_DETAIL_SUCCESS: {
      const enhancedDetail = {...action.data, hasLocationContract: !!action.data.contracts?.length}
      storageSet(`locations-detail-${action.locationId}`, enhancedDetail)
      return state
    }

    case LOCATION_DETAIL_REQUEST: {
      return {
        ...state,
        loading: true,
        detail: {},
      }
    }
    case LOCATION_DETAIL_SUCCESS: {
      const enhancedDetail = {...action.data, hasLocationContract: !!action.data.contracts?.length}
      storageSet(`locations-detail-${action.locationId}`, enhancedDetail)
      return {
        ...state,
        detail: enhancedDetail,
        loading: false,
      }
    }

    case LOCATION_DETAIL_FAIL: {
      return {
        ...state,
        offlineData: true,
        detail: storageGet(`locations-detail-${action.locationId}`),
        loading: false,
      }
    }

    case LOCATION_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case LOCATION_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        detail: action.data,
        list: [...state.list.filter((item) => item.id !== action.data.id), {...action.data}],
      }
    }
    case LOCATION_UPDATE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case LOCATION_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    // SUCCESS and FAIL has same result
    case LOCATION_DELETE_SUCCESS:
    case LOCATION_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case CLEAR_LOCATION_DETAIL: {
      return {
        ...state,
        detail: {},
      }
    }

    default: {
      return state
    }
  }
}

export default locationReducer
