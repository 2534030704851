import React from 'react'
import {ReactComponent as Work} from 'style/asset/work.svg'
import {ReactComponent as Gas} from 'style/asset/gas.svg'
import {ReactComponent as Destination} from 'style/asset/destination.svg'
import {ReactComponent as Service} from 'style/asset/service.svg'
import withStyles from '@material-ui/core/styles/withStyles'
import iconStyle from 'component/icon/iconStyle'
import PropTypes from 'prop-types'
import cx from 'classnames'

const LocationTypeIcon = (props) => {
  const {className, classes, type, ...rest} = props

  const getIconByType = () => {
    if (type === 'WORK') {
      return (
        <div className={cx(classes.locationTypeIcon, classes.locationTypeWork)}>
          <Work className={cx(className)} {...rest} />
        </div>
      )
    } else if (type === 'DESTINATION') {
      return (
        <div className={cx(classes.locationTypeIcon, classes.locationTypeDestination)}>
          <Destination className={cx(className)} {...rest} />
        </div>
      )
    } else if (type === 'GAS_STATION') {
      return (
        <div className={cx(classes.locationTypeIcon, classes.locationTypeGas)}>
          <Gas className={cx(className)} {...rest} />
        </div>
      )
    } else if (type === 'SERVICE_STATION') {
      return (
        <div className={cx(classes.locationTypeIcon, classes.locationTypeService)}>
          <Service className={cx(className)} {...rest} />
        </div>
      )
    } else {
      return null
    }
  }

  return getIconByType()
}

LocationTypeIcon.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  rest: PropTypes.object,
}

export default withStyles(iconStyle)(LocationTypeIcon)
