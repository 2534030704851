import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import PropTypes from 'prop-types'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'

const CheckIconButton = (props) => {
  const {onSave, classes} = props

  return (
    <IconButton onClick={onSave} classes={{root: classes.saveCloseButtonIcon}}>
      <CheckRoundedIcon />
    </IconButton>
  )
}

CheckIconButton.propTypes = {
  onEdit: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(locationDetailStyle)(CheckIconButton)
