import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import PropTypes from 'prop-types'
import EditIcon from 'component/icon/EditIcon'

const EditIconButton = (props) => {
  const {onEdit, classes} = props

  return (
    <IconButton onClick={onEdit} classes={{root: classes.editIconButtonPadding}}>
      <EditIcon />
    </IconButton>
  )
}

EditIconButton.propTypes = {
  onEdit: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(locationDetailStyle)(EditIconButton)
