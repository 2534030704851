import { MEDIA_MOBILE, MEDIA_SCREEN } from "helper/constants"

// accessing theme which is active in ThemeProvider
const mapLayoutPageStyle = (theme) => ({
  mapLayoutPage: {
    width: '100%',
  },

  mapContainer: {
    flexGrow: 1,
    height: '100%',
    background: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  rightPanel: {
    width: '28%',
    maxWidth: '350px',
    minWidth: '166px',
    position: 'relative',
    transition: 'width 250ms ease-out',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.15)',
    borderLeft: '1px solid ' + theme.palette.secondary.main,
    '& svg': {
      fill: theme.palette.primary.main,
    },
    [MEDIA_MOBILE]: {
      height: 'calc(100vh - 80px)',
    },
    [MEDIA_SCREEN]: {
      height: '100vh',
    }
  },

  rightPanelLocationTitle: {
    fontWeight: 600,
    fontSize: 24,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },

  closedPanel: {
    transition: 'width 250ms ease-out',
    width: '10px',
    minWidth: '10px'
  },

  rightPanelContent: {
    zIndex: 999,
    // height: '50vh',
    overflowY: 'auto'
  },

  rightPanelToggleIcon: {
    bottom: '60px',
    left: '-42px',
    position: 'absolute',
    zIndex: '10',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    height: '42px',
    width: '42px',
    cursor: 'pointer',
    transition: 'color 200ms',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderLeft: '1px solid ' + theme.palette.secondary.main,
    borderTop: '1px solid ' + theme.palette.secondary.main,
    borderBottom: '1px solid ' + theme.palette.secondary.main,
    boxSizing: 'border-box',

    '&:hover': {
      transition: 'color 200ms',
      color: theme.palette.text.primary,
    },
  },

  panelCloseIcon: {
    width: 16,
    height: 16,
  },

  panelOpenIcon: {
    width: 20,
    height: 20,
  },

  mapLayoutFilterButton: {
    position: 'absolute',
    top: '30px',
    left: '-72px',
    zIndex: 20,
    width: 42,
    height: 42,
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid ' + theme.palette.secondary.main,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
  },
  mapLayoutCompassButton: {
    position: 'absolute',
    top: '30px',
    left: '-132px',
    zIndex: 20,
    width: 42,
    height: 42,
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid ' + theme.palette.secondary.main,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
  },
  mapLayoutFilterOpened: {
    borderBottom: 'none',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxSizing: 'border-box',
  },
  filterContainer: {
    position: 'absolute',
    top: '80px',
    left: '-280px',
    zIndex: '10',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: 0,
    height: 'auto',
    width: 250,
    boxSizing: 'border-box',
  },
  panelCoverElement: {
    position: 'absolute',
    top: '71px',
    left: '-72px',
    width: 42,
    height: 10,
    zIndex: 20,
    backgroundColor: theme.palette.background.paper,
    borderLeft: '1px solid ' + theme.palette.secondary.main,
    borderRight: '1px solid ' + theme.palette.secondary.main,
    boxSizing: 'border-box',
  },
  filterTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 700,
  },
  // filter switch
  switchClasses: {
    '& .MuiSwitch-switchBase': {
      color: theme.palette.primary.main,
    },

    '& .MuiSwitch-track': {
      opacity: 0.5,
      background: theme.palette.primary.main,
    },
  },
  switchLabel: {
    color: theme.palette.primary.main,
    paddingLeft: 10,
  },

  mapLayoutCreateButton: {
    backgroundColor: theme.palette.primary.contrastText,
    position: 'absolute',
    zIndex: '10',
    right: 60,
    '& svg': {
      fill: theme.palette.primary.contrastText,
    },
  },

  // flex grow for form group of checkboxes
  formGroupGrow: {
    flexGrow: 1,
  },

  colorCircle: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '2px', // ensure nice view when wrapped
  },

  // changing padding around checkbox
  filterCheckbox: {
    padding: '6px 8px',

    // size of svg icon
    '& .MuiSvgIcon-root': {
      width: '26px',
      height: '26px',
    },
  },

  filterSubCheckbox: {
    marginLeft: 29,
    padding: '6px 8px',

    // size of svg icon
    '& .MuiSvgIcon-root': {
      width: '22px',
      height: '22px',
    },
  },

  filterIconText: {
    color: theme.palette.primary.main,

    '& span': {
      display: 'flex',
      alignSelf: 'center',
      paddingRight: 10,
    },
  },

  workColor: {
    '& svg': {
      fill: theme.palette.work + '!important',
    },
  },
  destinationColor: {
    '& svg': {
      fill: theme.palette.destination + '!important',
    },
  },
  gasColor: {
    '& svg': {
      fill: theme.palette.gas + '!important',
    },
  },
  serviceColor: {
    '& svg': {
      fill: theme.palette.service + '!important',
    },
  },

  // single location row styles
  singleLocationContainer: {
    cursor: 'pointer',
  },
  singleLocationDate: {
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.dark,
    paddingLeft: 28,
    '& svg': {
      paddingRight: 8,
    },
  },
  dashedBorder: {
    height: 1,
    backgroundImage: 'linear-gradient(to right, #b6b6be 60%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'bottom',
    backgroundSize: '16px 1px',
    backgroundRepeat: 'repeat-x',
    marginTop: '10px',
  },
  workTypeIcon: {
    '& svg': {
      width: 15,
      fill: theme.palette.work + ' !important',
    },
  },
  gasTypeIcon: {
    '& svg': {
      width: 15,
      fill: theme.palette.gas + ' !important',
    },
  },
  destinationTypeIcon: {
    '& svg': {
      width: 15,
      fill: theme.palette.destination + ' !important',
    },
  },
  serviceTypeIcon: {
    '& svg': {
      width: 15,
      fill: theme.palette.service + ' !important',
    },
  },
  locationName: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
  },
  cursorPointer: {
    cursor: 'pointer',
  },

  // dialog for location detail which has no contract
  locationDetailWithoutContract: {
    pointerEvents: 'none !important',

    '& .MuiDialog-scrollPaper': {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },

    '& .MuiDialog-paper': {
      width: '50%',
      pointerEvents: 'all !important',
      [MEDIA_MOBILE]: {
        width: '100%',
      }
    },
  },

  addCircleIconPadding: {
    paddingRight: 10,
    alignSelf: 'center',
    display: 'flex',
  },

  locationEditRowTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  hideModal: {
    display: 'none',
  },
})

export default mapLayoutPageStyle
