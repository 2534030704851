import React from 'react'
import GridItem from 'component/material/GridItem'
import GridContainer from 'component/material/GridContainer'
import moment from 'moment'
import {DAY_MONTH_FORMAT, DB_DATE_FORMAT} from 'helper/constants'
import {DatePicker} from '@material-ui/pickers'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import EditIconButton from 'component/editField/EditIconButton'
import EditFieldDefaultValue from 'component/editField/EditFieldDefaultValue'
import EditFieldLabel from 'component/editField/EditFieldLabel'
import CheckIconButton from 'component/editField/CheckIconButton'
import CloseIconButton from 'component/editField/CloseIconButton'
import cx from 'classnames'

/**
 * Component able of toggling edit/display single controlled fields
 *
 * @param defaultValue = initial value of field, displayed when not editing
 * @param value = actual value which change during onChange event
 * @param editing = true/false
 * @param name = name of the field on BE
 * @param onChange = function controlling field change
 * @param onSave = submit put request with new value
 * @param onEdit = setting field which is edited or cancelling edit with null
 * @returns {JSX.Element}
 */
const EditContractDateField = (props) => {
  const {
    defaultStart,
    start,
    defaultEnd,
    end,
    label,
    editing,
    enabled,
    onChange,
    onSave,
    onEdit,
    responsiveness = {xs: 12, sm: 6},
    classes,
  } = props

  const handleFieldChange = (name) => (value) => {
    if (name === 'start') {
      onChange({end: end, [name]: value.format(DB_DATE_FORMAT)})
    } else {
      onChange({start: start, [name]: value.format(DB_DATE_FORMAT)})
    }
  }

  const getDefaultValue = () => {
    if (defaultStart && defaultEnd) {
      const startFormat = moment(defaultStart).format(DAY_MONTH_FORMAT)
      const endFormat = moment(defaultEnd).format(DAY_MONTH_FORMAT)
      return `${startFormat} - ${endFormat}`
    }
  }

  return (
    <GridItem
      container
      {...responsiveness}
      className={cx(classes.borderBottom, classes.leftColumnField, classes.borderRight)}
      alignItems={'center'}
    >
      <GridItem xs={true}>
        {editing ? (
          <GridContainer direction={'row'} spacing={2}>
            <GridItem xs={6}>
              <DatePicker
                variant="inline"
                labe={<Trans>Start</Trans>}
                format={DAY_MONTH_FORMAT}
                value={start}
                onChange={handleFieldChange('start')}
                autoOk={true}
              />
            </GridItem>
            <GridItem xs={6}>
              <DatePicker
                variant="inline"
                labe={<Trans>End</Trans>}
                format={DAY_MONTH_FORMAT}
                value={end}
                onChange={handleFieldChange('end')}
                autoOk={true}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <EditFieldDefaultValue value={getDefaultValue()} />
        )}
        <EditFieldLabel value={label} />
      </GridItem>
      <GridItem>
        {enabled && (
          <>
            {editing ? (
              <GridContainer justify={'flex-end'} alignItems={'center'} direction={'column'}>
                <CheckIconButton onSave={onSave} />
                <CloseIconButton onClose={onEdit(null)} />
              </GridContainer>
            ) : (
              <EditIconButton onEdit={onEdit({start: defaultStart, end: defaultEnd})} />
            )}
          </>
        )}
      </GridItem>
    </GridItem>
  )
}

EditContractDateField.defaultProps = {
  enabled: false,
}

export default withStyles(locationDetailStyle)(EditContractDateField)
