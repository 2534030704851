import {storageGet, storageSet} from 'helper/functions'
import {
  CONTRACT_DETAIL_REQUEST,
  CONTRACT_DETAIL_SUCCESS,
  CONTRACT_LIST_REQUEST,
  CONTRACT_LIST_SUCCESS,
  CONTRACT_LIST_FAIL,
  CONTRACT_UPDATE_REQUEST,
  CONTRACT_UPDATE_SUCCESS,
  CONTRACT_DETAIL_FAIL,
  OFFLINE_CONTRACT_LIST_SUCCESS,
  OFFLINE_CONTRACT_DETAIL_SUCCESS,
  CLEAR_LOCATION_DETAIL,
} from 'redux/actionType'

const initState = {
  loading: false,
  listLoading: false,
  meta: {},
  list: [],
  detail: {},
}
const contractReducer = (state = initState, action) => {
  switch (action.type) {
    case CONTRACT_LIST_REQUEST: {
      return {
        ...state,
        listLoading: true,
      }
    }
    case CONTRACT_LIST_SUCCESS: {
      storageSet(`contracts-${action.locationId}`, {list: action.data.objects})
      return {
        ...state,
        offlineData: false,
        listLoading: false,
        list: action.data.objects,
        locationId: action.locationId,
      }
    }
    case OFFLINE_CONTRACT_LIST_SUCCESS: {
      storageSet(`contracts-${action.locationId}`, {list: action.data.objects})
      return state
    }
    case OFFLINE_CONTRACT_DETAIL_SUCCESS: {
      storageSet(`contracts-detail-${action.locationId}-${action.contractId}`, action.data)
      return state
    }

    case CONTRACT_LIST_FAIL: {
      return {
        ...state,
        offlineData: true,
        ...storageGet(`contracts-${action.locationId}`),
        listLoading: false,
      }
    }

    case CONTRACT_DETAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case CONTRACT_DETAIL_SUCCESS: {
      storageSet(`contracts-detail-${action.locationId}-${action.contractId}`, action.data)
      return {
        ...state,
        loading: false,
        detail: action.data,
      }
    }
    case CONTRACT_DETAIL_FAIL: {
      return {
        offlineData: true,
        ...state,
        detail: storageGet(`contracts-detail-${action.locationId}-${action.contractId}`),
        loading: false,
      }
    }

    case CONTRACT_UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case CONTRACT_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case CLEAR_LOCATION_DETAIL: {
      return {
        ...state,
        detail: {},
        list: [],
      }
    }

    default: {
      return state
    }
  }
}

export default contractReducer
