import React, {useState} from 'react'
import GridItem from 'component/material/GridItem'
import Carousel from 'react-material-ui-carousel'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import PropTypes from 'prop-types'
import UploadButton from 'component/material/UploadButton'
import {deleteLocationImage, getLocation, uploadLocationImage} from 'redux/action/locationAction'
import {deleteContractImage, getContract, uploadContractImage} from 'redux/action/contractAction'
import {Trans} from '@lingui/macro'
import {ReactComponent as AddImage} from 'style/asset/image-add.svg'
import GridContainer from 'component/material/GridContainer'
import {fireErrorToast, fireSuccessToast} from 'helper/functions'
import Loader from 'component/material/Loader'
import Typography from '@material-ui/core/Typography'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import BoxFullWidth from 'component/material/BoxFullWidth'
import IconButton from 'component/material/IconButton'
import {ReactComponent as BinIcon} from 'style/asset/bin.svg'

const ImageCarousel = (props) => {
  const {
    openBigImageCarousel,
    getLocation,
    getContract,
    classes,
    locationId,
    contractId,
    images,
    hasContract,
  } = props

  const [imageUploadLoading, setImageUploadLoading] = useState(false)

  const handleSaveImage = (imageType) => (imageData) => {
    setImageUploadLoading(true)
    if (imageType === 'location') {
      uploadLocationImage(locationId, [{data: imageData}])
        .then((res) => {
          fireSuccessToast(<Trans>Image uploaded to location.</Trans>)
          getLocation(locationId)
          setImageUploadLoading(false)
        })
        .catch((err) => {
          fireErrorToast(<Trans>Image upload failed.</Trans>)
          setImageUploadLoading(false)
        })
    } else {
      uploadContractImage(locationId, contractId, [{data: imageData}])
        .then((res) => {
          fireSuccessToast(<Trans>Image uploaded to contract.</Trans>)
          getLocation(locationId)
          setImageUploadLoading(false)
        })
        .catch((err) => {
          fireErrorToast(<Trans>Image upload failed.</Trans>)
          setImageUploadLoading(false)
        })
    }
  }

  const handleDeleteImage = (id, imageType) => (e) => {
    setImageUploadLoading(true)
    e.stopPropagation()
    if (imageType === 'location') {
      deleteLocationImage(locationId, id)
        .then((res) => {
          fireSuccessToast(<Trans>Image delete.</Trans>)
          getLocation(locationId)
          setImageUploadLoading(false)
        })
        .catch((err) => {
          fireErrorToast(<Trans>Image deletion failed.</Trans>)
          setImageUploadLoading(false)
        })
    } else {
      deleteContractImage(locationId, contractId, id)
        .then((res) => {
          fireSuccessToast(<Trans>Image delete.</Trans>)
          getContract(locationId, contractId)
          setImageUploadLoading(false)
        })
        .catch((err) => {
          fireErrorToast(<Trans>Image deletion failed.</Trans>)
          setImageUploadLoading(false)
        })
    }
  }

  return (
    <GridItem xs={12} className={classes.imageCarouselContainer}>
      <BoxFullWidth pb={4}>
        <GridContainer spacing={4} direction={'row'}>
          <GridItem>
            <UploadButton
              saveImage={handleSaveImage('location')}
              title={
                <GridContainer justify={'center'} alignItems={'center'}>
                  <div className={classes.addImageIconPadding}>
                    <AddImage />
                  </div>
                  <Trans>Location</Trans>
                </GridContainer>
              }
            />
          </GridItem>
          {hasContract && (
            <GridItem>
              <UploadButton
                saveImage={handleSaveImage('contract')}
                title={
                  <GridContainer justify={'center'} alignItems={'center'}>
                    <div className={classes.addImageIconPadding}>
                      <AddImage />
                    </div>
                    <Trans>Contract</Trans>
                  </GridContainer>
                }
              />
            </GridItem>
          )}
        </GridContainer>
      </BoxFullWidth>
      {imageUploadLoading ? (
        <div className={classes.carouselEmptyContainer}>
          <Loader />
        </div>
      ) : images?.length > 0 ? (
        <Carousel
          autoPlay={false}
          animation={'slide'}
          fullHeightHover={false}
          navButtonsWrapperProps={{style: {height: 48, marginTop: 32}}}
        >
          {images.map((item, index) => (
            <Paper
              elevation={0}
              key={index}
              className={classes.carouselPaper}
              onClick={openBigImageCarousel(index)}
            >
              <div className={classes.deleteImageIcon}>
                <IconButton
                  icon={<BinIcon />}
                  onClick={handleDeleteImage(item.id, item.resource ? item.resource : 'location')}
                />
              </div>
              {hasContract && <div className={classes.imageResource}>{item.title}</div>}
              <img src={item.url} alt={item.id} className={classes.carouselImage} />
            </Paper>
          ))}
        </Carousel>
      ) : (
        <div className={classes.carouselEmptyContainer}>
          <Typography variant={'h4'} color={'secondary'} component={'span'}>
            <Trans>No images</Trans>
          </Typography>
        </div>
      )}
    </GridItem>
  )
}

ImageCarousel.propTypes = {
  classes: PropTypes.object,
  openBigImageCarousel: PropTypes.func,
  images: PropTypes.array,
  getLocation: PropTypes.func,
  getContract: PropTypes.func,
  hasContract: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLocation,
      getContract,
    },
    dispatch
  )
}

export default compose(
  withStyles(locationDetailStyle),
  connect(null, mapDispatchToProps)
)(ImageCarousel)
