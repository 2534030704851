import React from 'react'
import {Trans} from '@lingui/macro'
import {resolveEnv} from 'helper/functions'

export const ENVIRONMENT = process.env.NODE_ENV
export const PUBLIC_API_KEY = resolveEnv('REACT_APP_PUBLIC_API_KEY')
export const GATEWAY_URL = resolveEnv('REACT_APP_GATEWAY_URL')

// database date format
export const DB_DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const DAY_MONTH_FORMAT = 'DD.MM.'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const CUBIC_METERS_TO_PRM = 2.6

export const RESPONSIVE_WIDTH = 600
export const MEDIA_SCREEN = `@media (min-width: ${RESPONSIVE_WIDTH}px)`
export const MEDIA_MOBILE = `@media (max-width: ${RESPONSIVE_WIDTH-1}px)`

export const LOCATION_TYPES = [
  {value: 'WORK', label: <Trans>Work station</Trans>},
  {value: 'DESTINATION', label: <Trans>Destination station</Trans>},
  {value: 'GAS_STATION', label: <Trans>Gas station</Trans>},
  {value: 'SERVICE_STATION', label: <Trans>Service station</Trans>},
]

export const USER_ROLES = [
  {value: 'OPERATOR', label: <Trans>Operator</Trans>},
  {value: 'MANAGER', label: <Trans>Manager</Trans>},
]

export const CONTRACT_STATUS_LIST = [
  {value: 'PLANNED', label: <Trans>Planned</Trans>},
  // {value: 'STARTED', label: <Trans>Started</Trans>},
  {value: 'IN_PROGRESS', label: <Trans>In-progress</Trans>},
  {value: 'SUSPENDED', label: <Trans>Suspended</Trans>},
  {value: 'FINISHED', label: <Trans>Finished</Trans>},
  {value: 'ABANDONED', label: <Trans>Abandoned</Trans>},
]

export const CONTRACT_TYPE_LIST = [
  {value: 'OWN_RESOURCE', label: <Trans>Own resource</Trans>},
  {value: 'AS_A_SERVICE', label: <Trans>As a service</Trans>},
]

export const MATERIAL_TYPE_LIST = [
  {value: 'WOOD', label: <Trans>Wood</Trans>},
  {value: 'BRANCHES', label: <Trans>Branches</Trans>},
]
