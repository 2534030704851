import React, {useEffect, useState} from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import {Typography} from '@material-ui/core'
import BoxFullWidth from 'component/material/BoxFullWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import machinesPageStyle from 'component/MachinePage/machinesPageStyle'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import PrimaryButton from 'component/material/PrimaryButton'
import NewAssetIcon from 'component/icon/NewAssetIcon'
import DialogWindow from 'component/material/DialogWindow'
import {fireErrorToast, fireSuccessToast} from 'helper/functions'
import {createUser, getUsers} from 'redux/action/userAction'
import UserIcon from 'component/icon/UsersIcon'
import UserTable from 'component/UserPage/UserTable'
import CreateUserForm from 'component/UserPage/CreateUserForm'
import useWindowSize from 'helper/useWindowSize'

const UserPage = (props) => {
  const {classes, getUsers, createUser} = props

  const [openCreateUser, setOpenCreateUser] = useState(false)
  const [tableSort, setTableSort] = useState({orderBy: null, orderDirection: 'ASC'})
  const [onlyArchivedFilter, setOnlyArchivedFilter] = useState(false)

  const {isMediaMobile, isMediaScreen} = useWindowSize()

  const handleOnlyArchived = (e) => {
    setOnlyArchivedFilter(e.target.checked)
  }

  const handleOpenCreateUser = () => {
    setOpenCreateUser(true)
  }
  const handleCloseCreateUser = () => {
    setOpenCreateUser(false)
  }
  const handleCreateUser = (values) => {
    createUser(values)
      .then((res) => {
        fireSuccessToast(<Trans>New user created.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>User creation failed.</Trans>)
      })
      .finally(() => {
        getUsers(onlyArchivedFilter, tableSort)
        handleCloseCreateUser()
      })
  }

  useEffect(() => {
    getUsers(onlyArchivedFilter, tableSort)
  }, [onlyArchivedFilter, tableSort])

  const handleTableSort = (orderBy) => (e) => {
    if (tableSort.orderBy === orderBy) {
      if (tableSort.orderDirection === 'ASC') {
        return setTableSort({orderBy: orderBy, orderDirection: 'DESC'})
      } else {
        // reset the sort
        return setTableSort({orderBy: null, orderDirection: 'ASC'})
      }
    } else {
      return setTableSort({orderBy: orderBy, orderDirection: 'ASC'})
    }
  }

  return (
    <GridContainer alignItems={'flex-start'}>
      {/* CREATE USER DIALOG */}
      <DialogWindow
        title={<Trans>New user</Trans>}
        maxWidth={'sm'}
        open={openCreateUser}
        onClose={handleCloseCreateUser}
      >
        <CreateUserForm handleSubmit={handleCreateUser} />
      </DialogWindow>

      <BoxFullWidth px={6} py={4} className={classes.topBar}>
        <GridItem container xs={12} alignItems={'center'}>
          <UserIcon className={classes.topBarIcon} />
          <Typography component={'span'} variant={'h3'} color={'primary'}>
            <b>
              <Trans>Users</Trans>
            </b>
          </Typography>
        </GridItem>
      </BoxFullWidth>
      <GridContainer
        alignItems={'flex-start'}
        direction={'column'}
        className={classes.tableContainer}
      >
        <BoxFullWidth px={6} pb={4} pt={2} className={classes.userTable}>
          <UserTable
            onlyArchivedFilter={onlyArchivedFilter}
            handleOnlyArchived={handleOnlyArchived}
            handleTableSort={handleTableSort}
            tableSort={tableSort}
          />
        </BoxFullWidth>
        <BoxFullWidth px={6} py={2}  class={classes.newUser}>
          <GridContainer justify={isMediaMobile ? 'flex-start' : 'flex-end'} alignItems={'center'}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              onClick={handleOpenCreateUser}
              text={
                <GridContainer alignItems={'center'}>
                  <NewAssetIcon />
                  <Trans>New user</Trans>
                </GridContainer>
              }
            />
          </GridContainer>
        </BoxFullWidth>
      </GridContainer>
    </GridContainer>
  )
}

UserPage.propTypes = {
  classes: PropTypes.object,
  getUsers: PropTypes.func,
  createUser: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUsers,
      createUser,
    },
    dispatch
  )
}

export default compose(
  withStyles(machinesPageStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(UserPage)
