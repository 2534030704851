import React from 'react'
import {Trans} from '@lingui/macro'

/// undefined == no error for the react-final-form ///

// connect multiple validations together,
// otherwise you can omit this function and use single validation on Field
export const composeValidators = (...validators) => (value) => {
  return validators.reduce((error, validator) => error || validator(value), undefined)
}

export const required = (value) => {
  return value ? undefined : <Trans>Required field</Trans>
}

export const validateEmail = (value) => {
  if (!value?.includes('@')) {
    return <Trans>Wrong email address</Trans>
  }
  return undefined
}

// form level validation of identity of passwords
export const samePasswordValidation = (values) => {
  if (values.password !== values.password_confirmation) {
    return {password_confirmation: <Trans>Passwords must be the same</Trans>}
  }
  return undefined
}
