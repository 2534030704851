import React from 'react'
import GridContainer from 'component/material/GridContainer'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import withStyles from '@material-ui/core/styles/withStyles'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'
import PropTypes from 'prop-types'
import {ReactComponent as Work} from 'style/asset/work.svg'
import {ReactComponent as Gas} from 'style/asset/gas.svg'
import {ReactComponent as Destination} from 'style/asset/destination.svg'
import {ReactComponent as Service} from 'style/asset/service.svg'

const SingleCheckboxFilter = (props) => {
  const {classes, handleChange, checked, label, name} = props

  const prepareClasses = () => {
    if (name === 'WORK') {
      return classes.workColor
    } else if (name === 'DESTINATION') {
      return classes.destinationColor
    } else if (name === 'GAS_STATION') {
      return classes.gasColor
    } else {
      return classes.serviceColor
    }
  }

  const prepareIcon = () => {
    if (name === 'WORK') {
      return (
        <span className={classes.workTypeIcon}>
          <Work />
        </span>
      )
    }
    if (name === 'DESTINATION') {
      return (
        <span className={classes.destinationTypeIcon}>
          <Destination />
        </span>
      )
    }
    if (name === 'GAS_STATION') {
      return (
        <span className={classes.gasTypeIcon}>
          <Gas />
        </span>
      )
    }
    return (
      <span className={classes.serviceTypeIcon}>
        <Service />
      </span>
    )
  }

  return (
    <GridContainer justify={'space-between'} alignItems={'center'}>
      <FormControlLabel
        className={prepareClasses()}
        control={
          <Checkbox
            classes={{root: classes.filterCheckbox}}
            checked={checked}
            onChange={handleChange}
            name={name}
          />
        }
        label={
          <GridContainer alignItems={'center'} className={classes.filterIconText}>
            {prepareIcon()}
            {label}
          </GridContainer>
        }
      />
    </GridContainer>
  )
}

SingleCheckboxFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
}

export default withStyles(mapLayoutPageStyle)(SingleCheckboxFilter)
