import React from 'react'
import {TextField} from '@material-ui/core'
import PropTypes from 'prop-types'
import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import withStyles from '@material-ui/core/styles/withStyles'

const TextInput = (props) => {
  const {
    classes,
    input,
    meta,
    type,
    helperText,
    inputJustifyRight = false,
    hideNumberArrows = false,
    variant = 'outlined',
    onChange,
    value,
    label,
    multiline = false,
    className,
    ...rest
  } = props

  const errorMessage = meta.touched && meta.error

  const inputClasses = cx({
    hideNumberArrows: true,
  })

  return (
    <>
      <div className={classes.fieldLabel}>{label}</div>
      <TextField
        variant={variant}
        fullWidth
        type={type}
        className={cx(
          !multiline && classes.inputPadding,
          (input?.type === 'number' || type === 'number') && inputClasses,
          classes.adornments,
          classes.fieldShadow,
          inputJustifyRight && classes.inputJustifyRight,
          className
        )}
        {...input}
        onChange={(e) => {
          onChange ? onChange(e, input?.onChange) : input?.onChange(e)
        }}
        error={!!errorMessage}
        helperText={errorMessage || helperText || ' '}
        multiline={multiline}
        {...rest}
      />
    </>
  )
}

TextInput.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
  helperText: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
}

export default withStyles(fieldStyle)(TextInput)
