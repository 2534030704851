import React from 'react'
import {ReactComponent as Restore} from 'style/asset/restore.svg'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import iconStyle from 'component/icon/iconStyle'
import cx from 'classnames'

const RestoreIcon = (props) => {
  const {className, classes, ...rest} = props

  return <Restore {...rest} className={cx(className, classes.restoreIcon)} />
}

RestoreIcon.propTypes = {
  className: PropTypes.object,
  classes: PropTypes.object,
  rest: PropTypes.object,
}

export default withStyles(iconStyle)(RestoreIcon)
