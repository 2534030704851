import React from 'react'
import {ReactComponent as Map} from 'style/asset/maps.svg'
import PropTypes from 'prop-types'

const MapIcon = (props) => {
  const {className, ...rest} = props

  return <Map {...rest} className={className} />
}

MapIcon.propTypes = {
  className: PropTypes.object,
  rest: PropTypes.object,
}

export default MapIcon
