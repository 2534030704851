import React, {useState} from 'react'
import GridItem from 'component/material/GridItem'
import {TextField} from '@material-ui/core'
import GridContainer from 'component/material/GridContainer'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import locationDetailStyle from 'component/MapLayoutPage/locationDetail/locationDetailStyle'
import cx from 'classnames'
import IconButton from 'component/material/IconButton'
import Menu from '@material-ui/core/Menu'
import Fade from '@material-ui/core/Fade'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from 'component/icon/CloseIcon'
import Box from '@material-ui/core/Box'
import CheckIconButton from 'component/editField/CheckIconButton'
import CloseIconButton from 'component/editField/CloseIconButton'
import DotsIcon from 'component/icon/DotsIcon'
import WorkTypeIcon from 'component/icon/LocationTypeIcon'

/**
 * Component able of toggling edit/display single controlled fields
 *
 * @param defaultValue = initial value of field, displayed when not editing
 * @param value = actual value which change during onChange event
 * @param editing = true/false
 * @param name = name of the field on BE
 * @param onChange = function controlling field change
 * @param onSave = submit put request with new value
 * @param onEdit = setting field which is edited or cancelling edit with null
 * @param responsiveness = object with material breakpoints
 * @returns {JSX.Element}
 */
const EditTitleField = (props) => {
  const {
    locationType,
    defaultValue,
    value,
    editing,
    name,
    onChange,
    onSave,
    onEdit,
    responsiveness = {xs: 12},
    classes,
    onClose,
    showMenuButton = false,
    menuItems,
    borderBottom = true,
    edgeSide = 'left',
    hasContract,
  } = props

  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = (e) => {
    setAnchorEl(e.currentTarget)
    setMenuOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen(false)
  }

  const handleClickInMenu = (item) => (e) => {
    handleClose()
    item.onClick(name)
  }

  const classesObject = cx(
    borderBottom && classes.borderBottom,
    edgeSide === 'left' && classes.leftEdge,
    edgeSide === 'right' && classes.rightEdge
  )

  return (
    <GridItem
      container
      {...responsiveness}
      direction={'row'}
      alignItems={'center'}
      justify={'space-between'}
    >
      <GridItem
        container
        alignItems={'center'}
        justify={'space-between'}
        xs={true}
        className={classesObject}
      >
        <GridItem container direction={'row'} xs={true} justify={'center'} alignItems={'center'}>
          {showMenuButton && (
            <>
              <Box pr={4}>
                <IconButton icon={<DotsIcon />} onClick={openMenu} />
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                {menuItems.map((item, index) => (
                  <MenuItem key={index} onClick={handleClickInMenu(item)}>
                    <GridContainer>
                      {item.icon && (
                        <Box pr={3} className={classes.menuItemIcon}>
                          {item.icon}
                        </Box>
                      )}
                      {item.text}
                    </GridContainer>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
          <GridItem>
            <WorkTypeIcon type={locationType} />
          </GridItem>
          <GridItem container xs={true}>
            {editing ? (
              <TextField fullWidth name={name} value={value} onChange={onChange} />
            ) : (
              <div className={classes.locationTitle}>{defaultValue}</div>
            )}
          </GridItem>
          <GridItem>
            {editing && (
              <GridContainer justify={'flex-end'} alignItems={'center'} direction={'column'}>
                <CheckIconButton onSave={onSave} />
                <CloseIconButton onClose={() => onEdit(null)} />
              </GridContainer>
            )}
          </GridItem>
        </GridItem>
      </GridItem>

      {(!hasContract || window.innerWidth < 960) && onClose && (
        <GridItem>
          <div className={classes.locationDetailCloseButton} onClick={onClose}>
            <CloseIcon />
          </div>
        </GridItem>
      )}
    </GridItem>
  )
}

EditTitleField.propTypes = {
  locationType: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  editing: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  additionalContent: PropTypes.node,
  responsiveness: PropTypes.object,
  classes: PropTypes.object,
  onClose: PropTypes.func,
  showMenuButton: PropTypes.bool,
  menuItems: PropTypes.array,
  borderBottom: PropTypes.bool,
  hasContract: PropTypes.bool,
  edgeSide: PropTypes.string,
}

export default withStyles(locationDetailStyle)(EditTitleField)
