import React from 'react'
import {ReactComponent as User} from 'style/asset/users.svg'
import PropTypes from 'prop-types'

const UserIcon = (props) => {
  const {className, ...rest} = props

  return <User {...rest} className={className} />
}

UserIcon.propTypes = {
  className: PropTypes.string,
  rest: PropTypes.object,
}

export default UserIcon
