import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import mapLayoutPageStyle from 'component/MapLayoutPage/mapLayoutPageStyle'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {Trans} from '@lingui/macro'
import BoxFullWidth from 'component/material/BoxFullWidth'
import SingleLocation from 'component/MapLayoutPage/SingleLocation'
import cx from 'classnames'
import DialogWindow from 'component/material/DialogWindow'
import {bindActionCreators, compose} from 'redux'
import {
  createLocation,
  getLocation,
  getLocations,
  clearLocationDetail,
} from 'redux/action/locationAction'
import {connect} from 'react-redux'
import {fireErrorToast, handleMapError, windowResizeEvent} from 'helper/functions'
import LocationDetailDialog from 'component/MapLayoutPage/locationDetail/LocationDetailDialog'
import LocationWithoutContractDialog from 'component/MapLayoutPage/locationDetail/LocationWithoutContractDialog'
import Loader from 'component/material/Loader'
import Box from '@material-ui/core/Box'
import CloseIcon from 'component/icon/CloseIcon'
import LocationIcon from 'component/icon/LocationIcon'
import NewAssetIcon from 'component/icon/NewAssetIcon'
import {ReactComponent as Filters} from 'style/asset/filters.svg'
import {ReactComponent as CompassArrow} from 'style/asset/compass-arrow.svg'
import SingleCheckboxFilter from 'component/MapLayoutPage/SingleCheckboxFilter'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'
import useWindowSize from 'helper/useWindowSize'

const MapPanel = (props) => {
  const {
    classes,
    locationsList,
    locationDetail,
    getLocations,
    getLocation,
    clearLocationDetail,
    onOpenLocation,
    locationsLoading,
    gettingLocationFromMap,
    openLocationFromMapId,
    closeLocationDetailFromMap,
    locationFromMapCoords,
    handleLocationFromMap,
    handleOpenCreateLocation,
    setMyLocation,
    isManager,
  } = props

  const [panelOpen, setPanelOpen] = useState(true)
  // filter for locations of certain type
  const [filters, setFilters] = useState(['WORK', 'DESTINATION', 'GAS_STATION', 'SERVICE_STATION'])

  // filter for active or archived (workplaces, destinations ...)
  const [archivedFilter, setArchivedFilter] = useState(false)
  const [activeWorkFilter, setActiveWorkFilter] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)
  const [onMobileShouldOpenPanel, setOnMobileShouldOpenPanel] = useState(false)

  const {width, height, isMediaMobile, isMediaScreen} = useWindowSize()

  const handleOpenFilters = () => {
    setOpenFilters(true)
    if (isMediaMobile && panelOpen) {
      setPanelOpen(false)
      setOnMobileShouldOpenPanel(true)
    }
  }

  const handleCloseFilters = () => {
    setOpenFilters(false)
    if (isMediaMobile && onMobileShouldOpenPanel) {
      setPanelOpen(true)
      setOnMobileShouldOpenPanel(false)
    }
  }

  const handleTogglePanel = () => {
    if (isMediaMobile && openFilters && !panelOpen) {
      setOpenFilters(false)
    }
    setPanelOpen((prevState) => !prevState)
  }

  const handleChange = (e) => {
    setFilters((prevState) =>
      filters.includes(e.target.name)
        ? [...prevState.filter((f) => f !== e.target.name)]
        : [...prevState, e.target.name]
    )
  }

  const handleChangeArchived = () => {
    setArchivedFilter((prevState) => !prevState)
  }
  const handleChangeActiveWork = () => {
    setActiveWorkFilter((prevState) => !prevState)
  }

  const openLocationDetail = (values) => (e) => {
    getLocation(values.id).then((res) => {
      setOpenDetail(true)
    })
  }

  const closeLocationDetail = () => {
    clearLocationDetail().then(() => {
      closeLocationDetailFromMap()
      setOpenDetail(false)
    })
  }

  useEffect(() => {
    getLocations(filters, archivedFilter, activeWorkFilter)
  }, [filters, archivedFilter, activeWorkFilter])

  // when panel is open/closed, call resize event to notify the map
  useEffect(() => windowResizeEvent, [panelOpen])

  // when location detail is changed then open location in map
  useEffect(() => onOpenLocation(locationDetail), [locationDetail])

  // when location is clicked on map
  const handleOpenLocationDetailFromMap = (id) => {
    getLocation(id).then((res) => {
      setOpenDetail(true)
    })
  }
  useEffect(() => {
    if (openLocationFromMapId) {
      handleOpenLocationDetailFromMap(openLocationFromMapId)
    }
  }, [openLocationFromMapId])

  const getArchivedSwitchLabel = () => {
    if (archivedFilter) {
      return (
        <span className={classes.switchLabel}>
          <Trans>Active</Trans>
          {' / '}
          <b>
            <Trans>Archived</Trans>
          </b>
        </span>
      )
    } else {
      return (
        <span className={classes.switchLabel}>
          <b>
            <Trans>Active</Trans>
          </b>
          {' / '}
          <Trans>Archived</Trans>
        </span>
      )
    }
  }

  const renderFilterCheckBoxes = () => {
    return (
      <>
        <SingleCheckboxFilter
          handleChange={handleChange}
          checked={filters.includes('WORK')}
          label={<Trans>Work station</Trans>}
          name="WORK"
        />
        {filters.includes('WORK') && (
          <FormControlLabel
            className={classes.workColor}
            control={
              <Checkbox
                classes={{root: classes.filterSubCheckbox}}
                checked={activeWorkFilter}
                onChange={handleChangeActiveWork}
              />
            }
            label={
              <GridContainer alignItems={'center'} className={classes.filterIconText}>
                <Trans>Only active</Trans>
              </GridContainer>
            }
          />
        )}
        <SingleCheckboxFilter
          handleChange={handleChange}
          checked={filters.includes('DESTINATION')}
          label={<Trans>Destination</Trans>}
          name="DESTINATION"
        />

        <SingleCheckboxFilter
          handleChange={handleChange}
          checked={filters.includes('GAS_STATION')}
          label={<Trans>Gas station</Trans>}
          name="GAS_STATION"
        />

        <SingleCheckboxFilter
          handleChange={handleChange}
          checked={filters.includes('SERVICE_STATION')}
          label={<Trans>Service</Trans>}
          name="SERVICE_STATION"
        />

        <FormControlLabel
          control={
            <Switch
              className={classes.switchClasses}
              checked={archivedFilter}
              onChange={handleChangeArchived}
              color="primary"
            />
          }
          label={getArchivedSwitchLabel()}
        />
      </>
    )
  }

  // simple HTML5 geolocation https://www.w3schools.com/html/html5_geolocation.asp
  const handleMyLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => setMyLocation({x: pos.coords.longitude, y: pos.coords.latitude}),
        handleMapError,
        {enableHighAccuracy: true, timeout: 5000, maximumAge: 0}
      )
    } else {
      fireErrorToast(<Trans>Geolocation is not supported by this browser.</Trans>)
    }
  }

  const openCreateLocation = (e) => {
    e.stopPropagation()
    handleOpenCreateLocation()
  }
  const locationFromMap = (e) => {
    e.stopPropagation()
    handleLocationFromMap()
  }

  return (
    <GridContainer
      direction="column"
      justify="space-between"
      className={cx(classes.rightPanel, !panelOpen && classes.closedPanel)}
    >
      {openFilters ? (
        <>
          <div
            className={cx(classes.mapLayoutFilterButton, classes.mapLayoutFilterOpened)}
            onClick={handleCloseFilters}
          >
            <CloseIcon className={classes.panelCloseIcon} />
          </div>
          <div className={classes.panelCoverElement} />
          <div className={classes.filterContainer}>
            <BoxFullWidth p={5}>
              <div className={classes.filterTitle}>
                <Trans>Location filter</Trans>
              </div>
              <BoxFullWidth pt={2}>{renderFilterCheckBoxes()}</BoxFullWidth>
            </BoxFullWidth>
          </div>
        </>
      ) : (
        <div className={classes.mapLayoutFilterButton} onClick={handleOpenFilters}>
          <Filters className={classes.panelOpenIcon} />
        </div>
      )}

      <div className={classes.mapLayoutCompassButton} onClick={handleMyLocationClick}>
        <CompassArrow className={classes.panelOpenIcon} />
      </div>

      <div
        onClick={handleTogglePanel}
        className={classes.rightPanelToggleIcon}
      >
        {panelOpen ? (
          <CloseIcon className={classes.panelCloseIcon} />
        ) : (
          <LocationIcon className={classes.panelOpenIcon} />
        )}

        <div className={classes.mapLayoutCreateButton}>
          <PrimaryButton
            fullWidth={false}
            formSubmit={true}
            onClick={gettingLocationFromMap ? locationFromMap : openCreateLocation}
            text={
              <GridContainer alignItems={'center'}>
                <NewAssetIcon single={isMediaMobile && !gettingLocationFromMap} />
                {gettingLocationFromMap ? (
                  isMediaScreen ? (
                    <Trans>Get coordinates</Trans>
                  ) : (
                    <Trans>Select</Trans>
                  )
                ) : isMediaScreen ? (
                  <Trans>Create location</Trans>
                ) : null}
              </GridContainer>
            }
          />
        </div>
      </div>

      {/* LOCATION DETAIL DIALOG */}
      {locationDetail?.hasLocationContract ? (
        <DialogWindow open={openDetail} className={gettingLocationFromMap ? classes.hideModal : ''}>
          {locationsLoading ? (
            <GridContainer justify={'center'} alignItems={'center'}>
              <Box py={6}>
                <Loader />
              </Box>
            </GridContainer>
          ) : (
            <GridContainer direction={'row'} alignItems={'flex-start'}>
              <LocationDetailDialog
                onClose={closeLocationDetail}
                locationFromMapCoords={locationFromMapCoords}
                handleLocationFromMap={handleLocationFromMap}
                gettingLocationFromMap={gettingLocationFromMap}
              />
            </GridContainer>
          )}
        </DialogWindow>
      ) : (
        <DialogWindow
          className={cx(
            classes.locationDetailWithoutContract,
            gettingLocationFromMap ? classes.hideModal : ''
          )}
          open={openDetail}
          hideBackdrop={true}
        >
          {locationsLoading ? (
            <GridContainer justify={'center'} alignItems={'center'}>
              <Box py={6}>
                <Loader />
              </Box>
            </GridContainer>
          ) : (
            <LocationWithoutContractDialog
              onClose={closeLocationDetail}
              hasLocationContract={locationDetail?.hasLocationContract}
              filters={filters}
              archivedFilter={archivedFilter}
              activeWorkFilter={activeWorkFilter}
              locationFromMapCoords={locationFromMapCoords}
              handleLocationFromMap={handleLocationFromMap}
              gettingLocationFromMap={gettingLocationFromMap}
            />
          )}
        </DialogWindow>
      )}

      {panelOpen && (
        <GridItem container direction="column" className={classes.rightPanelContent}>
          <BoxFullWidth p={5}>
            <GridItem xs={12}>
              <BoxFullWidth pb={5} className={classes.rightPanelLocationTitle}>
                <Trans>Locations</Trans>
              </BoxFullWidth>
            </GridItem>
            <GridContainer>
              {locationsList.map((location, index) => (
                <SingleLocation
                  key={index}
                  location={location}
                  openLocationDetail={openLocationDetail}
                />
              ))}
            </GridContainer>
          </BoxFullWidth>
        </GridItem>
      )}
    </GridContainer>
  )
}

MapPanel.propTypes = {
  classes: PropTypes.object,
  locationsList: PropTypes.array,
  locationsLoading: PropTypes.bool,
  getLocations: PropTypes.func,
  getLocation: PropTypes.func,
  locationDetail: PropTypes.object,
  onOpenLocation: PropTypes.func,
  createLocation: PropTypes.func,
  gettingLocationFromMap: PropTypes.bool,
  handleGettingLocationFromMap: PropTypes.func,
  closeLocationDetailFromMap: PropTypes.func,
  getMyAccount: PropTypes.func,
  locationFromMap: PropTypes.object,
  openLocationFromMapId: PropTypes.number,
  setMyLocation: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLocations,
      getLocation,
      createLocation,
      clearLocationDetail,
    },
    dispatch
  )
}

export default compose(
  withStyles(mapLayoutPageStyle),
  connect((store) => {
    return {
      locationsLoading: store.locations.loading,
      locationsList: store.locations.list,
      locationDetail: store.locations.detail,
      isManager: store.user.isManager,
    }
  }, mapDispatchToProps)
)(MapPanel)
