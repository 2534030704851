import React from 'react'
import {ReactComponent as Close} from 'style/asset/close.svg'
import PropTypes from 'prop-types'
import cx from 'classnames'
import iconStyle from 'component/icon/iconStyle'
import withStyles from '@material-ui/core/styles/withStyles'

const CloseIcon = (props) => {
  const {classes, className, ...rest} = props

  return <Close {...rest} className={cx(className, classes.closeIcon)} />
}

CloseIcon.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  rest: PropTypes.object,
}

export default withStyles(iconStyle)(CloseIcon)
