import React from 'react'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  USER_CREATE_FAIL,
  USER_CREATE_SUCCESS,
  USER_CREATE_REQUEST,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
} from 'redux/actionType'
import {fireWarningOffline, fireWarningToast, globalApiErrorHandler} from 'helper/functions'
import {Trans} from '@lingui/macro'

export const getUsers = (isArchived, sortParams) => {
  return (dispatch) => {
    dispatch({type: USER_LIST_REQUEST})
    return httpClient
      .get(endpoints.users, {
        is_archived: isArchived,
        order_by: sortParams?.orderBy || null,
        order_direction: sortParams?.orderBy ? sortParams.orderDirection : null,
      })
      .then((res) => {
        dispatch({type: USER_LIST_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          dispatch({type: USER_LIST_FAIL})
          fireWarningOffline()
        } else {
          globalApiErrorHandler(err, dispatch)
          return Promise.reject()
        }
      })
  }
}

export const createUser = (data) => {
  return (dispatch) => {
    dispatch({type: USER_CREATE_REQUEST})
    return httpClient
      .post(endpoints.users, data)
      .then((res) => {
        dispatch({type: USER_CREATE_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: USER_CREATE_FAIL})
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}

export const updateUser = (userId, data) => {
  return (dispatch) => {
    dispatch({type: USER_UPDATE_REQUEST})
    return httpClient
      .put(`${endpoints.users}/${userId}`, data)
      .then((res) => {
        dispatch({type: USER_UPDATE_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: USER_UPDATE_FAIL})
        globalApiErrorHandler(err, dispatch)
        return Promise.reject()
      })
  }
}
